import React, { useEffect } from 'react';

import { StringParam, useQueryParam } from 'use-query-params';
import { signInWithOkta } from 'state/actions/auth';
import { useLazyLoading } from 'state/hooks';
import { useHistory } from 'react-router-dom';
import { Loader } from 'components/Base';
import AuthHeadline from 'components/Auth/AuthHeadline';
import { AuthLayout } from 'components/Auth';

const OktaInitiatedLogin = () => {
  const history = useHistory();
  const [issuerUrl] = useQueryParam('iss', StringParam);
  const [loginViaOkta, { loading }] = useLazyLoading({
    action: signInWithOkta,
    onSuccess: () => {
      history.push('/');
    },
  });

  useEffect(() => {
    if (issuerUrl) {
      const url = new URL(issuerUrl);
      loginViaOkta(url.host);
    }
    // eslint-disable-next-line
  }, [issuerUrl]);

  if (loading) {
    return <Loader />;
  }

  return (
    <AuthLayout title="Log In via Okta...">
      <AuthHeadline title="Log In via Okta!..." />
    </AuthLayout>
  );
};

export default OktaInitiatedLogin;
