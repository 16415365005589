const userGroupColumns = [
  {
    label: 'Name',
    id: 'label',
    type: 'text',
    sortable: true,
  },
  {
    label: 'Description',
    id: 'description',
    type: 'text',
    sortable: false,
  },
  {
    label: 'Users',
    id: 'userCount',
    type: 'text',
    sortable: false,
  },
  {
    label: 'AWS Accounts',
    id: 'awsAccountsCount',
    type: 'text',
    sortable: false,
  },
  {
    label: 'Policies',
    id: 'policiesCount',
    type: 'text',
    sortable: false,
  },
  {
    align: 'right',
    id: 'Actions',
    label: 'Actions',
    type: 'actions',
  },
  // {
  //   id: 'permissionCount',
  //   label: 'Permissions',
  //   type: 'text',
  //   sortable: false,
  // },
  // {
  //   id: 'Actions',
  //   label: 'Actions',
  //   type: 'actions',
  // },
];

export default userGroupColumns;
