import React, { useEffect, useRef, useState } from 'react';
import { AlertDialog, LogoLoader } from 'components/Base';
import AppBar from 'components/Structure/AppBar';
import { Box, Link, styled, Tooltip, Typography } from '@material-ui/core';
import { connect, useDispatch } from 'react-redux';
import { UPDATE_BLOCK_ERROR_MULTIPLE_ROWS } from 'state/consts/snackbarMessages';
import {
  fetchBlockDetails,
  updateBlock,
  saveBlockToTemplates,
  publishBlockToTemplates,
  showErrorSnackbar,
} from 'state/actions';
import { useAsyncAction, useLazyLoading } from 'state/hooks';
import { useQueryParam, StringParam } from 'use-query-params';
import { useSubscription } from 'components/Contexts';
import EmailEditor from 'react-email-editor';
import BlockPublishButton from './BlockPublishButton';
import BlockTemplatesDialog from '../Editor/BlockTemplatesDialog';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  height: '100%',
  width: '100%',
  overflow: 'hidden',
});

const InfoContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginRight: theme.spacing(4),
  marginLeft: theme.spacing(4),
}));

const TemplateCountLink = styled(Link)(({ theme }) => ({
  cursor: 'pointer',
  fontWeight: 600,
  marginRight: theme.spacing(1),
}));

const customStyle = `
  .save-block-btn {
    display: none !important;
  }

  .blockbuilder-duplicate {
    display: none !important;
  }

  button .fa-ellipsis {
    display: none !important;
  }
  button:has(.fa-ellipsis) {
    display: none !important;
  }
`;

const ReusableBlockDetails = ({
  selectedBlock,
  themeMode,
  currentAwsAccount,
}) => {
  const dispatch = useDispatch();
  const emailEditorRef = useRef(null);
  const designLoaded = useRef(false);
  const [designHasLoaded, setDesignHasLoaded] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [blockTemplatesDialogOpen, setBlockTemplatesDialogOpen] = useState(
    false
  );
  const [blockId] = useQueryParam('blockId', StringParam);
  const [confirmationDialogContent, setConfirmationDialogContent] = useState({
    open: false,
  });
  const { company, subscription } = useSubscription();

  const defaultEditorOptions = {
    appearance: {
      theme: 'modern_' + themeMode,
    },
    features: {
      preview: false,
      undoRedo: false,
      preheaderText: false,
    },
    displayMode: 'email',
    tabs: {
      content: {
        active: true,
      },
      blocks: {
        enabled: false,
      },
      body: {
        enabled: false,
      },
    },
    customCSS: [customStyle],
  };

  const [editorOptions, setEditorOptions] = useState(defaultEditorOptions);
  useEffect(() => {
    if (
      subscription.name === 'Business' ||
      subscription.name === 'Enterprise'
    ) {
      setEditorOptions({
        ...defaultEditorOptions,
        user: {
          id: company?.details?.uid,
        },
      });
    } else {
      setEditorOptions(defaultEditorOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, subscription]);

  const { loading } = useAsyncAction({
    action: fetchBlockDetails,
    values: { blockId },
  });

  const [updateBlockAction, { loading: updating }] = useLazyLoading({
    action: updateBlock,
    onSuccess: () => {
      setUnsavedChanges(false);
    },
  });

  const [saveBlockToTemplatesAction, { loading: saving }] = useLazyLoading({
    action: saveBlockToTemplates,
    onSuccess: () => {
      setConfirmationDialogContent({
        open: false,
      });
    },
  });

  const [
    publishBlockToTemplatesAction,
    { loading: publishing },
  ] = useLazyLoading({
    action: publishBlockToTemplates,
    onSuccess: () => {
      setConfirmationDialogContent({
        open: false,
      });
    },
  });

  const handleLoadBlockDesign = () => {
    if (!emailEditorRef.current || !emailEditorRef.current.editor) {
      return;
    }
    designLoaded.current = true;
    if (selectedBlock?.design) {
      emailEditorRef.current.editor.loadDesign(
        JSON.parse(selectedBlock.design)
      );
    } else {
      emailEditorRef.current.editor.loadBlank();
    }

    emailEditorRef.current.editor.addEventListener('design:loaded', () => {
      setDesignHasLoaded(true);
    });
  };

  useEffect(() => {
    if (designHasLoaded) {
      emailEditorRef.current.editor.addEventListener('design:updated', () => {
        setUnsavedChanges(true);
      });
    }
  }, [designHasLoaded]);

  const handleSaveBlock = async () => {
    emailEditorRef.current.editor.exportHtml(async ({ html, design }) => {
      if (design.body.rows.length > 1) {
        dispatch(showErrorSnackbar(UPDATE_BLOCK_ERROR_MULTIPLE_ROWS));
      } else {
        await updateBlockAction({
          id: blockId,
          values: {
            label: selectedBlock.label,
            description: selectedBlock.description,
            design: JSON.stringify(design),
            html,
          },
        });
      }
    });
  };

  const handleSaveToTemplates = (awsAccountIds) => {
    const dialogContent = {
      open: true,
      title: 'Save Block to Templates',
      onSubmit: () =>
        saveBlockToTemplatesAction({ id: selectedBlock.id, awsAccountIds }),
      submitText: 'Save to Templates',
    };

    setConfirmationDialogContent({
      text: `Do you want to update all templates that use this block? Note: Templates will not be published, so you can review them before publishing.`,
      ...dialogContent,
    });
  };

  const handlePublishToTemplates = (awsAccountIds) => {
    const dialogContent = {
      open: true,
      title: 'Publish Block to Templates',
      onSubmit: () =>
        publishBlockToTemplatesAction({ id: selectedBlock.id, awsAccountIds }),
      submitText: 'Publish to Templates',
    };

    setConfirmationDialogContent({
      text: `Do you want to publish all templates that use this block?
            Please note, if you are modifying placeholders within this block 
            that are not managed by your current interpolation logic, publishing may 
            lead to unexpected issues. Proceed carefully and review your templates 
            after publishing to ensure accuracy.`,
      ...dialogContent,
    });
  };

  if (loading || !selectedBlock) {
    return <LogoLoader />;
  }

  const formattedDate = new Date(selectedBlock.lastModifiedTimestamp);
  const shortDate = `${formattedDate.toLocaleDateString([], {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  })} ${formattedDate.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  })}`;

  const fullDate = formattedDate.toLocaleString();

  return (
    <>
      <AppBar title={selectedBlock.label}>
        <Box mt={1.5}>
          <BlockPublishButton
            unsavedChanges={unsavedChanges}
            onSave={handleSaveBlock}
            onSaveToTemplates={handleSaveToTemplates}
            onPublishToTemplates={handlePublishToTemplates}
            loading={updating || saving || publishing}
            block={selectedBlock}
          />
        </Box>
        <InfoContainer>
          <Typography variant="subtitle1">
            <b>Version:</b> {selectedBlock.versionNumber}
          </Typography>
          <Tooltip title={fullDate}>
            <Typography variant="subtitle1">
              <b>Updated:</b> {shortDate}
            </Typography>
          </Tooltip>
        </InfoContainer>
        <InfoContainer>
          <Typography variant="subtitle1">
            <b>Description:</b>{' '}
            {selectedBlock?.description.length > 25
              ? `${selectedBlock.description.substring(0, 25)}...`
              : selectedBlock.description}
          </Typography>
          <Typography style={{ display: 'flex' }} variant="subtitle1">
            <TemplateCountLink
              onClick={() => setBlockTemplatesDialogOpen(true)}
            >
              View Template Usages
            </TemplateCountLink>
          </Typography>
        </InfoContainer>
      </AppBar>
      <Container>
        <EmailEditor
          style={{
            height: `calc(100vh - 60px)`,
            marginTop: '60px',
          }}
          ref={emailEditorRef}
          onLoad={handleLoadBlockDesign}
          projectId={6799}
          options={editorOptions}
        />
      </Container>
      <AlertDialog
        {...confirmationDialogContent}
        onClose={() =>
          setConfirmationDialogContent({
            open: false,
          })
        }
        loading={updating || saving || publishing}
      />
      <BlockTemplatesDialog
        blockId={blockId}
        open={blockTemplatesDialogOpen}
        onClose={() => setBlockTemplatesDialogOpen(false)}
      ></BlockTemplatesDialog>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    selectedBlock: state.blocks?.selectedBlock,
    themeMode: state.layout.themeMode,
    currentAwsAccount: state.aws?.default,
  };
};

export default connect(mapStateToProps, null)(ReusableBlockDetails);
