import React from 'react';
import PropTypes from 'prop-types';
import { FastField as Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { Grid } from '@material-ui/core';
import { AsyncDialogActions, FieldWrapper } from 'components/Base';
import * as Yup from 'yup';

const RenameSchema = Yup.object().shape({
  label: Yup.string().required(
    'Please enter a new label for this reusable block.'
  ),
  description: Yup.string().required('Please enter a new short description'),
});

const RenameReusableBlockForm = ({
  loading,
  onClose,
  onSubmit,
  initialValues,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={RenameSchema}
      onSubmit={onSubmit}
    >
      {() => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FieldWrapper
                label="New Reusable Block Label"
                subtitle="New label for the reusable block."
              >
                <Field
                  component={TextField}
                  fullWidth
                  name="label"
                  variant="outlined"
                />
              </FieldWrapper>
            </Grid>
            <Grid item xs={12}>
              <FieldWrapper
                label="New Reusable Block Description"
                subtitle="A new short description explaining the reusable block."
              >
                <Field
                  component={TextField}
                  fullWidth
                  name="description"
                  variant="outlined"
                />
              </FieldWrapper>
            </Grid>
            <AsyncDialogActions
              onClose={onClose}
              loading={loading}
              submitText={'Rename'}
            />
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

RenameReusableBlockForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    label: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

export default RenameReusableBlockForm;
