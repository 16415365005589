import React from 'react';
import { connect } from 'react-redux';
import { Tooltip } from '@material-ui/core';
import { actionTooltips } from 'consts';

/**
 * Function to check if the user has the required permission.
 * @param {Array} userPermissions - Array of user permissions from the Redux store.
 * @param {string} requiredPermission - The permission that needs to be checked.
 * @returns {boolean} - Returns true if the user has the required permission, false otherwise.
 */
export const hasPermission = (userPermissions, requiredPermission) => {
  return userPermissions.some((userPerm) => userPerm.id === requiredPermission);
};

const PermissionWrapper = ({ children, permission, userPermissions }) => {
  if (!permission || hasPermission(userPermissions, permission)) {
    return children;
  }

  const tooltipMessage =
    actionTooltips[permission] ||
    'Permission needed. Please consult account admin.';

  return (
    <Tooltip title={tooltipMessage}>
      <div>{React.cloneElement(children, { disabled: true })}</div>
    </Tooltip>
  );
};

const mapStateToProps = (state) => ({
  userPermissions: state.user?.permissions ?? [],
});

export default connect(mapStateToProps)(PermissionWrapper);
