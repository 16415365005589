const INITIAL_STATE = {
  entities: [],
  selectedBlock: undefined,
  count: 0,
};

const applySetBlocks = (state, { payload }) => {
  return {
    ...state,
    ...payload,
  };
};

const applyAddBlock = (state, { payload }) => {
  return {
    ...state,
    entities: [...state.entities, payload],
    count: state.count + 1,
  };
};

const applyRemoveBlock = (state, { payload }) => {
  const entities = state.entities.filter((item) => item.id !== payload);
  return {
    ...state,
    entities,
    count: state.count - 1,
  };
};

const applyUpdateBlock = (state, { payload }) => {
  const updateIndex = state.entities.findIndex(
    (block) => block.id === payload.id
  );
  state.entities[updateIndex] = payload;
  state.selectedBlock = payload;
  return state;
};

const applySetBlockDetails = (state, { payload }) => {
  return {
    ...state,
    selectedBlock: payload,
  };
};

const templateReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'BLOCK_LIST_SET': {
      return applySetBlocks(state, action);
    }
    case 'BLOCK_REMOVE': {
      return applyRemoveBlock(state, action);
    }
    case 'BLOCK_UPDATE': {
      return applyUpdateBlock(state, action);
    }
    case 'BLOCK_ADD': {
      return applyAddBlock(state, action);
    }
    case 'BLOCK_DETAILS_SET': {
      return applySetBlockDetails(state, action);
    }
    default:
      return state;
  }
};

export default templateReducer;
