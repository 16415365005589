import React, { Fragment, useRef, useState } from 'react';
import {
  ClickAwayListener,
  Divider,
  Grow,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
  styled,
} from '@material-ui/core';
import { MoreVert } from 'assets/icons';

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[300]}`,

  '&:last-child': {
    borderBottom: 'none',
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(0.5),
  transition: 'all 0.15s ease',
  border: `1px solid transparent`,
  marginRight: theme.spacing(2),

  '&:hover': {
    boxShadow: theme.custom.standardShadow,
    border: `1px solid ${theme.palette.grey[400]}`,
  },
}));

const TemplateTableActions = ({ template, actions }) => {
  const [actionsOpen, setActionsOpen] = useState(false);
  const actionAnchorRef = useRef(null);

  return (
    <Fragment>
      <StyledIconButton
        ref={actionAnchorRef}
        aria-label="open additional actions"
        onClick={() => setActionsOpen(true)}
        edge="end"
      >
        <MoreVert size={26} />
      </StyledIconButton>
      <Popper
        key={`template-popper-${template.uid}`}
        open={actionsOpen}
        anchorEl={actionAnchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement={'bottom-end'}
        popperOptions={{
          modifiers: {
            preventOverflow: {
              padding: 0,
            },
          },
        }}
        style={{ zIndex: 1 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
            <Paper>
              <ClickAwayListener onClickAway={() => setActionsOpen(false)}>
                <MenuList disablePadding>
                  {actions.map(
                    ({
                      label,
                      icon,
                      onClick,
                      enabledCondition,
                      disabledTooltipText,
                    }) => {
                      if (
                        enabledCondition !== undefined &&
                        !enabledCondition()
                      ) {
                        return (
                          <Tooltip title={disabledTooltipText}>
                            <div>
                              <StyledMenuItem
                                key={`action-list-${label}-${template.uid}`}
                                onClick={() => onClick(template)}
                                disabled
                              >
                                <ListItemIcon>{icon}</ListItemIcon>
                                <ListItemText>{label}</ListItemText>
                                <Divider sx={{ my: 0.5 }} />
                              </StyledMenuItem>
                            </div>
                          </Tooltip>
                        );
                      }
                      return (
                        <StyledMenuItem
                          key={`action-list-${label}-${template.uid}`}
                          onClick={() => onClick(template)}
                        >
                          <ListItemIcon>{icon}</ListItemIcon>
                          <ListItemText>{label}</ListItemText>
                          <Divider sx={{ my: 0.5 }} />
                        </StyledMenuItem>
                      );
                    }
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};

export default TemplateTableActions;
