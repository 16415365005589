import React from 'react';
import UpgradePlanForm from './UpgradePlanForm';
import { Actions, BoldText, Text } from './styles';
import { connect } from 'react-redux';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  styled,
} from '@material-ui/core';
import { Headline } from 'components/WelcomeSlides/Slides/styles';
import { Close } from 'assets/icons';
import { useSubscription } from '../Contexts';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: '0',
  },
  '& .MuiDialog-paper': {
    borderRadius: '16px',
  },
}));

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  position: 'relative',
  minHeight: '6rem',
  overflowY: 'auto',
  overflowX: 'hidden',
  backgroundColor: theme.palette.background.main,
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.reverted,
  position: 'absolute',
  top: theme.spacing(1.5),
  right: theme.spacing(1.5),
  '&:hover': {
    backgroundColor: theme.palette.background.light,
  },

  '& .MuiSvgIcon-root': {
    color: `${theme.palette.primary.main} !important`,
  },
}));

const CenteredHeadline = styled(Headline)(({ theme }) => ({
  textAlign: 'center',
}));

const UpgradeSubscriptionDialog = ({ upgradeRequested }) => {
  const {
    closeUpgradeDialog,
    handleUpgrade,
    submitUpgradeLoading,
    upgradeDialogOpen,
  } = useSubscription();

  const StripePaymentLink = ({ paymentSlug, planName }) => {
    const baseUrl = 'https://payments.semplates.io/b';
    return (
      <a href={`${baseUrl}/${paymentSlug}`} target="_blank" rel="noreferrer">
        {planName}
      </a>
    );
  };

  const UpgradeRequestedDialogContent = () => {
    if (upgradeRequested) {
      return (
        <>
          <Box mx={6} mt={4} mb={2}>
            <CenteredHeadline>
              👋 🤩 📨
              <br></br>
              Thanks for upgrading
            </CenteredHeadline>
          </Box>
          <Box mx={6} my={3} textAlign={'center'}>
            <Text>You were redirected to stripe payment link.</Text>
            <br></br>
            <Text>
              Please fill in all the necessary information in order to upgrade
              to a paid plan.
            </Text>
            <br></br>
            <br></br>
            <BoldText>Need urgent support?</BoldText>
            <br></br>
            <Text>
              Please use the chat in the lower left corner or send an email to:
            </Text>
            <br></br>
            <BoldText>support@semplates.io</BoldText>
            <br></br>
            <br></br>

            <Text>
              The Stripe Payment Link did not open? Try again here:
              <br></br>
              <StripePaymentLink
                paymentSlug="8wM7uP9TX8vW0sU4gl"
                planName="Starter Plan, monthly billed"
              />
              <br></br>
              <StripePaymentLink
                paymentSlug="6oE7uPaY127y5Ne7sw"
                planName="Starter Plan, annually billed"
              />
              <br></br>
              <StripePaymentLink
                paymentSlug="14kcP90jn7rSa3ufZ5"
                planName="Business Plan, monthly billed"
              />
              <br></br>
              <StripePaymentLink
                paymentSlug="8wM7uP9TX8vW0sU4gl"
                planName="Business Plan, annually billed"
              />
            </Text>
          </Box>
          <Actions justifyContent="center">
            <Button
              variant="contained"
              color="secondary"
              onClick={closeUpgradeDialog}
            >
              {' '}
              Close{' '}
            </Button>
          </Actions>
        </>
      );
    } else {
      return (
        <>
          <Box mx={6} mt={4} mb={2}>
            <Headline>Upgrade your plan</Headline>
          </Box>
          <UpgradePlanForm
            onSubmit={handleUpgrade}
            onClose={closeUpgradeDialog}
            loading={submitUpgradeLoading}
          />
        </>
      );
    }
  };

  return (
    <StyledDialog
      open={upgradeDialogOpen}
      onClose={closeUpgradeDialog}
      maxWidth={upgradeRequested ? 'sm' : 'md'}
    >
      <StyledDialogContent>
        <CloseButton onClick={closeUpgradeDialog}>
          <Close size={25} />
        </CloseButton>
        <UpgradeRequestedDialogContent />
      </StyledDialogContent>
    </StyledDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    upgradeDialogOpen: state.layout.upgradeDialogOpen,
    upgradeRequested: state.company?.subscription?.upgradeRequested || false,
  };
};

export default connect(mapStateToProps)(UpgradeSubscriptionDialog);
