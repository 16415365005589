import React from 'react';
import { Drawer, Box } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

const StyledDrawer = styled(Drawer)`
  & .MuiPaper-root {
    overflow-x: hidden;
    overflow-y: hidden;
  }
`;

export const DrawerContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 3),
  overflowY: 'auto',
  flexGrow: 1,
  minHeight: 'calc(100% - 58px)',
}));

export const DrawerFooter = styled('div')(({ theme }) => ({
  padding: theme.spacing(1.5, 3.75, 1.5, 2),
  backgroundColor: theme.palette.background.main,
  boxShadow: '0 0 2px 2px rgba(0, 0, 0, 0.05)',
  display: 'flex',
  justifyContent: 'space-between',
  position: 'sticky',
  bottom: 0,
  zIndex: 1000,
}));

const DetailDrawer = ({ open, onClose, children, PaperProps }) => {
  return (
    <StyledDrawer
      anchor={'right'}
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          overflowX: 'hidden',
          ...PaperProps.style,
        },
        ...PaperProps,
      }}
    >
      {children}
    </StyledDrawer>
  );
};

export default DetailDrawer;
