import React from 'react';
import PropTypes from 'prop-types';
import { FastField as Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { Grid } from '@material-ui/core';
import { AsyncDialogActions, FieldWrapper } from 'components/Base';
import * as Yup from 'yup';

const CreateSchema = Yup.object().shape({
  label: Yup.string().required('Please enter a label for this User Group.'),
  description: Yup.string().required('Please enter a short description'),
});

const CreateUserGroupForm = ({ loading, onClose, onSubmit }) => {
  return (
    <Formik
      initialValues={{
        label: '',
        description: '',
      }}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={CreateSchema}
      onSubmit={onSubmit}
    >
      {() => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FieldWrapper
                label="User Group label"
                subtitle="Label of User Group to be shown to your company members."
              >
                <Field
                  component={TextField}
                  fullWidth
                  name="label"
                  variant="outlined"
                />
              </FieldWrapper>
            </Grid>
            <Grid item xs={12}>
              <FieldWrapper
                label="User Group Description"
                subtitle="A short description explaining the permission set of the User Group."
              >
                <Field
                  component={TextField}
                  fullWidth
                  name="description"
                  variant="outlined"
                />
              </FieldWrapper>
            </Grid>
            <AsyncDialogActions
              onClose={onClose}
              loading={loading}
              submitText={'Create'}
            />
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

CreateUserGroupForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default CreateUserGroupForm;
