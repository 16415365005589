import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { EnhancedTableHeader, EnhancedTableTitle, Loader } from './';
import { useHistory } from 'react-router-dom';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import {
  Box,
  Checkbox,
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { updateTemplateTags } from 'state/actions';
import { SenderNameForm } from '../Menu/CompanySettings/Shared/components';
import { DefaultEmailRow } from '../Menu/Identities';
import {
  getComparator,
  ShimmerContainer,
  stableSort,
  tableCellStyles,
  TableTitle,
  StyledImage,
  TableActions,
  TableIcons,
  StyledIconButton,
  StyledLabel,
} from './TableUtils';
import { ColoredTag, PermissionsWrapper } from 'components/Base';
import { ReusableBlockTags } from 'components/Menu/Templates/Blocks';
import { StatusChip } from 'components/Menu/Templates/Shared';

const CollapsedTableRow = styled(TableRow)(({ theme }) => ({
  borderBottom: 'none',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  boxShadow: 'none',
  backgroundColor: theme.palette.bg.main,
}));

const PointerBox = styled(Box)({
  cursor: 'pointer',
});

const EnhancedTable = ({
  actions,
  columns,
  data,
  title,
  total,
  onRowClick,
  onChangeSenderName,
  onChangeIsTestSender,
  onLoadingSenderName,
  onLoadingEmailVisibility,
  onSaveTags,
  scopedMail,
  onSetScopedEntity,
  anyDialogOpen,
  rowsPerPage,
  setRowsPerPage,
  shimmer,
  loading,
  page,
  setPage,
  styleOverwrites,
  outlined,
  entityName = '',
}) => {
  const [dense] = useState(false);
  const [isCmdPressed, setIsCmdPressed] = useState(false);
  const [uncollapsed, setUncollapsed] = useState({
    rowIndex: null,
    colId: null,
    rowId: null,
  });
  const history = useHistory();
  const queryKey = entityName ? `${entityName}_order` : 'order';
  const orderByKey = entityName ? `${entityName}_orderBy` : 'orderBy';

  const [query, setQuery] = useQueryParams({
    [queryKey]: withDefault(StringParam, 'asc'),
    [orderByKey]: withDefault(StringParam, ''),
  });

  const adjustPage = () => {
    if (total && rowsPerPage && page) {
      const lastPage = Math.ceil(total / rowsPerPage) - 1;
      if (page > lastPage) {
        setPage(lastPage);
      }
    }
  };

  const onOpenBlock = async (event, blockId) => {
    if (event.metaKey || event.ctrlKey) {
      window.open(`/templates/reusable-blocks?blockId=${blockId}`, '_blank');
    } else {
      let path = `/templates/reusable-blocks?blockId=${blockId}`;
      history.push(path);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.metaKey || event.ctrlKey) {
        setIsCmdPressed(true);
      }
    };

    const handleKeyUp = (event) => {
      if (event.key === 'Meta' || event.key === 'Control') {
        setIsCmdPressed(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  useEffect(() => {
    adjustPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total]);

  const handleRequestSort = (event, property) => {
    const isAsc = query[orderByKey] === property && query[queryKey] === 'asc';
    setQuery(
      { [queryKey]: isAsc ? 'desc' : 'asc', [orderByKey]: property },
      'pushIn'
    );
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getIsUncollapsed = (rowIndex, colId) => {
    return uncollapsed.rowIndex === rowIndex && uncollapsed.colId === colId;
  };

  const handleOnClick = (rowIndex, colId, rowId) => {
    if (
      uncollapsed.rowIndex === rowIndex &&
      uncollapsed.colId === colId &&
      rowId === uncollapsed.rowId
    ) {
      setUncollapsed({ rowIndex: null, colId: null, rowId: null });
    } else {
      setUncollapsed({ rowIndex, colId, rowId });
    }
  };

  const showUncollapsed = (rowIndex) => {
    return uncollapsed.rowIndex === rowIndex;
  };

  const getUncollapsedContent = (row, columns) => {
    const content = row[uncollapsed.colId];
    const uncollapsedColumn = columns.find(
      (col) => col.id === uncollapsed.colId
    );

    if (uncollapsedColumn?.getCollapseContent) {
      return uncollapsedColumn.getCollapseContent(content);
    }
    return <></>;
  };

  const columnsContainCollapsible = columns.some(
    (col) => col.type === 'collapsible'
  );

  return (
    <Fragment>
      <ShimmerContainer>
        {(shimmer || loading) && (
          <Loader overlay overlayShimmer spinner={false} />
        )}
        <StyledPaper variant={outlined || null}>
          {title && <EnhancedTableTitle title={title} />}
          <TableContainer>
            <Table
              style={{ minWidth: 750, ...styleOverwrites }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
              aria-label="enhanced table"
            >
              <EnhancedTableHeader
                headCells={columns}
                order={query[queryKey]}
                orderBy={query[orderByKey]}
                onRequestSort={handleRequestSort}
              />

              <TableBody>
                {stableSort(
                  data,
                  getComparator(query[queryKey], query[orderByKey])
                )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    const rowId = row.id || row.uid;
                    return (
                      <React.Fragment key={`${rowId}-${index}`}>
                        <TableRow hover tabIndex={-1}>
                          {columns.map((col) => {
                            switch (col.type) {
                              case 'subtitled':
                                return (
                                  <TableCell
                                    styles={tableCellStyles}
                                    component="th"
                                    id={labelId}
                                    key={`${col.id}-${index}`}
                                    scope="row"
                                  >
                                    <Fragment>
                                      <TableTitle>{row[col.id]}</TableTitle>
                                      <Box mt={0.5}>{row[col.subId]}</Box>
                                    </Fragment>
                                  </TableCell>
                                );
                              case 'collapsible':
                                return (
                                  <TableCell
                                    styles={tableCellStyles}
                                    component="th"
                                    id={labelId}
                                    key={`${rowId}${col.id}-${index}`}
                                    scope="row"
                                  >
                                    {getIsUncollapsed(index, col.id) ? (
                                      <Tooltip title={'Hide'}>
                                        <StyledIconButton
                                          onClick={() =>
                                            handleOnClick(index, col.id, rowId)
                                          }
                                        >
                                          <KeyboardArrowDownIcon />
                                        </StyledIconButton>
                                      </Tooltip>
                                    ) : (
                                      <Tooltip title={'Show'}>
                                        <StyledIconButton
                                          onClick={() =>
                                            handleOnClick(index, col.id, rowId)
                                          }
                                        >
                                          <KeyboardArrowRightIcon />
                                        </StyledIconButton>
                                      </Tooltip>
                                    )}
                                  </TableCell>
                                );
                              case 'image':
                                return (
                                  <TableCell
                                    styles={tableCellStyles}
                                    component="th"
                                    id={labelId}
                                    key={`${col.id}-${index}`}
                                    scope="row"
                                  >
                                    <StyledImage
                                      backgroundImage={row[col.id]}
                                    />
                                  </TableCell>
                                );
                              case 'text':
                                return (
                                  <TableCell
                                    style={col.width && { width: col.width }}
                                    styles={tableCellStyles}
                                    component="th"
                                    id={labelId}
                                    key={`${col.id}-${index}`}
                                    scope="row"
                                    onClick={(event) =>
                                      onRowClick && onRowClick(event, row.uid)
                                    }
                                  >
                                    <StyledLabel>
                                      {row[col.id] !== undefined
                                        ? row[col.id]
                                        : row['identity']}
                                    </StyledLabel>
                                  </TableCell>
                                );
                              case 'select':
                                return (
                                  <TableCell
                                    component="th"
                                    id={labelId}
                                    key={`${col.id}-${index}`}
                                    scope="row"
                                    align={col.align || 'left'}
                                  >
                                    <Checkbox
                                      checked={col.isSelected(row)}
                                      onChange={() => col.onSelect(row)}
                                      inputProps={{
                                        'aria-label': `Select ${entityName}`,
                                      }}
                                    />
                                  </TableCell>
                                );
                              case 'labeledValues':
                                const values = row[col.id];
                                let displayValues = '';

                                if (Array.isArray(values)) {
                                  if (values.length <= 2) {
                                    displayValues = values
                                      .map((item) => item.label)
                                      .join(',\n');
                                  } else {
                                    const firstTwoLabels = values
                                      .slice(0, 2)
                                      .map((item) => item.label)
                                      .join(',');
                                    displayValues = `${firstTwoLabels}, + ${values.length -
                                      2} more`;
                                  }
                                } else {
                                  displayValues = row['identity'];
                                }

                                return (
                                  <TableCell
                                    style={col.width && { width: col.width }}
                                    styles={tableCellStyles}
                                    component="th"
                                    id={labelId}
                                    key={`${col.id}-${index}`}
                                    scope="row"
                                    onClick={(event) =>
                                      onRowClick && onRowClick(event, row.uid)
                                    }
                                  >
                                    <StyledLabel>{displayValues}</StyledLabel>
                                  </TableCell>
                                );
                              case 'date':
                                return (
                                  <TableCell
                                    component="th"
                                    id={labelId}
                                    key={`${col.id}-${index}`}
                                    scope="row"
                                  >
                                    <Moment format="MMMM D, YYYY" utc local>
                                      {row[col.id]}
                                    </Moment>
                                  </TableCell>
                                );
                              case 'link':
                                return (
                                  <TableCell
                                    component="th"
                                    id={labelId}
                                    key={`${col.id}-${index}`}
                                    scope="row"
                                  >
                                    {row[col.id] ? (
                                      <Tooltip title={row[col.id]}>
                                        <a
                                          href={row[col.id]}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          open product
                                        </a>
                                      </Tooltip>
                                    ) : (
                                      'not published'
                                    )}
                                  </TableCell>
                                );
                              case 'color':
                                return (
                                  <TableCell
                                    component="th"
                                    id={labelId}
                                    key={`${col.id}-${index}`}
                                    scope="row"
                                  >
                                    <ColoredTag background={row[col.id]}>
                                      {row[col.id]}
                                    </ColoredTag>
                                  </TableCell>
                                );
                              case 'actions':
                                return (
                                  <TableActions
                                    actions={actions}
                                    row={row}
                                    col={col}
                                    labelId={labelId}
                                    key={`${col.id}-${index}`}
                                  />
                                );
                              case 'icons':
                                return (
                                  <TableIcons
                                    icons={col.getIcons(row)}
                                    col={col}
                                    labelId={labelId}
                                    key={`${col.id}-${index}`}
                                  />
                                );
                              case 'setDefault':
                                return (
                                  <TableCell
                                    align={col.align}
                                    component="th"
                                    id={labelId}
                                    key={`${col.id}-${index}`}
                                    scope="row"
                                  >
                                    <DefaultEmailRow rowData={row} />
                                  </TableCell>
                                );
                              case 'checkbox':
                                return (
                                  <TableCell
                                    align={col.align}
                                    component="th"
                                    id={labelId}
                                    key={`${col.id}-${index}`}
                                    scope="row"
                                  >
                                    <div style={{ position: 'relative' }}>
                                      {scopedMail?.uid === row.uid &&
                                      onLoadingEmailVisibility ? (
                                        <Loader />
                                      ) : (
                                        <PermissionsWrapper
                                          permission={'write_email_identities'}
                                        >
                                          <Checkbox
                                            checked={row[col.id]}
                                            id={row.uid || row.id}
                                            onChange={() => {
                                              onSetScopedEntity(row);
                                              onChangeIsTestSender(
                                                row.uid || row.id,
                                                !row[col.id]
                                              );
                                            }}
                                          />
                                        </PermissionsWrapper>
                                      )}
                                    </div>
                                  </TableCell>
                                );
                              case 'editableColumn':
                                return (
                                  <TableCell
                                    component="th"
                                    id={labelId}
                                    key={`${col.id}-${index}`}
                                    scope="row"
                                  >
                                    <Fragment>
                                      {scopedMail?.uid === row.uid &&
                                      !anyDialogOpen &&
                                      !onLoadingEmailVisibility ? (
                                        <SenderNameForm
                                          displayName={row.senderName}
                                          loading={onLoadingSenderName}
                                          onSubmit={onChangeSenderName}
                                          onAbort={() =>
                                            onSetScopedEntity(null)
                                          }
                                        />
                                      ) : (
                                        <p
                                          onClick={() => onSetScopedEntity(row)}
                                        >
                                          {row.senderName}
                                        </p>
                                      )}
                                    </Fragment>
                                  </TableCell>
                                );
                              case 'title':
                                return (
                                  <TableCell
                                    style={col.width && { width: col.width }}
                                    component="th"
                                    key={`${col.id}-${index}`}
                                    scope="row"
                                    onClick={(event) =>
                                      onOpenBlock(event, row.id)
                                    }
                                  >
                                    <PointerBox>
                                      <Box display="flex" position="relative">
                                        <Tooltip
                                          title={
                                            'Press CMD (Mac) or Ctrl (Windows) to open in new tab'
                                          }
                                        >
                                          <TableTitle
                                            cmdpressed={isCmdPressed.toString()}
                                          >
                                            {row.label}
                                          </TableTitle>
                                        </Tooltip>
                                      </Box>
                                      <Typography variant="subtitle2">
                                        {row.description}
                                      </Typography>
                                    </PointerBox>
                                  </TableCell>
                                );
                              case 'tags':
                                return (
                                  <TableCell
                                    style={col.width && { width: col.width }}
                                    component="th"
                                    key={`${col.id}-${index}`}
                                    scope="row"
                                  >
                                    <ReusableBlockTags
                                      block={row}
                                      size="small"
                                      onSave={onSaveTags}
                                    />
                                  </TableCell>
                                );
                              case 'status':
                                return (
                                  <TableCell
                                    component="th"
                                    key={`${col.id}-${index}`}
                                    scope="row"
                                  >
                                    <PointerBox>
                                      <StatusChip status={row.state} />
                                    </PointerBox>
                                  </TableCell>
                                );
                              default:
                                return null;
                            }
                          })}
                        </TableRow>
                        {columnsContainCollapsible &&
                          uncollapsed.rowId === rowId && (
                            <CollapsedTableRow
                              key={`${row.uid}-${row.index}-collapsed`}
                            >
                              <TableCell style={{ padding: 0 }} colSpan={6}>
                                <Collapse
                                  in={showUncollapsed(index)}
                                  timeout="auto"
                                  unmountOnExit
                                  style={{ width: '100%' }}
                                >
                                  {showUncollapsed &&
                                    getUncollapsedContent(row, columns)}
                                </Collapse>
                              </TableCell>
                            </CollapsedTableRow>
                          )}
                      </React.Fragment>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          {total === 0 && (
            <Box py={4} textAlign="center">
              <Typography variant="body2">Nothing to show here</Typography>
            </Box>
          )}
          {total >= 0 && total > rowsPerPage && (
            <TablePagination
              rowsPerPageOptions={setRowsPerPage ? [5, 10, 25] : []}
              component="div"
              count={total}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </StyledPaper>
      </ShimmerContainer>
    </Fragment>
  );
};

EnhancedTable.defaultProps = {
  data: [],
};

EnhancedTable.propTypes = {
  actions: PropTypes.array,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  onRowClick: PropTypes.func,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  entityName: PropTypes.string, // Added entityName prop
};

const mapStateToProps = (state) => {
  return {
    shimmer: state.layout.shimmerOverlay,
  };
};

export const mapDispatchToProps = {
  updateTemplateTags: updateTemplateTags,
};

export default connect(mapStateToProps, mapDispatchToProps)(EnhancedTable);
