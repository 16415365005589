const policyColumns = [
  {
    id: 'label',
    label: 'Name',
    type: 'text',
    sortable: true,
  },
  {
    id: 'description',
    label: 'Description',
    type: 'text',
    width: '50%',
    sortable: false,
  },
  {
    id: 'isDefault',
    label: 'Default Policy',
    type: 'text',
    sortable: false,
  },
  {
    id: 'permissionCount',
    label: 'Attached Permissions',
    type: 'text',
    sortable: false,
  },
  {
    align: 'right',
    id: 'Actions',
    label: 'Actions',
    type: 'actions',
  },
  // {
  //   id: 'Actions',
  //   label: 'Actions',
  //   type: 'actions',
  // },
];

export default policyColumns;
