const INITIAL_STATE = {
  all: [],
  default: undefined,
  count: 0,
};

const setDefaultAwsAccount = (state, { payload }) => {
  return {
    ...state,
    default: payload,
  };
};

const addAwsAccount = (state, { payload }) => {
  return {
    ...state,
    all: [...state.all, payload],
    default: [...state.all, payload].find((account) => account.isDefault),
    count: state.count + 1,
  };
};

const setAwsAccounts = (state, { payload }) => {
  return {
    ...state,
    all: payload.entities,
    count: payload.count,
    default: payload.entities.find((account) => account.isDefault),
  };
};

const updateAwsAccount = (state, { payload }) => {
  const updatedAccountId = payload.id;

  const updatedAccounts = state.all.map((account) =>
    account.id === updatedAccountId ? payload : account
  );

  return {
    ...state,
    all: updatedAccounts,
  };
};

const removeAwsCredentials = (state, { payload }) => {
  return {
    ...state,
    all: state.all.filter((account) => account.id !== payload),
    default: state.default.id === payload ? undefined : state.default,
    count: state.count - 1,
  };
};

// FixMe: Adjust to work for multiple aws_accounts
const setApiKey = (state, { payload }) => {
  if (state.default?.id === payload.id) {
    return {
      default: payload,
      all: [payload],
    };
  }

  return {
    ...state,
    all: [payload],
  };
};

const awsAccountReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'AWS_ACCOUNTS_SET': {
      return setAwsAccounts(state, action);
    }
    case 'DEFAULT_AWS_ACCOUNT_SET': {
      return setDefaultAwsAccount(state, action);
    }
    case 'AWS_ACCOUNT_ADD': {
      return addAwsAccount(state, action);
    }
    case 'API_KEY_UPDATE': {
      return setApiKey(state, action);
    }
    case 'AWS_ACCOUNT_UPDATE': {
      return updateAwsAccount(state, action);
    }
    case 'AWS_CREDENTIALS_REMOVE': {
      return removeAwsCredentials(state, action);
    }
    default:
      return state;
  }
};

export default awsAccountReducer;
