import React from 'react';
import PropTypes from 'prop-types';
import { DialogFormFrame } from 'components/Base';
import CreatePolicyForm from './CreatePolicyForm';
import { createPolicy } from 'state/actions';
import { useLazyLoading } from 'state/hooks';
import { useTracking } from 'components/Contexts/TrackingContext';
import { StringParam, useQueryParam } from 'use-query-params';

const CreatePolicyDialog = ({ open, onClose }) => {
  const { logEvent } = useTracking();
  const [, setPolicyId] = useQueryParam('policyId', StringParam);

  const [handleSubmit, { loading }] = useLazyLoading({
    action: createPolicy,
    onSuccess: (result) => {
      onClose();
      setPolicyId(result.id);
      logEvent({ category: 'policies', action: 'created' });
    },
  });

  return (
    <DialogFormFrame
      onClose={onClose}
      open={open}
      title={'Create a new Policy'}
      formComponent={
        <CreatePolicyForm
          loading={loading}
          onClose={onClose}
          onSubmit={handleSubmit}
        />
      }
    />
  );
};

CreatePolicyDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CreatePolicyDialog;
