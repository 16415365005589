import {
  setShimmerOverlay,
  showErrorSnackbar,
  showSuccessSnackbar,
} from './layout';
import {
  CREATE_EXISTING_TEMPLATE_ERROR,
  CREATE_TEMPLATE_SUCCESS,
  DELETE_TEMPLATE_ERROR,
  DELETE_TEMPLATE_SUCCESS,
  DUPLICATE_TEMPLATE_ERROR,
  DUPLICATE_TEMPLATE_IN_OTHER_REGION_SUCCESS,
  DUPLICATE_TEMPLATE_SUCCESS,
  IMPORT_TEMPLATES_ERROR,
  PUBLISH_TEMPLATE_ERROR,
  PUBLISH_TEMPLATE_SUCCESS,
  REACTIVATE_TEMPLATE_VERSION_ERROR,
  REACTIVATE_TEMPLATE_VERSION_SUCCESS,
  SEND_MAIL_SUCCESS,
  SEND_MAILS_SUCCESS,
  SEND_TEMPLATE_DATA_ERROR,
  UPDATE_TEMPLATE_SUCCESS,
  UPDATE_TEMPLATE_TAGS_ERROR,
  UPDATE_TEMPLATE_TAGS_SUCCESS,
} from '../consts/snackbarMessages';
import {
  handleBackendErrorMessage,
  handleUpgradeError,
} from '../utils/error_handling';

export const setTemplates = (templates) => ({
  type: 'TEMPLATE_LIST_SET',
  payload: templates,
});

export const setStarters = (samples) => ({
  type: 'TEMPLATE_SAMPLES_SET',
  payload: samples,
});

export const updateSelectedTemplate = (template) => ({
  type: 'TEMPLATE_SELECTED_SET',
  payload: template,
});

export const updateSelectedTemplateVersions = (template) => ({
  type: 'TEMPLATE_VERSIONS_SELECTED_SET',
  payload: template,
});

export const updateTemplatesAfterDelete = (id) => ({
  type: 'TEMPLATE_DELETE',
  payload: id,
});

export const importableTemplates = (templates) => ({
  type: 'IMPORTABLE_TEMPLATES_SET',
  payload: templates,
});

export const updateImportableTemplates = (templates) => ({
  type: 'IMPORTABLE_TEMPLATES_UPDATE',
  payload: templates,
});

export const getTemplates = ({
  limit,
  filter,
  skip = 0,
  tagIds,
  order,
  orderBy,
  status,
}) => async (dispatch, getState, { axios }) => {
  dispatch(setShimmerOverlay(true));
  const { entities: state } = getState().templates;
  // if state is empty or length of state is smaller than skip, then fetch all
  if (!state?.length || state.length < skip) {
    limit = skip + limit;
    skip = 0;
  }
  try {
    let query = `/templates?limit=${limit}&search=${filter}&skip=${skip}&tag_filter=${tagIds}`;
    if (order) query = query + `&order=${order}`;
    if (orderBy) query = query + `&order_by=${orderBy}`;
    if (status) query = query + `&status=${status}`;

    const {
      data: { entities, count },
    } = await axios.get(query);

    if (skip > 0) {
      const allTemplates = state.concat(entities);
      dispatch(
        setTemplates({
          entities: allTemplates,
          count,
        })
      );
    } else {
      dispatch(setTemplates({ entities, count }));
    }
    dispatch(setShimmerOverlay(false));
    return entities;
  } catch (error) {
    dispatch(setShimmerOverlay(false));
    throw error;
  }
};

export const loadTemplateStarters = () => async (
  dispatch,
  getState,
  { axios }
) => {
  const stateTemplates = getState().templates.samples;
  if (stateTemplates) return stateTemplates;
  try {
    const { data } = await axios.get(`/templates/samples`);
    dispatch(setStarters(data));
    return data.data;
  } catch (error) {
    throw error;
  }
};

export const getTemplate = ({ id }) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const { data } = await axios.get(`/template/${id}`);
    dispatch(updateSelectedTemplate(data));
    return data;
  } catch (error) {
    throw error;
  }
};

export const getTemplateVersions = ({ id, limit }) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const { data } = await axios.get(`/template/${id}/versions?limit=${limit}`);
    dispatch(updateSelectedTemplateVersions(data));
    return data;
  } catch (error) {
    throw error;
  }
};

export const createTemplate = (body) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const { data } = await axios.post(`/template`, body);
    dispatch(showSuccessSnackbar(CREATE_TEMPLATE_SUCCESS));
    return data.uid;
  } catch (error) {
    handleUpgradeError(error, dispatch);
    handleBackendErrorMessage(error, dispatch, CREATE_EXISTING_TEMPLATE_ERROR);
    throw error;
  }
};

export const updateTemplate = (
  template,
  bulk = false,
  blockUpdateType = undefined
) => async (dispatch, getState, { axios }) => {
  let templateData = {
    ...template,
    design: JSON.stringify(template?.design),
  };

  if (blockUpdateType) {
    templateData = {
      blockUpdateType,
      ...templateData,
    };
  }

  try {
    let query = `/template/${template.uid}`;
    if (bulk) {
      query = `${query}/all`;
    }
    const { data } = await axios.patch(query, templateData);
    dispatch(getTemplateVersions({ id: template.uid, limit: 5 }));
    dispatch(updateSelectedTemplate(data));
    dispatch(showSuccessSnackbar(UPDATE_TEMPLATE_SUCCESS));
    return data;
  } catch (error) {
    handleUpgradeError(error, dispatch);
    handleBackendErrorMessage(error, dispatch, CREATE_EXISTING_TEMPLATE_ERROR);
    throw error;
  }
};

export const publishTemplate = ({ uid, awsAccountId, bulk = false }) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    let query = `/template/${uid}`;
    if (awsAccountId) {
      query = `${query}?awsAccountId=${awsAccountId}`;
    }
    if (bulk) {
      query = `${query}/all`;
    }
    const { data } = await axios.put(query);
    dispatch(getTemplateVersions({ id: uid, limit: 5 }));
    dispatch(updateSelectedTemplate(data));
    dispatch(showSuccessSnackbar(PUBLISH_TEMPLATE_SUCCESS));
    return true;
  } catch (error) {
    handleUpgradeError(error, dispatch);
    handleBackendErrorMessage(error, dispatch, PUBLISH_TEMPLATE_ERROR);
    throw error;
  }
};

export const deleteTemplate = (uid) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    await axios.delete(`/template/${uid}`);
    dispatch(updateTemplatesAfterDelete(uid));
    dispatch(showSuccessSnackbar(DELETE_TEMPLATE_SUCCESS));
    return true;
  } catch (error) {
    showErrorSnackbar(DELETE_TEMPLATE_ERROR);
    throw error;
  }
};

export const duplicateTemplate = (
  templateId,
  duplicateName,
  target_account_uid,
  target_account_alias
) => async (dispatch, getState, { axios }) => {
  try {
    const payload = {
      duplicate_name: duplicateName,
      target_account_uid: target_account_uid,
    };

    const { data } = await axios.post(
      `/template/duplicate/${templateId}`,
      payload
    );

    dispatch(
      showSuccessSnackbar(
        target_account_uid
          ? DUPLICATE_TEMPLATE_IN_OTHER_REGION_SUCCESS + target_account_alias
          : DUPLICATE_TEMPLATE_SUCCESS
      )
    );
    return { uid: data.uid, target_account_uid: target_account_uid };
  } catch (error) {
    handleUpgradeError(error, dispatch);
    handleBackendErrorMessage(error, dispatch, DUPLICATE_TEMPLATE_ERROR);
    throw error;
  }
};

export const sendMail = ({
  templateId,
  templateInputs,
  senderEmailAddress,
  recipients,
}) => async (dispatch, getState, { axios }) => {
  try {
    const { data } = await axios.post(`template/${templateId}/send`, {
      variables: templateInputs,
      senderEmailAddress,
      recipients: recipients,
    });
    dispatch(
      showSuccessSnackbar(
        recipients.length > 1 ? SEND_MAILS_SUCCESS : SEND_MAIL_SUCCESS
      )
    );
    return data;
  } catch (error) {
    if (error.response?.status === 422) {
      dispatch(showErrorSnackbar(SEND_TEMPLATE_DATA_ERROR));
      return;
    }
    const response = error.response?.data;
    dispatch(showErrorSnackbar(response.detail));
    throw error;
  }
};

export const updateTemplateTags = (body) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const templateUid = body.templateUid;
    const { data: template } = await axios.put(
      `/template/${templateUid}/tags`,
      body
    );
    // delete timestamp since BE returns null.
    delete template.updatedTimestamp;
    dispatch(updateSelectedTemplate(template));
    dispatch(showSuccessSnackbar(UPDATE_TEMPLATE_TAGS_SUCCESS));
    return true;
  } catch (error) {
    dispatch(showErrorSnackbar(UPDATE_TEMPLATE_TAGS_ERROR));
    throw error;
  }
};

export const getImportableTemplates = () => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const { data: templates } = await axios.get(`/templates/importable`);
    dispatch(importableTemplates(templates));
    return true;
  } catch (error) {
    throw error;
  }
};

export const importTemplates = (templates) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const { templates: state } = getState().templates;
    const {
      data: { importedTemplates },
    } = await axios.post(`/templates/import`, templates);
    dispatch(updateImportableTemplates(importedTemplates));
    const allTemplates = [...importedTemplates, ...state];
    dispatch(setTemplates({ entities: allTemplates, count: templates.length }));
    return importedTemplates;
  } catch (error) {
    dispatch(showErrorSnackbar(IMPORT_TEMPLATES_ERROR));
    throw error;
  }
};

export const reactivateVersion = ({ id, versionNumber }) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const { data } = await axios.put(
      `/template/${id}/reactivate/${versionNumber}`
    );
    dispatch(updateSelectedTemplateVersions(data));
    dispatch(showSuccessSnackbar(REACTIVATE_TEMPLATE_VERSION_SUCCESS));
    return data;
  } catch (error) {
    dispatch(showErrorSnackbar(REACTIVATE_TEMPLATE_VERSION_ERROR));
    throw error;
  }
};
