const blockColumns = [
  {
    label: 'Label',
    id: 'label',
    type: 'title',
    sortable: true,
    width: '25%',
  },
  {
    label: 'Templates Used',
    id: 'templateCount',
    type: 'text',
    sortable: false,
  },
  {
    label: 'Version Number',
    id: 'versionNumber',
    type: 'text',
    sortable: true,
  },
  {
    id: 'status',
    label: 'Status',
    type: 'status',
  },
  {
    label: 'Creation Date',
    id: 'createdTimestamp',
    type: 'date',
    sortable: true,
  },
  {
    label: 'Last Updated',
    id: 'lastModifiedTimestamp',
    type: 'date',
    sortable: true,
  },
  {
    align: 'right',
    id: 'Actions',
    label: 'Actions',
    type: 'actions',
  },
];

export default blockColumns;
