import * as React from 'react';
import {
  Box,
  Grow,
  IconButton,
  Popper,
  Typography,
  useTheme,
} from '@material-ui/core';
import { InfoOutlined } from 'assets/icons';

const InfoBox = ({ infoText }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'info-popper' : undefined;

  return (
    <React.Fragment>
      <Box
        display="flex"
        alignItems="center"
        sx={{
          height: theme.spacing(3),
          padding: 0,
          margin: 0,
        }}
      >
        <IconButton
          aria-describedby={id}
          onHo
          onMouseEnter={handleClick}
          onMouseLeave={handleClick}
          sx={{
            padding: 0,
            margin: 0,
          }}
        >
          <InfoOutlined />
        </IconButton>
      </Box>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom"
        transition
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: 'center top' }}
            timeout={{ enter: 250, exit: 250 }} // Set the transition duration to 0.25s
          >
            <Box
              sx={{
                border: 1,
                padding: theme.spacing(2),
                borderRadius: theme.spacing(1),
                boxShadow: theme.shadows[20],
                backgroundColor: theme.palette.bg.main,
                maxWidth: theme.spacing(30),
              }}
            >
              <Typography variant="body2">{infoText}</Typography>
            </Box>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};

export default InfoBox;
