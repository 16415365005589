// companies.js

const INITIAL_STATE = {
  details: {},
  subscription: undefined,
  summary: {
    progress: 0,
  },
};

const applySetCompanyDetails = (state, { payload }) => {
  return {
    ...state,
    details: payload,
  };
};

const applySetCompanySummary = (state, { payload }) => {
  return {
    ...state,
    summary: {
      ...state.summary,
      ...payload,
      progress: payload.progress || state.summary.progress,
    },
  };
};

const applySetCompanySubscription = (state, { payload }) => {
  return {
    ...state,
    subscription: {
      ...payload,
    },
  };
};

const companyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'COMPANY_DETAILS_SET': {
      return applySetCompanyDetails(state, action);
    }
    case 'COMPANY_SUMMARY_SET': {
      return applySetCompanySummary(state, action);
    }
    case 'COMPANY_SUBSCRIPTION_SET': {
      return applySetCompanySubscription(state, action);
    }
    case 'SUBSCRIPTION_UPDATE': {
      return {
        ...state,
        subscription: {
          ...state.subscription,
          ...action.payload,
        },
      };
    }
    case 'AWS_CREDENTIALS_COUNT_INCREMENT': {
      return {
        ...state,
        subscription: {
          ...state.subscription,
          awsAccountsCount: state.subscription.awsAccountsCount + 1,
        },
      };
    }
    case 'AWS_CREDENTIALS_COUNT_DECREMENT': {
      return {
        ...state,
        subscription: {
          ...state.subscription,
          awsAccountsCount: state.subscription.awsAccountsCount - 1,
        },
      };
    }
    default:
      return state;
  }
};

export default companyReducer;
