import {
  CREATE_USER_GROUP_SUCCESS,
  CREATE_USER_GROUP_ERROR,
  DELETE_USER_GROUP_SUCCESS,
  DELETE_USER_GROUP_ERROR,
  DELETE_USER_GROUP_ERROR_FORBIDDEN,
  DUPLICATE_USER_GROUP_SUCCESS,
  DUPLICATE_USER_GROUP_ERROR,
  UPDATE_USER_GROUP_SUCCESS,
  UPDATE_USER_GROUP_ERROR,
} from 'state/consts/snackbarMessages';
import { showSuccessSnackbar, showErrorSnackbar } from 'state/actions/layout';
import { handleBackendErrorMessage } from 'state/utils/error_handling';

export const setUserGroups = (data) => ({
  type: 'USER_GROUPS_SET',
  payload: data,
});

export const setDeleteUserGroup = (data) => ({
  type: 'USER_GROUP_DELETE',
  payload: data,
});

export const setAddUserGroup = (data) => ({
  type: 'USER_GROUP_ADD',
  payload: data,
});

export const setUpdateUserGroup = (data) => ({
  type: 'USER_GROUP_UPDATE',
  payload: data,
});

export const fetchUserGroups = ({
  limit, // Default to undefined
  skip = 0,
  search = '',
  order = 'asc',
  orderBy = 'label',
} = {}) => async (dispatch, getState, { axios }) => {
  try {
    const { groups: state } = getState().userGroups;

    if (!state?.length || state.length < skip) {
      if (limit !== undefined) {
        limit = skip + limit;
      }
      skip = 0;
    }

    let query = `/iam/groups?skip=${skip}&order=${order}&order_by=${orderBy}`;
    if (limit !== undefined) {
      query += `&limit=${limit}`;
    }
    if (search) {
      query += `&search=${search}`;
    }

    const { data } = await axios.get(query);

    if (skip > 0 && state?.length) {
      const allGroups = state.concat(data.entities);
      dispatch(
        setUserGroups({
          entities: allGroups,
          count: data.count,
        })
      );
    } else {
      dispatch(setUserGroups({ entities: data.entities, count: data.count }));
    }

    return data;
  } catch (error) {
    throw error;
  }
};

export const createUserGroup = (values) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const { data } = await axios.post(`/iam/groups`, values);
    dispatch(setAddUserGroup(data));
    dispatch(showSuccessSnackbar(CREATE_USER_GROUP_SUCCESS));
    return data;
  } catch (error) {
    handleBackendErrorMessage(error, dispatch, CREATE_USER_GROUP_ERROR);
    throw error;
  }
};

export const fetchUserGroupDetails = (id) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const { data } = await axios.get(`/iam/groups/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateUserGroup = (
  id,
  { label, description, policyIds, awsAccountIds, userIds }
) => async (dispatch, getState, { axios }) => {
  try {
    const { data } = await axios.patch(`/iam/groups/${id}`, {
      label,
      description,
      policy_ids: policyIds,
      aws_account_ids: awsAccountIds,
      user_ids: userIds,
    });
    dispatch(setUpdateUserGroup);
    dispatch(showSuccessSnackbar(UPDATE_USER_GROUP_SUCCESS));
    return data;
  } catch (error) {
    handleBackendErrorMessage(error, dispatch, UPDATE_USER_GROUP_ERROR);
    throw error;
  }
};

export const deleteUserGroup = (id) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    await axios.delete(`/iam/groups/${id}`);
    dispatch(setDeleteUserGroup(id));
    dispatch(showSuccessSnackbar(DELETE_USER_GROUP_SUCCESS));
  } catch (error) {
    if (error.response?.status === 403) {
      dispatch(showErrorSnackbar(DELETE_USER_GROUP_ERROR_FORBIDDEN));
    } else {
      handleBackendErrorMessage(error, dispatch, DELETE_USER_GROUP_ERROR);
    }
    throw error;
  }
};

export const duplicateUserGroup = (sourceUserGroupId) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const { data } = await axios.post(`/iam/groups/duplicate`, {
      source_user_group_id: sourceUserGroupId,
    });
    dispatch(setAddUserGroup(data));
    dispatch(showSuccessSnackbar(DUPLICATE_USER_GROUP_SUCCESS));
    return data;
  } catch (error) {
    handleBackendErrorMessage(error, dispatch, DUPLICATE_USER_GROUP_ERROR);
    throw error;
  }
};
