import React, { Fragment } from 'react';
import { Grid } from '@material-ui/core';
import { LogoLoader } from 'components/Base';
import UserTable from './UserTable';
import PolicyTable from './PolicyTable';
import UserGroupTable from './UserGroupTable';
import {
  fetchCompanyMembers,
  fetchUserGroups,
  fetchPolicies,
} from 'state/actions';
import { useAsyncAction } from 'state/hooks';

const TeamAndAccess = () => {
  const { loading: loadingMembers } = useAsyncAction({
    action: fetchCompanyMembers,
  });

  const { loading: loadingGroups } = useAsyncAction({
    action: fetchUserGroups,
  });

  const { loading: loadingPolicies } = useAsyncAction({
    action: fetchPolicies,
  });

  if (loadingMembers || loadingGroups || loadingPolicies) {
    return <LogoLoader />;
  }

  return (
    <Fragment>
      <Grid container direction={'column'} spacing={10}>
        <Grid item>
          <UserTable />
        </Grid>
        <Grid item>
          <UserGroupTable />
        </Grid>
        <Grid item>
          <PolicyTable />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default TeamAndAccess;
