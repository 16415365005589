import React from 'react';
import { Box, styled, Typography, useTheme } from '@material-ui/core';

const ChipContainer = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[500]}`,
  display: 'flex',
  alignItems: 'center',
  borderRadius: '4px',
  width: 'fit-content',
  height: theme.custom.tagHeight,
}));

const Dot = styled('div')(({ theme, color }) => ({
  marginLeft: theme.spacing(1),
  height: '14px',
  width: '14px',
  borderRadius: '100%',
  background: color,
}));

const StatusChip = ({ status }) => {
  const theme = useTheme();
  const states = {
    published: {
      variant: 'outlined',
      color: theme.palette.success.main,
      label: 'Published',
    },
    draft: {
      variant: 'outlined',
      color: theme.palette.warning.main,
      label: 'Draft',
    },
    unpublished: {
      variant: 'outlined',
      color: theme.palette.info.main,
      label: 'Unpublished',
    },
    archived: {
      variant: 'outlined',
      color: theme.palette.grey[700],
      label: 'Archived',
    },
    // block states
    UPDATED: {
      variant: 'outlined',
      color: theme.palette.warning.main,
      label: 'Draft',
    },
    CREATED: {
      variant: 'outlined',
      color: theme.palette.warning.main,
      label: 'Draft',
    },
    PROPAGATED: {
      variant: 'outlined',
      color: theme.palette.info.main,
      label: 'Unpublished',
    },
    PUBLISHED: {
      variant: 'outlined',
      color: theme.palette.success.main,
      label: 'Published',
    },
  };

  const chipState = states[status];
  return (
    <ChipContainer>
      <Box alignItems="center">
        <Dot color={chipState?.color} />
      </Box>
      <Box ml={1} mr={2} my={1}>
        <Typography variant="body2" style={{ fontWeight: 600 }}>
          {chipState.label}
        </Typography>
      </Box>
    </ChipContainer>
  );
};

export default StatusChip;
