import {
  GoogleIcon,
  MicrosoftIcon,
  OktaIcon,
  EmailOutlined,
} from 'assets/icons';

const signInIcons = {
  microsoft: <MicrosoftIcon />,
  google: <GoogleIcon />,
  password: EmailOutlined,
  okta: <OktaIcon />,
};

const memberColumns = [
  {
    id: 'displayName',
    label: 'Name',
    type: 'text',
    sortable: true,
  },
  {
    id: 'createdTimestamp',
    label: 'Member since',
    type: 'date',
    sortable: true,
  },
  {
    id: 'userGroups',
    label: 'User Groups',
    type: 'labeledValues',
    sortable: false,
  },
  {
    id: 'verified',
    label: 'Verified',
    type: 'text',
    sortable: false,
  },
  {
    id: 'signInMethods',
    label: 'Sign In Methods',
    type: 'icons',
    getIcons: (row) =>
      row.signInMethods.map((method) => ({
        icon: method !== 'password' ? signInIcons[method] : undefined,
        Icon: method === 'password' ? signInIcons[method] : undefined,
        tooltip: `${method.charAt(0).toUpperCase() + method.slice(1)}`,
        disabled: false,
      })),
    sortable: false,
  },
  {
    align: 'right',
    id: 'Actions',
    label: 'Actions',
    type: 'actions',
  },
];

export default memberColumns;
