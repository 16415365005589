import PropTypes from 'prop-types';
import { DialogFormFrame } from 'components/Base';
import RenameReusableBlockForm from './RenameReusableBlockForm';

const RenameReusableBlockDialog = ({
  loading,
  open,
  onClose,
  onSubmit,
  selectedBlock,
}) => {
  return (
    <DialogFormFrame
      onClose={onClose}
      open={open}
      title={'Rename Reusable Block'}
      formComponent={
        <RenameReusableBlockForm
          loading={loading}
          onClose={onClose}
          onSubmit={onSubmit}
          initialValues={{
            label: selectedBlock?.label ?? '',
            description: selectedBlock?.description ?? '',
          }}
        />
      }
    />
  );
};

RenameReusableBlockDialog.propTypes = {
  loading: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default RenameReusableBlockDialog;
