import React, { Fragment, useState } from 'react';
import {
  AlertDialog,
  LogoLoader,
  OverviewTable,
  SettingsHeader,
  hasPermission,
} from 'components/Base';
import { deleteAwsCredentials, renewApiKey } from 'state/actions';
import {
  actionTooltips,
  awsAccountColumns,
  awsRegions,
  infoBoxTexts,
} from 'consts';
import { connect } from 'react-redux';
import { useLazyLoading } from 'state/hooks';
import {
  BarChart,
  DeleteOutline as DeleteIcon,
  InfoOutlined,
  LockOpenOutlined as LockOpenIcon,
} from '@material-ui/icons';
import { useSubscription, useTracking } from 'components/Contexts';
import { EditIcon } from 'assets/icons';
import {
  AwsAccountSendingStatisticsSection,
  AwsAccountStatusSection,
  AwsPermissionsSection,
} from './index';

const AwsAccountsSettings = ({
  awsAccounts,
  awsConnected,
  userPermissions,
}) => {
  const { logEvent } = useTracking();
  const { openAwsDialog } = useSubscription();
  const [scopedAccount, setScopedAccount] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [handleRenewApiKey, { loading: apiKeyLoading }] = useLazyLoading({
    action: renewApiKey,
    onSuccess: () => {
      logEvent({ category: 'aws_credentials', action: 'renew api key' });
      handleCloseDialog();
    },
  });

  const [deleteCredentials, { loading: deleteCredsLoading }] = useLazyLoading({
    action: deleteAwsCredentials,
    onSuccess: () => {
      logEvent({ category: 'aws_credentials', action: 'remove' });
      handleCloseDialog();
    },
  });

  const [deleteCredsDialogOpen, setDeleteCredsDialogOpen] = useState(false);
  const [renewAPIKeyDialogOpen, setRenewAPIKeyDialogOpen] = useState(false);
  const [showSendingStatistics, setShowSendingStatistics] = useState(false);
  const [showAccountStatus, setShowAccountStatus] = useState(false);
  const [showPermissions, setShowPermissions] = useState(false);

  const handleCloseDialog = () => {
    setDeleteCredsDialogOpen(false);
    setRenewAPIKeyDialogOpen(false);
  };

  const handleOpenEditDialog = (row) => {
    setShowAccountStatus(false);
    setShowSendingStatistics(false);
    setShowPermissions(false);
    setScopedAccount(row);
    openAwsDialog(row);
  };

  const handleDeleteDialogOpen = (row) => {
    setShowAccountStatus(false);
    setShowSendingStatistics(false);
    setShowPermissions(false);
    setScopedAccount(row);
    setDeleteCredsDialogOpen(true);
  };

  awsAccounts.map((item) =>
    Object.assign(item, {
      awsRegionName: `${
        awsRegions.find((region) => region.value === item.awsRegion).region
      } (${item.awsRegion})`,
    })
  );

  const handleSetPermissions = (row) => {
    setScopedAccount(row);
    setShowAccountStatus(false);
    setShowSendingStatistics(false);
    if (row.id === scopedAccount?.id) {
      setShowPermissions(!showPermissions);
    } else {
      setShowPermissions(true);
    }
  };

  const handleShowAccountStatusInfo = (row) => {
    setScopedAccount(row);
    setShowSendingStatistics(false);
    setShowPermissions(false);
    if (row.id === scopedAccount?.id) {
      setShowAccountStatus(!showAccountStatus);
    } else {
      setShowAccountStatus(true);
    }
  };

  const handleShowSendingStatistics = (row) => {
    setScopedAccount(row);
    setShowAccountStatus(false);
    setShowPermissions(false);
    if (row.id === scopedAccount?.id) {
      setShowSendingStatistics(!showSendingStatistics);
    } else {
      setShowSendingStatistics(true);
    }
  };

  if (!awsConnected) {
    return <LogoLoader />;
  }

  return (
    <Fragment>
      <SettingsHeader
        title={'Connected AWS Accounts'}
        buttonText={'Add AWS Account'}
        onClick={openAwsDialog}
        itemsCount={awsAccounts.length}
        infoText={infoBoxTexts.awsAccounts}
        primaryActionPermission={'write_aws_accounts'}
      />
      <OverviewTable
        data={awsAccounts}
        columns={awsAccountColumns}
        onSetScopedEntity={setScopedAccount}
        total={awsAccounts.length}
        actions={[
          {
            icon: <InfoOutlined size={22} />,
            label: 'Show Account Status Info',
            onClick: handleShowAccountStatusInfo,
          },
          {
            icon: <BarChart size={22} />,
            label: 'Show Sending Statistics',
            onClick: handleShowSendingStatistics,
          },
          {
            icon: <LockOpenIcon size={22} />,
            label: 'Features & AWS Permissions',
            onClick: handleSetPermissions,
          },
          {
            icon: <EditIcon size="26" />,
            label: 'Update AWS Credentials',
            onClick: handleOpenEditDialog,
            enabledCondition: () =>
              hasPermission(userPermissions, 'write_aws_accounts'),
            disabledTooltipText: actionTooltips.write_aws_accounts,
          },
          {
            icon: <DeleteIcon size={22} />,
            label: 'Remove',
            onClick: handleDeleteDialogOpen,
            enabledCondition: () =>
              hasPermission(userPermissions, 'write_aws_accounts'),
            disabledTooltipText: actionTooltips.write_aws_accounts,
          },
        ]}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
      <AlertDialog
        onClose={handleCloseDialog}
        open={deleteCredsDialogOpen}
        title={'Really want to remove these credentials?'}
        onSubmit={() => deleteCredentials(scopedAccount.id)}
        text={
          'You will not be able to send any emails from this account and your API Key becomes invalid.'
        }
        submitText={'Delete'}
        loading={deleteCredsLoading}
      />
      <AlertDialog
        onClose={handleCloseDialog}
        open={renewAPIKeyDialogOpen}
        title={'Really want to replace your API key?'}
        onSubmit={() => handleRenewApiKey(scopedAccount.id)}
        text={
          'You will not be able to recover that key. It will be invalidated and only the new key can be used further on.'
        }
        submitText={'Renew'}
        loading={apiKeyLoading}
      />
      {showAccountStatus && (
        <AwsAccountStatusSection
          open={showAccountStatus}
          account={scopedAccount}
          onError={() => setShowAccountStatus(false)}
        />
      )}
      {showSendingStatistics && (
        <AwsAccountSendingStatisticsSection
          open={showSendingStatistics}
          account={scopedAccount}
          onError={() => setShowSendingStatistics(false)}
        />
      )}
      {showPermissions && (
        <AwsPermissionsSection
          open={showPermissions}
          account={scopedAccount}
          onError={() => setShowPermissions(false)}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    awsAccounts: state.aws?.all,
    awsConnected: state.aws?.all.length > 0 || false,
    userPermissions: state.user?.permissions ?? [],
  };
};

export default connect(mapStateToProps, null)(AwsAccountsSettings);
