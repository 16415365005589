import React from 'react';
import { Box, CircularProgress, styled, Typography } from '@material-ui/core';
import { BaseCard, IconContainer } from './Cards';
import { connect } from 'react-redux';
import { Badge, hasPermission } from 'components/Base';
import { useHistory } from 'react-router-dom';
import {
  companySettings,
  emailManagement,
  companyMembers,
  templates,
} from 'consts/routes';
import { Aws, EmailOutlined, Plus, RightArrowAlt } from 'assets/icons';
import { useSubscription } from '../../Contexts';

const Root = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  paddingRight: theme.spacing(1),
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gridTemplateRows: '1.2fr 0.5fr 1.2fr',
  gridTemplateAreas: `
  "templates addTemplate awsAccounts"
  "templates identities awsAccounts"
  "templates identities teamMembers"`,
  columnGap: theme.spacing(4.5),
  rowGap: theme.spacing(4.5),
}));

const LargeBase = styled(BaseCard)(({ theme, gridarea }) => ({
  boxShadow: 'none',
  position: 'relative',
  transition: 'all 0.15s ease',
  '&:hover': {
    boxShadow: theme.custom.standardShadow,
  },
  gridArea: gridarea,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

const SmallBase = styled(LargeBase)(({ theme, gridarea }) => ({
  boxShadow: '0px 4px 10px 0px #0000000D',
  '&:hover': {
    cursor: 'pointer',
    boxShadow: theme.custom.standardShadow,
  },
  gridArea: gridarea,
  justifyContent: 'space-between',
}));

const CardTitle = styled(Typography)(({ theme, iscolored }) => ({
  margin: theme.spacing(0, 1),
  fontFamily: theme.typography.fontFamilyTitle,
  color: iscolored ? theme.palette.text.default : theme.palette.text.main,
  fontWeight: 700,
  fontSize: '19px',
}));

const SubTitle = styled(CardTitle)(({ theme, iscolored }) => ({
  fontSize: '17px',
  fontWeight: 600,
  marginRight: 0,
}));

const LargeCount = styled(CardTitle)({
  margin: '0',
  fontSize: '32px',
  fontWeight: 700,
});

const StyledRightArrow = styled(RightArrowAlt)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: '3vw',
}));

const StyledSmallArrow = styled(StyledRightArrow)(({ theme }) => ({
  fontSize: '2vw',
}));

const StyledAddTemplate = styled('div')(({ theme }) => ({
  width: '3.3vw',
  height: '3.3vw',
  borderRadius: '3.3vw',
  backgroundColor: theme.palette.secondary.main,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& .MuiSvgIcon-root': {
    fontSize: '2.2vw',
    color: theme.palette.text.reverted,
  },
}));

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  height: '8px',
  width: '75%',
  borderRadius: '8px',
  color: theme.palette.secondary.main,
}));

const StyledBgCircle = styled(CircularProgress)(({ theme }) => ({
  position: 'absolute',
  margin: '4px 0px 0px 4px',
  color: theme.palette.background.main,
}));

const CenteredColumnBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const CenteredRowBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const OverviewRow = ({
  awsAccountsCount,
  emailIdentitiesCount,
  userCount,
  templatesCount,
  templateLimit,
  draftsCount,
  publishedCount,
  userPermissions,
}) => {
  const history = useHistory();
  const { openUserDialog, openTemplateDialog } = useSubscription();

  const handleViewTemplates = () => {
    history.push(templates);
  };

  const handleViewEmails = () => {
    history.push(emailManagement);
  };

  const handleViewAws = () => {
    history.push(companySettings);
  };

  const TemplateCard = () => {
    const progressValue =
      templatesCount > templateLimit
        ? 100
        : (templatesCount / templateLimit) * 100;

    const countDisclaimer = 'Total templates';
    const includedDisclaimer = '/ included in plan.';

    return (
      <LargeBase
        iscolored={1}
        onClick={handleViewTemplates}
        gridarea="templates"
      >
        <CenteredColumnBox pt={2} justifyContent="space-between" height="100%">
          <Box position="relative" display="inline-flex">
            <StyledBgCircle
              color="inherit"
              variant="determinate"
              value={100}
              size={190}
              thickness={1.4}
            />
            <StyledCircularProgress
              color="inherit"
              variant="determinate"
              value={progressValue}
              size={200}
              thickness={3}
            />
            <CenteredColumnBox
              top={0}
              left={0}
              bottom={0}
              right={0}
              position="absolute"
              justifyContent="center"
            >
              <LargeCount iscolored={1}>
                {templatesCount}{' '}
                <Box component="span" fontSize="17px">
                  / {templateLimit}
                </Box>
              </LargeCount>
              <Box
                fontSize="17px"
                fontWeight="600"
                fontFamily="Exo"
                color="rgb(3, 39, 51)"
                lineHeight="1.5"
                marginLeft={1}
              >
                <Box fontSize="14px" width="140px" textAlign="center">
                  {countDisclaimer}
                </Box>
                <Box fontSize="11px" width="140px" textAlign="center">
                  {includedDisclaimer}
                </Box>
              </Box>
            </CenteredColumnBox>
          </Box>

          <CenteredRowBox
            height="100%"
            justifyContent="space-around"
            alignItems="flex-end !important"
          >
            <CenteredColumnBox pr="10%" justifyContent="flex-end">
              <Box
                display="flex"
                style={{ flexDirection: 'row' }}
                alignItems="center"
              >
                <SubTitle iscolored={1}>Published</SubTitle>
                <StyledSmallArrow />
              </Box>
              <LargeCount iscolored={1}>{publishedCount}</LargeCount>
            </CenteredColumnBox>
            <CenteredColumnBox pl="10%" pr={1}>
              <Box
                display="flex"
                style={{ flexDirection: 'row' }}
                alignItems="center"
              >
                <SubTitle iscolored={1}>Draft</SubTitle>
                <StyledSmallArrow />
              </Box>
              <LargeCount iscolored={1}>{draftsCount}</LargeCount>
            </CenteredColumnBox>
          </CenteredRowBox>
        </CenteredColumnBox>
      </LargeBase>
    );
  };

  const AddTemplateCard = () => {
    const title = 'Create new template';
    return (
      <SmallBase
        iscolored={0}
        onClick={() => openTemplateDialog({ subject: '', name: '', tags: [] })}
        gridarea="addTemplate"
      >
        <CenteredRowBox pt={1} height="100%" justifyContent="space-between">
          <CardTitle>{title}</CardTitle>
          <StyledAddTemplate>
            <Plus />
          </StyledAddTemplate>
        </CenteredRowBox>
      </SmallBase>
    );
  };

  const TeamMembersCard = () => {
    const title = 'Team Members';
    const badgeText = '+Invite more';
    return (
      <SmallBase
        iscolored={0}
        onClick={() => history.push(companyMembers)}
        gridarea="teamMembers"
      >
        <CardTitle>{title}</CardTitle>
        <CenteredRowBox justifyContent="space-between">
          <Box ml={2}>
            <LargeCount>{userCount}</LargeCount>
          </Box>
          <Box
            mt={-0.25}
            onClick={(event) => {
              event.stopPropagation();
              openUserDialog(null);
            }}
          >
            {hasPermission(userPermissions, 'iam') && (
              <Badge text={badgeText} />
            )}
          </Box>
        </CenteredRowBox>
      </SmallBase>
    );
  };

  const AwsAccountCard = () => {
    const title = 'Connected AWS accounts';
    return (
      <LargeBase iscolored={1} onClick={handleViewAws} gridarea="awsAccounts">
        <IconContainer iscolored={1}>
          <Aws fontSize="inherit" />
        </IconContainer>
        <Box
          display="flex"
          style={{ flexDirection: 'column' }}
          justifyContent="flex-end"
        >
          <CardTitle iscolored={1}>{title}</CardTitle>
          <CenteredRowBox justifyContent="space-between">
            <Box ml={2} mt={0.5}>
              <LargeCount iscolored={1}>{awsAccountsCount}</LargeCount>
            </Box>
            <StyledRightArrow />
          </CenteredRowBox>
        </Box>
      </LargeBase>
    );
  };

  const EmailIdentitiesCard = () => {
    const title = 'Connected email identities';
    return (
      <LargeBase iscolored={1} onClick={handleViewEmails} gridarea="identities">
        <IconContainer iscolored={1}>
          <EmailOutlined fontSize="inherit" />
        </IconContainer>
        <Box
          display="flex"
          style={{ flexDirection: 'column' }}
          justifyContent="flex-end"
        >
          <CardTitle iscolored={1}>{title}</CardTitle>
          <CenteredRowBox justifyContent="space-between">
            <Box ml={2} mt={0.5}>
              <LargeCount iscolored={1}>{emailIdentitiesCount}</LargeCount>
            </Box>
            <StyledRightArrow />
          </CenteredRowBox>
        </Box>
      </LargeBase>
    );
  };

  return (
    <Root>
      <TemplateCard />
      <AddTemplateCard />
      <AwsAccountCard />
      <EmailIdentitiesCard />
      <TeamMembersCard />
    </Root>
  );
};

const mapStateToProps = (state) => {
  return {
    awsAccountsCount: state.company.subscription?.awsAccountsCount || 1,
    userCount: state.company.subscription?.userCount || 0,
    emailIdentitiesCount: state.company.subscription?.emailIdentitiesCount || 0,
    templateLimit: state.company.subscription?.templateLimit || 5,
    templatesCount: state.company.subscription?.templates?.count || 0,
    draftsCount: state.company.subscription?.templates?.drafts || 0,
    publishedCount: state.company.subscription?.templates?.published || 0,
    userPermissions: state.user?.permissions || [],
  };
};

export default connect(mapStateToProps, null)(OverviewRow);
