import { createTheme } from '@material-ui/core/styles';

export const updateTheme = (defaultValues) => {
  const theme = createTheme(defaultValues);
  const shadows = [
    // button
    '0 2px 10px 1px rgba(0, 0, 0, 0.1)',
    // drawer
    '2px 0 13px 3px rgba(0, 0, 0, 0.1)',
  ];
  theme.shadows.push(...shadows);
  theme.palette.grey[350] = '#eaf0f6';

  theme.overrides = {
    MuiButton: {
      root: {
        textTransform: 'uppercase',
        fontWeight: 700,
        padding: theme.spacing(1, 2.5, 1, 2.5),
      },
      outlined: {
        padding: theme.spacing(0.75, 2.5, 0.75, 2.5),
        borderColor: theme.palette.primary.main,
      },
      outlinedPrimary: {
        borderWidth: '2px',
        '&:hover': {
          borderWidth: '2px',
        },
      },
      outlinedSecondary: {
        borderWidth: '2px',
        '&:hover': {
          borderWidth: '2px',
        },
      },
      containedPrimary: {
        boxShadow: theme.shadows[25],

        '&.Mui-disabled': {
          backgroundColor: theme.palette.secondary.light,
        },
      },
      contained: {
        '&.Mui-disabled': {
          backgroundColor: theme.palette.secondary.light,
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: theme.palette.primary.main,
        '&.Mui-focused': {
          color: theme.palette.primary.main,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        background: theme.palette.background.main,
        overflow: 'hidden',

        $notchedOutline: {
          borderColor: theme.palette.grey.main,
        },

        '&$focused $notchedOutline': {
          color: theme.palette.primary.main,
          borderColor: theme.palette.grey.main,
          borderWidth: 1,
        },

        '&:hover $notchedOutline': {
          borderColor: theme.palette.grey.main,
        },
      },
      input: {
        fontSize: 14,
        fontWeight: 500,
        padding: '12px 14px',
        background: theme.palette.background.main,
      },
    },
    MuiFilledInput: {
      root: {
        marginBottom: '4px',
        borderRadius: '5px',
      },
      input: {
        background: theme.palette.background.main,
        padding: '12px 8px',
        borderRadius: '5px',
        borderBottom: 'none',
        fontFamily: theme.typography.fontFamilyTitle,
        fontSize: 14,
      },
    },
    MuiMenuItem: {
      root: {
        color: theme.palette.text.main,
      },
    },
    MuiTypography: {
      h1: {
        fontFamily: theme.typography.fontFamilyTitle,
        color: theme.palette.text.main,
        fontSize: '25px',
        fontWeight: 500,
      },
      h2: {
        fontFamily: theme.typography.fontFamilyTitle,
        fontSize: '3rem',
        color: theme.palette.text.main,
      },
      h3: {
        fontFamily: theme.typography.fontFamilyTitle,
        color: theme.palette.text.main,
        fontSize: '25px',
        fontWeight: 800,
      },
      h5: {
        fontFamily: theme.typography.fontFamilyTitle,
        color: theme.palette.text.main,
        fontSize: '1.1rem',
        fontWeight: 600,
      },
      h6: {
        fontFamily: theme.typography.fontFamilyTitle,
        color: theme.palette.text.main,
      },
      subtitle1: {
        fontFamily: theme.typography.fontFamilyTitle,
        color: theme.palette.text.main,
      },
      subtitle2: {
        fontFamily: theme.typography.fontFamilyTitle,
        color: theme.palette.text.main,
      },
      body1: {
        fontFamily: theme.typography.fontFamilyTitle,
      },
      body2: {
        fontWeight: 500,
        fontFamily: theme.typography.fontFamilyTitle,
      },
    },
    MuiDialogTitle: {
      root: {
        fontSize: '1.4rem',
        fontWeight: 'normal',
        paddingTop: theme.spacing(4),
        color: theme.palette.text.main,
        fontFamily: theme.typography.fontFamilyTitle,
      },
    },
    MuiDialogActions: {
      root: {
        padding: theme.spacing(3),
      },
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      label: {
        color: theme.palette.text.reverted,
        '& .MuiSvgIcon-root': {
          color: theme.palette.text.main,
        },
      },
    },
    MuiTableRow: {
      root: {
        color: theme.palette.text.main,
        '&:not(:first-of-type)': {
          borderTop: `0.75px solid ${theme.palette.text.main}`,
          borderBottom: `none`,
        },
        '&:last-of-type': {
          borderBottom: `0.75px solid ${theme.palette.text.main}`,
        },
      },
    },
    MuiTableHead: {
      root: {
        color: theme.palette.text.main,
      },
    },
    MuiTableSortLabel: {
      root: {
        color: theme.palette.text.main,
        '&:hover': {
          color: theme.palette.primary.light,
        },
        '&$active': {
          color: theme.palette.primary.light,
        },
      },
      icon: {
        color: 'inherit !important',
        opacity: 0,
        '&$active': {
          opacity: 1,
        },
      },
    },
    MuiTableCell: {
      head: {
        color: theme.palette.text.main,
        borderBottom: `0.75px solid ${theme.palette.text.main} !important`,
        fontWeight: 600,
      },
      body: {
        color: theme.palette.text.main,
        fontWeight: 500,
      },
      root: {
        borderBottom: '0px',
        padding: '12px 12px',
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: theme.palette.bg.main,
        color: theme.palette.text.primary,
      },
    },
    MuiTablePagination: {
      root: {
        color: theme.palette.text.main,
      },
    },
    MuiDialogContent: {
      root: {
        color: theme.palette.text.main,
      },
    },
    MuiTableContainer: {
      root: {
        overflow: 'none !important',
        overflowX: 'none',
      },
    },
  };
  return theme;
};
