import React, { useState, useRef } from 'react';
import {
  Box,
  ClickAwayListener,
  Grid,
  Grow,
  ListItemText,
  ListItemIcon,
  MenuList,
  MenuItem,
  Typography,
  styled,
  Popper,
  Paper,
  useTheme,
} from '@material-ui/core';
import {
  Badge,
  InfoBox,
  StyledButton,
  PermissionsWrapper,
} from 'components/Base';
import { StyledIconButton } from 'components/Base/TableUtils';
import { MoreVert } from 'assets/icons';
import { Searchbar } from 'components/Menu/Templates';

export const Header = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const TitleContainer = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  position: 'relative',
}));

const ActionItemsContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: 0,
}));

const PaddedFlexBox = styled(Box)(({ theme }) => ({
  '& > * + *': {
    marginLeft: theme.spacing(1),
  },
}));

const AdditionActionsButton = styled(StyledIconButton)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.text.primary}`,

  '&:hover': {
    boxShadow: theme.custom.standardShadow,
    border: `1px solid ${theme.palette.text.primary}`,
  },

  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(0.25),
}));

const SettingsHeader = ({
  additionalActionItems,
  buttonText,
  title,
  onClick,
  itemsCount,
  infoText = undefined,
  searchProps = undefined,
  primaryActionPermission = undefined,
}) => {
  const [actionsOpen, setActionsOpen] = useState(false);
  const actionAnchorRef = useRef(null);
  const theme = useTheme();
  const handleToggle = () => {
    setActionsOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (
      actionAnchorRef.current &&
      actionAnchorRef.current.contains(event.target)
    ) {
      return;
    }
    setActionsOpen(false);
  };

  const customSearchBarStyles = {
    height: theme.custom.buttonHeight,
    border: `1px solid ${theme.palette.text.primary}`,
    width: theme.spacing(25),
  };

  return (
    <Header>
      <TitleContainer item container direction={'row'} alignItems={'flex-end'}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Typography variant="h5" style={{ whiteSpace: 'nowrap' }}>
            {title}
          </Typography>
          {itemsCount > 0 && (
            <Box pl={2}>
              <Badge color="primary" text={itemsCount.toString()} />
            </Box>
          )}
          {infoText && (
            <Box pl={1}>
              <InfoBox infoText={infoText} />
            </Box>
          )}
        </Box>
        <ActionItemsContainer>
          <PaddedFlexBox
            display="flex"
            alignItems="center"
            style={{ flexDirection: 'row' }}
          >
            {searchProps && (
              <Searchbar
                {...searchProps}
                customStyles={customSearchBarStyles}
              />
            )}

            {additionalActionItems && (
              <>
                <AdditionActionsButton
                  ref={actionAnchorRef}
                  aria-label="open additional header actions"
                  onClick={handleToggle}
                  edge="end"
                >
                  <MoreVert size={26} />
                </AdditionActionsButton>
                <Popper
                  open={actionsOpen}
                  anchorEl={actionAnchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                  placement={'bottom-end'}
                  style={{ zIndex: 1300 }}
                >
                  {({ TransitionProps }) => (
                    <Grow
                      {...TransitionProps}
                      style={{ transformOrigin: 'center top' }}
                    >
                      <Paper>
                        <ClickAwayListener
                          onClickAway={(event) => handleClose(event)}
                        >
                          <MenuList disablePadding>
                            {additionalActionItems.map((item, index) => (
                              <MenuItem
                                key={index}
                                onClick={(event) => {
                                  item.onClick();
                                  handleClose(event);
                                }}
                              >
                                {item.icon && (
                                  <ListItemIcon>{item.icon}</ListItemIcon>
                                )}
                                <ListItemText>{item.label}</ListItemText>
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </>
            )}
            {buttonText &&
              onClick &&
              (primaryActionPermission ? (
                <PermissionsWrapper permission={primaryActionPermission}>
                  <StyledButton
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={onClick}
                  >
                    {buttonText}
                  </StyledButton>
                </PermissionsWrapper>
              ) : (
                <StyledButton
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={onClick}
                >
                  {buttonText}
                </StyledButton>
              ))}
          </PaddedFlexBox>
        </ActionItemsContainer>
      </TitleContainer>
    </Header>
  );
};

export default SettingsHeader;
