import { showErrorSnackbar, showSuccessSnackbar } from './layout';

import {
  UPGRADE_PLAN_ERROR,
  UPGRADE_PLAN_SUCCESS,
  UPDATE_COMPANY_DETAILS_ERROR,
  UPDATE_COMPANY_DETAILS_SUCCESS,
} from '../consts/snackbarMessages';
import { setDefaultAwsAccount } from './awsAccounts';

export const setCompanyDetails = (data) => ({
  type: 'COMPANY_DETAILS_SET',
  payload: data,
});

export const setCompanySummary = (data) => ({
  type: 'COMPANY_SUMMARY_SET',
  payload: data,
});

export const updateSubscriptionDetails = (data) => ({
  type: 'SUBSCRIPTION_UPDATE',
  payload: data,
});

export const setSubscriptionDetails = (
  subscription,
  companyCreatedTimestamp
) => ({
  type: 'COMPANY_SUBSCRIPTION_SET',
  payload: { ...subscription, companyCreatedTimestamp },
});

export const loadCompanyInfo = () => async (dispatch, getState, { axios }) => {
  try {
    const {
      data: { details, defaultAccount, subscription, summary },
    } = await axios.get(`/company`);
    dispatch(setCompanyDetails(details));
    defaultAccount && dispatch(setDefaultAwsAccount(defaultAccount));
    dispatch(setSubscriptionDetails(subscription));
    dispatch(setCompanySummary(summary));
    return { subscription, summary };
  } catch (error) {
    throw error;
  }
};

export const loadCompanyName = (uid) => async (_, __, { axios }) => {
  try {
    const data = await axios.get(`/invited-member?userId=${uid}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const stripeCheckoutSuccessful = (
  checkoutSessionId,
  subscribedPlan
) => async (dispatch, getState, { axios }) => {
  try {
    const { data } = await axios.put(`/company/upgrade-success`, {
      checkoutSessionId,
      subscribedPlan,
    });
    dispatch(updateSubscriptionDetails(data));
    dispatch(showSuccessSnackbar(UPGRADE_PLAN_SUCCESS));
    return true;
  } catch (error) {
    dispatch(showErrorSnackbar(UPGRADE_PLAN_ERROR));
    throw error;
  }
};

export const updateCompanyDetails = (values) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const { data } = await axios.put(`/company/details`, values);
    dispatch(setCompanyDetails(data));
    dispatch(showSuccessSnackbar(UPDATE_COMPANY_DETAILS_SUCCESS));

    return true;
  } catch (error) {
    dispatch(showErrorSnackbar(UPDATE_COMPANY_DETAILS_ERROR));
    throw error;
  }
};

export const upgradePlan = (values) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const { data } = await axios.put(`/company/upgrade`, values);
    dispatch(updateSubscriptionDetails(data));
    return true;
  } catch (error) {
    throw error;
  }
};
