import React, { Fragment, useState, useEffect } from 'react';
import { DeleteOutline as DeleteIcon } from 'assets/icons';
import {
  AlertDialog,
  LogoLoader,
  OverviewTable,
  SettingsHeader,
  hasPermission,
} from 'components/Base';
import { VerifyDomainDialog } from './index';
import { useLazyLoading } from 'state/hooks';
import {
  getDomainIdentities,
  syncDomainIdentities,
  removeIdentity,
  getDomainIdentityDetails,
} from 'state/actions';
import { connect } from 'react-redux';
import { actionTooltips, verifiedDomainColumns } from 'consts';
import { AwsNotConnected } from '../CompanySettings/Aws';
import { InfoOutlined } from '@material-ui/icons';
import DomainDetailsTableContent from './DomainDetailsTableContent';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Typography } from '@material-ui/core';
import { Refresh } from 'assets/icons';

const DomainManager = ({
  awsConnected,
  domainIdentities,
  domainCount,
  userPermissions,
}) => {
  const [domainDialogOpen, setDomainDialogOpen] = useState(false);
  const [scopedDomain, setScopedDomain] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [reloadDomainIdentities, { loading, error }] = useLazyLoading({
    action: getDomainIdentities,
  });

  const onChangeHandleFetchDomain = () => {
    reloadDomainIdentities({
      skip: page * rowsPerPage,
      limit: rowsPerPage,
      order: 'asc',
      orderBy: 'domainName',
    });
  };

  useEffect(() => {
    onChangeHandleFetchDomain();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const [handleRefresh] = useLazyLoading({
    action: syncDomainIdentities,
    onSuccess: () => onChangeHandleFetchDomain(),
  });

  const handleCloseDialog = async () => {
    setDomainDialogOpen(false);
    setDeleteDialogOpen(false);
    setScopedDomain(null);
    onChangeHandleFetchDomain();
  };

  const [handleRemoveIdentity, { loading: deleting }] = useLazyLoading({
    action: removeIdentity,
    onSuccess: () => {
      setDeleteDialogOpen(false);
      setScopedDomain(null);
      onChangeHandleFetchDomain();
    },
    onError: () => {
      setDeleteDialogOpen(false);
      setScopedDomain(null);
    },
  });

  const [getDomainDetailsAction, { loading: detailsLoading }] = useLazyLoading({
    action: getDomainIdentityDetails,
    onSuccess: (result) => {
      setScopedDomain(result);
    },
  });

  const confirmRemoveIdentity = (row) => {
    setScopedDomain(row);
    setDeleteDialogOpen(true);
  };

  const handleShowDomainDetail = (row) => {
    setScopedDomain(row);
    if (!scopedDomain || row.uid !== scopedDomain.uid) {
      getDomainDetailsAction(row.identity);
    }
  };

  if (loading) return <LogoLoader />;

  if (error && error.includes('403')) {
    return (
      <Alert severity="error">
        <AlertTitle>Access Denied</AlertTitle>
        <Typography variant="body2">
          You don't have the necessary permissions to view this information.
          Please contact your administrator to request access.
        </Typography>
      </Alert>
    );
  }

  if (!awsConnected) return <AwsNotConnected />;

  return (
    <Fragment>
      <SettingsHeader
        title="Verified Domains"
        buttonText={'Add Domain'}
        onClick={() => setDomainDialogOpen(true)}
        itemsCount={domainCount}
        refreshing={loading}
        onRefresh={handleRefresh}
        primaryActionPermission={'write_domain_identities'}
        additionalActionItems={[
          {
            label: 'Refresh',
            onClick: handleRefresh,
            icon: <Refresh size={26} />,
          },
        ]}
      />
      <OverviewTable
        data={domainIdentities}
        columns={verifiedDomainColumns}
        anyDialogOpen={deleteDialogOpen || domainDialogOpen}
        total={domainCount}
        actions={[
          {
            icon: <InfoOutlined size={22} />,
            label: 'Show Account Status Info',
            onClick: handleShowDomainDetail,
          },
          {
            icon: <DeleteIcon size={22} />,
            label: 'Remove',
            onClick: confirmRemoveIdentity,
            enabledCondition: () =>
              hasPermission(userPermissions, 'write_domain_identities'),
            disabledTooltipText: actionTooltips.write_domain_identities,
          },
        ]}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
      <AlertDialog
        onClose={handleCloseDialog}
        open={deleteDialogOpen}
        title={'Remove Domain'}
        onSubmit={async () => {
          await handleRemoveIdentity(scopedDomain);
        }}
        text={
          'Are you sure you want to delete the domain ' +
          scopedDomain?.domainName +
          '?'
        }
        submitText={'Remove'}
        loading={deleting}
      />
      {scopedDomain && (
        <DomainDetailsTableContent
          domainDetails={scopedDomain}
          loading={detailsLoading}
        />
      )}
      <VerifyDomainDialog open={domainDialogOpen} onClose={handleCloseDialog} />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    awsConnected: state.aws.all?.length > 0,
    domainIdentities: state.identities.domains.entities,
    domainCount: state.identities.domains?.count ?? 0,
    userPermissions: state.user?.permissions ?? [],
  };
};

export default connect(mapStateToProps)(DomainManager);
