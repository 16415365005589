import PropTypes from 'prop-types';
import { DialogFormFrame } from 'components/Base';
import CreateReusableBlockForm from './CreateReusableBlockForm';

const CreateReusableBlockDialog = ({ loading, open, onClose, onSubmit }) => {
  return (
    <DialogFormFrame
      onClose={onClose}
      open={open}
      title={'Add new Reusable Block'}
      formComponent={
        <CreateReusableBlockForm
          loading={loading}
          onClose={onClose}
          onSubmit={onSubmit}
        />
      }
    />
  );
};

CreateReusableBlockDialog.propTypes = {
  loading: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CreateReusableBlockDialog;
