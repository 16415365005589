import React, { Fragment, useEffect, useRef, useState } from 'react';
import { styled, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useLazyLoading } from 'state/hooks';
import { fetchBlocks, publishTemplate, updateTemplate } from 'state/actions';
import {
  TemplatesAppBar,
  MoreActionsDrawer,
  PublishButton,
  RouteGuard,
} from '../Shared';
import EmailEditor from 'react-email-editor';
import { connect } from 'react-redux';
import BlockUpdateDialog from './BlockUpdateDialog';
import { LogoLoader, hasPermission } from 'components/Base';
import { useSubscription, useTracking } from 'components/Contexts';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { useAsyncAction } from 'state/hooks';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  height: '100%',
});

const UnlayerEditor = ({ blocks, template, themeMode, userPermissions }) => {
  const theme = useTheme();

  const customStyle = `
    .save-block-btn {
      display: none !important;
    }
    
    div[data-active-item-id="blocks"] button .fa-ellipsis {
      display: none !important;
    }

    div[data-active-item-id="blocks"] button:has(.fa-ellipsis) {
      display: none !important;
    }


    input[placeholder="Search"] {
      color: ${theme.palette.text.main} !important;
    }

    svg[data-icon="magnifying-glass"] * {
      color: ${theme.palette.text.main} !important;
    }

    svg[data-icon="xmark"] * {
      color: ${theme.palette.text.main} !important;
    }
  `;

  const defaultEditorOptions = {
    version: 'stable',
    appearance: {
      theme: 'modern_' + themeMode, // default: 'classic_light'
    },
    displayMode: 'email',
    features: {
      preheaderText: false,
      preview: false,
      svgImageUpload: true,
      undoRedo: false,
    },
    customCSS: [customStyle],
  };
  const { company, subscription } = useSubscription();
  const history = useHistory();
  const { logEvent } = useTracking();

  const designLoaded = useRef(false);
  const emailEditorRef = useRef(null);
  const pathToNavigate = useRef();

  const [designHasLoaded, setDesignHasLoaded] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [templateData, setTemplateData] = useState(template);
  const [editorOptions, setEditorOptions] = useState(null);
  const [blockDialogOpen, setBlockDialogOpen] = useState(false);
  const [isBulk, setIsBulk] = useState(undefined);

  const [drawerOpen, setDrawerOpen] = useQueryParam(
    'moreActions',
    BooleanParam
  );

  const { loading: loadingBlocks } = useAsyncAction({ action: fetchBlocks });

  // initial loading of the design
  useEffect(() => {
    if (!designLoaded.current) handleLoadTemplateDesign();
  });

  const getReusableBlocks = (params) => {
    return (
      blocks
        // if block is in state 'CREATED' or 'DELETED' the field design might be empty.
        .filter(
          (block) => block?.state !== 'DELETED' && block?.state !== 'CREATED'
        )
        .map((block) => {
          let design = JSON.parse(block.design);

          design = {
            schemaVersion: design.schemaVersion,
            ...design.body.rows[0],
          };

          return {
            displayMode: 'email',
            category: 'Reusable Block - ' + block.label,
            search: '',
            data: design,
            tags: [block.id],
            id: block.id,
            custom: true,
          };
        })
    );
  };

  // loading & state setting
  const handleLoadTemplateDesign = () => {
    if (!emailEditorRef.current || !emailEditorRef.current.editor) {
      return;
    }
    designLoaded.current = true;
    emailEditorRef.current.editor.loadDesign(
      template.design ? JSON.parse(template.design) : {}
    );
    emailEditorRef.current.editor.addEventListener('design:loaded', () => {
      setDesignHasLoaded(true);
    });

    emailEditorRef.current.editor.registerProvider('blocks', function(
      params,
      done
    ) {
      done(getReusableBlocks(params));
    });
    emailEditorRef.current.editor.reloadProvider('blocks');
  };

  // RELOAD to handle unlinking case when block is unlinked in the backend
  useEffect(() => {
    if (!designHasLoaded) {
      return;
    }
    emailEditorRef.current.editor.loadDesign(
      template.design ? JSON.parse(template.design) : {}
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template]);

  // AFTER the design has successfully loaded, we add a listener to the editor.
  useEffect(() => {
    if (designHasLoaded && blocks) {
      emailEditorRef.current.editor.addEventListener('design:updated', () => {
        setUnsavedChanges(true);
      });

      emailEditorRef.current.editor.registerProvider('blocks', function(
        params,
        done
      ) {
        done(getReusableBlocks(params));
      });
      emailEditorRef.current.editor.reloadProvider('blocks');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [designHasLoaded, blocks]);

  // load user specific editor options
  useEffect(() => {
    if (
      subscription.name === 'Business' ||
      subscription.name === 'Enterprise'
    ) {
      setEditorOptions({
        ...defaultEditorOptions,
        user: {
          id: company?.details?.uid,
        },
      });
    } else {
      setEditorOptions(defaultEditorOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, subscription]);

  const [handleTemplateSave, { loading: savingUpdate }] = useLazyLoading({
    action: updateTemplate,
    onSuccess: (template) => {
      logEvent({ category: 'template', action: 'updated' });
      // setUnsavedChanges = false, then setTemplateData
      // Thus previous useEffect is redundant -> removed
      setUnsavedChanges(false);
      setTemplateData(template);
      if (pathToNavigate.current) {
        history.push(pathToNavigate.current);
        pathToNavigate.current = undefined;
      }
      setIsBulk(undefined);
      setUnlinking(false);
      setUpdatingBlock(false);
      setBlockDialogOpen(false);
    },
    onError: (error) => {
      if (
        error.response?.data?.detail.includes(
          'TEMPLATE_UPDATE_FAILED_BLOCK_MODIFIED'
        )
      ) {
        setBlockDialogOpen(true);
      }
    },
  });

  const handleSave = async (update, bulk, blockUpdateType) => {
    await emailEditorRef.current.editor.exportHtml(async ({ html, design }) => {
      update = {
        ...update,
        html,
        design,
      };
      // also calls success handler
      await handleTemplateSave(update, bulk, blockUpdateType);
    });
  };

  const handleSaveMetadata = async (update) => {
    if (update?.design) {
      // Need to load & export once, to update HTML
      emailEditorRef.current.editor.loadDesign(update.design);
      await emailEditorRef.current.editor.exportHtml(
        async ({ html, design }) => {
          update = {
            ...update,
            html,
            design,
          };
          // also calls success handler
          await handleTemplateSave(update);
        }
      );
    } else {
      // also calls success handler
      await handleTemplateSave(update);
    }
  };

  const handleSaveWithblockUpdate = async ({ bulk, blockUpdateType }) => {
    await handleSave(templateData, bulk, blockUpdateType);
  };

  const handleSaveWithoutInput = async ({ bulk }) => {
    setIsBulk(bulk);
    await handleSave(templateData, bulk);
  };

  const handleNavigation = async (save = true, path = null) => {
    pathToNavigate.current = path;
    if (save) {
      await handleSaveWithoutInput({ bulk: false });
    }
    history.push(path);
  };

  const [handlePublish, { loading: publishing }] = useLazyLoading({
    action: publishTemplate,
    onSuccess: () => {
      logEvent({ category: 'template', action: 'published' });
    },
  });

  const isLoaded = !loadingBlocks && blocks && editorOptions;

  const [unlinking, setUnlinking] = useState(false);
  const [updatingBlock, setUpdatingBlock] = useState(false);

  const handleUnlink = async ({ bulk }) => {
    setUnlinking(true);
    try {
      await handleSaveWithblockUpdate({ bulk, blockUpdateType: 'unlink' });
    } catch (error) {
      console.error('Unlink failed', error);
    }
  };

  const handleUpdateBlock = async ({ bulk }) => {
    setUpdatingBlock(true);
    try {
      await handleSaveWithblockUpdate({ bulk, blockUpdateType: 'updateBlock' });
    } catch (error) {
      console.error('Update block failed', error);
    }
  };

  return (
    <Fragment>
      {hasPermission(userPermissions, 'edit_templates') && (
        <RouteGuard
          when={unsavedChanges}
          onNavigate={handleNavigation}
          loading={savingUpdate}
        />
      )}
      <TemplatesAppBar>
        <PublishButton
          loading={savingUpdate || publishing}
          onSave={handleSaveWithoutInput}
          onPublish={({ bulk, awsAccountId }) =>
            handlePublish({ uid: template.uid, bulk, awsAccountId })
          }
          unsavedChanges={unsavedChanges}
          template={template}
        />
      </TemplatesAppBar>
      <Container>
        {isLoaded ? (
          <Fragment>
            <EmailEditor
              ref={emailEditorRef}
              onLoad={handleLoadTemplateDesign}
              projectId={6799}
              options={editorOptions}
            />
            <MoreActionsDrawer
              open={drawerOpen}
              onClose={() => setDrawerOpen(false)}
              template={template}
              saving={savingUpdate}
              onSaveMetadata={handleSaveMetadata}
            />
          </Fragment>
        ) : (
          <LogoLoader />
        )}
      </Container>
      <BlockUpdateDialog
        onClose={() => setBlockDialogOpen(false)}
        onUnlink={() => handleUnlink({ bulk: isBulk })}
        onUpdate={() => handleUpdateBlock({ bulk: isBulk })}
        open={blockDialogOpen}
        loadingUnlink={unlinking}
        loadingUpdate={updatingBlock}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    blocks: state.blocks?.entities ?? [],
    themeMode: state.layout.themeMode,
    template: state.templates.selectedTemplate,
    userPermissions: state.user?.permissions || [],
  };
};

export default connect(mapStateToProps)(UnlayerEditor);
