import React, { useEffect, useState } from 'react';
import { Box, Grid, useTheme } from '@material-ui/core';
import { FastField as Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import {
  LogoLoader,
  LoadingButton,
  FieldWrapper,
  OverviewTable,
} from 'components/Base';
import { DrawerContent, DrawerFooter } from '../Shared';
import {
  fetchPolicyDetails,
  fetchPermissionSet,
  updatePolicyMetadata,
  deletePolicy,
} from 'state/actions';
import { useAsyncAction, useLazyLoading } from 'state/hooks';
import { StringParam, useQueryParam } from 'use-query-params';
import * as Yup from 'yup';

const UpdateSchema = Yup.object().shape({
  label: Yup.string().required('Please enter a label for this Policy.'),
  description: Yup.string().required('Please enter a short description'),
});

const PolicyDetails = ({ onUpdate }) => {
  const theme = useTheme();
  const [policyId, setPolicyId] = useQueryParam('policyId', StringParam);
  const [policy, setPolicy] = useState();
  const [selectedPermissionIds, setSelectedPermissionIds] = useState([]);

  const [
    updatePolicyMetadataAction,
    { loading: updatingName },
  ] = useLazyLoading({
    action: updatePolicyMetadata,
    onSuccess: () => {
      onUpdate();
      closeDrawer();
    },
  });

  const [deletePolicyAction, { loading: deleting }] = useLazyLoading({
    action: deletePolicy,
    onSuccess: () => {
      onUpdate();
      closeDrawer();
    },
  });

  const [fetchPolicyDetailsAction, { loading }] = useLazyLoading({
    action: fetchPolicyDetails,
  });

  const { data: permissions, loading: loadingPermissions } = useAsyncAction({
    action: fetchPermissionSet,
  });

  useEffect(() => {
    if (policyId && !policy && !loading) {
      fetchPolicyDetailsAction(policyId).then((data) => {
        setPolicy(data);
        if (data && data.permissions) {
          setSelectedPermissionIds(data.permissions.map((perm) => perm.id));
        }
      });
    }
  }, [policyId, policy, loading, fetchPolicyDetailsAction]);

  const handlePermissionChange = (permissionId) => {
    setSelectedPermissionIds((prev) => {
      let newSelectedIds = [...prev];

      if (newSelectedIds.includes(permissionId)) {
        newSelectedIds = newSelectedIds.filter((id) => id !== permissionId);

        if (permissionId.startsWith('read_')) {
          const writePermissionId = permissionId.replace('read_', 'write_');
          const deletePermissionId = permissionId.replace('read_', 'delete_');
          const publishPermissionId = permissionId.replace('read_', 'publish_');
          const editPermissionId = permissionId.replace('read_', 'edit_');
          const createPermissionId = permissionId.replace('read_', 'create_');

          newSelectedIds = newSelectedIds.filter(
            (id) =>
              id !== writePermissionId &&
              id !== deletePermissionId &&
              id !== editPermissionId &&
              id !== publishPermissionId &&
              id !== createPermissionId
          );
        }
      } else {
        newSelectedIds.push(permissionId);

        if (
          ['write_', 'delete_', 'publish_', 'create_', 'edit_'].some((prefix) =>
            permissionId.startsWith(prefix)
          )
        ) {
          const readPermissionId = permissionId.replace(
            /write_|delete_|publish_|edit_|create_/,
            'read_'
          );
          if (!newSelectedIds.includes(readPermissionId)) {
            newSelectedIds.push(readPermissionId);
          }
        }
      }

      return newSelectedIds;
    });
  };

  const handleUpdatePolicy = (values) => {
    updatePolicyMetadataAction(policyId, {
      label: values.label,
      description: values.description,
      permission_ids: selectedPermissionIds,
    });
  };

  const handleDeletePolicy = () => {
    deletePolicyAction(policyId);
  };

  const closeDrawer = () => {
    setPolicyId(null);
  };

  if (loading || loadingPermissions) return <LogoLoader />;

  return (
    <Formik
      initialValues={{
        label: policy?.label || '',
        description: policy?.description || '',
      }}
      enableReinitialize
      validationSchema={UpdateSchema}
      onSubmit={handleUpdatePolicy}
    >
      {({ values }) => (
        <Form style={{ height: '100%' }}>
          <DrawerContent>
            <Grid
              container
              direction="column"
              spacing={2}
              style={{ padding: '0px' }}
            >
              <Grid item>
                <h3>Policy Information</h3>
                <FieldWrapper
                  label="Policy Label"
                  subtitle="Label of the policy."
                >
                  <Field
                    component={TextField}
                    fullWidth
                    name="label"
                    variant="outlined"
                  />
                </FieldWrapper>
                <Box />
                <Box pt={3} pb={2}>
                  <FieldWrapper
                    label="Policy Description"
                    subtitle="A short description explaining the purpose of the policy."
                  >
                    <Field
                      component={TextField}
                      fullWidth
                      name="description"
                      variant="outlined"
                      multiline
                      rows={4}
                    />
                  </FieldWrapper>
                </Box>
              </Grid>
              <Grid item>
                <h3>Permissions</h3>
                <Box>
                  <OverviewTable
                    data={permissions}
                    columns={[
                      {
                        label: 'Assigned',
                        id: 'select',
                        type: 'select',
                        align: 'left',
                        isSelected: (row) => {
                          return selectedPermissionIds.includes(row.id);
                        },
                        onSelect: (row) => handlePermissionChange(row.id),
                      },
                      {
                        label: 'Permission',
                        id: 'label',
                        align: 'left',
                        type: 'text',
                      },
                    ]}
                    total={permissions.length}
                    rowsPerPage={50}
                    page={0}
                    entityName="selected_permissions"
                    styleOverwrites={{ minWidth: theme.spacing(10) }}
                    loading={loadingPermissions}
                  />
                </Box>
              </Grid>
            </Grid>
          </DrawerContent>
          <DrawerFooter>
            <LoadingButton
              variant="outlined"
              loading={deleting}
              color="error"
              onClick={handleDeletePolicy}
            >
              Remove Policy
            </LoadingButton>
            <LoadingButton
              variant="contained"
              loading={updatingName}
              color="primary"
              type="submit"
            >
              Update
            </LoadingButton>
          </DrawerFooter>
        </Form>
      )}
    </Formik>
  );
};

export default PolicyDetails;
