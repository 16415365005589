import React, { Fragment } from 'react';
import { Container } from 'components/Menu/CompanySettings/Shared/components/styles';
import { Typography } from '@material-ui/core';

const DataSecurity = () => {
  return (
    <Fragment>
      <Container>
        <Typography variant="h5" gutterBottom>
          Data Backup, Recovery, and Availability Responsibilities
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>Data Protection Measures</strong>
        </Typography>

        <Typography variant="body2" paragraph>
          Our platform implements several data durability features to safeguard
          critical customer data and content, such as automated versioning for
          assets stored in S3, backup snapshots for business logic entities, and
          highly available storage for email templates.
        </Typography>

        <Typography variant="body2" paragraph>
          <strong>1. Email Imagery Content:</strong> Email imagery content is
          stored in Amazon S3, with automatic versioning enabled to retain prior
          versions of files. This allows for easy recovery in case of accidental
          modification or deletion.
        </Typography>

        <Typography variant="body2" paragraph>
          <strong>2. Business Logic Data:</strong> All business data, including
          email templates, is securely stored in our Amazon RDS database. This
          data is backed up via nightly snapshots, offering a Recovery Point
          Objective (RPO) of up to 24 hours and a Recovery Time Objective (RTO)
          of approximately 1 hour, ensuring quick restoration in the unlikely
          event of an outage.
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>Customer Responsibilities</strong>
        </Typography>

        <Typography variant="body2" paragraph>
          While we have implemented robust data protection measures,{' '}
          <strong>
            customers remain responsible for creating additional backups or
            recovery processes
          </strong>{' '}
          if their internal data retention policies require stricter recovery
          times or higher backup frequency.
        </Typography>

        <Typography variant="body2" paragraph>
          We recommend customers:
        </Typography>

        <Typography variant="body2" paragraph>
          - <strong>Regularly backup email templates</strong> stored in their
          AWS SES account as needed for their internal requirements.
        </Typography>
        <Typography variant="body2" paragraph>
          -{' '}
          <strong>
            Maintain independent backups of critical business data
          </strong>{' '}
          if they need data recovery beyond the 24-hour snapshot recovery
          offered by our platform.
        </Typography>
        <Typography variant="body2" paragraph>
          - <strong>Regularly verify the stored content</strong> (such as email
          imagery) within S3 and back it up independently if desired.
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>Limitations of Liability</strong>
        </Typography>

        <Typography variant="body2" paragraph>
          Our platform takes steps to ensure high availability and backup for
          critical customer data, but we are not liable for any data loss or
          unavailability arising from customer actions, third-party
          integrations, or unforeseen events beyond our control. Customers are
          encouraged to maintain their own backups and follow our recommended
          practices for recovery and availability.
        </Typography>
      </Container>
    </Fragment>
  );
};

export default DataSecurity;
