import React, { Fragment, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { ColoredTag } from 'components/Base';
import { TagSelector } from 'components/Menu/Tags';
import { useLazyLoading } from 'state/hooks';
import { updateBlockTags } from 'state/actions';

const ChipContainer = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  width: '100%',
}));

const ReusableBlockTags = ({ block }) => {
  const [touched, setTouched] = useState(false);

  const [updateTags, { loading: savingTags }] = useLazyLoading({
    action: updateBlockTags,
    onSuccess: () => setTouched(false),
  });

  const [updatedTags, setUpdatedTags] = useState(block?.tags);

  useEffect(() => {
    setUpdatedTags(block?.tags);
  }, [block, setUpdatedTags]);

  const handleSave = async (value) => {
    const tagIds = value.map(({ id }) => id);
    await updateTags({
      id: block.id,
      tagIds,
    });
  };

  return (
    <Fragment>
      {touched ? (
        <Box display="flex" style={{ width: '100%', position: 'relative' }}>
          <TagSelector
            defaultValue={block?.tags ?? []}
            saving={savingTags}
            onSave={handleSave}
            onClose={() => setTouched(false)}
          />
        </Box>
      ) : (
        <ChipContainer onClick={() => setTouched(true)}>
          {updatedTags?.length > 0
            ? updatedTags.map(({ id, label, color }) => {
                return (
                  <ColoredTag key={id} background={color}>
                    {label}
                  </ColoredTag>
                );
              })
            : 'Click to add tags'}
        </ChipContainer>
      )}
    </Fragment>
  );
};

export default ReusableBlockTags;
