import React, { Fragment } from 'react';
import { Typography } from '@material-ui/core';
import { DialogFormFrame, LoadingButton } from 'components/Base';

const BlockUpdateDialog = ({
  onClose,
  onUpdate,
  onUnlink,
  open,
  loadingUnlink,
  loadingUpdate,
}) => {
  return (
    <DialogFormFrame
      onClose={onClose}
      title={'Choose block update type'}
      open={open}
      formComponent={
        <Typography gutterBottom>
          {
            'We detected that this template contains at least one reusable block, that got changed. Decide if you want to unlink used blocks and save this template, or update the used blocks as well. Note that other templates that use the block, will not be updated automatically. You can do so via the reusable block editor.'
          }
        </Typography>
      }
      actions={
        <Fragment>
          <LoadingButton
            onClick={onUpdate}
            loading={loadingUpdate}
            variant="outlined"
          >
            Update block(s) & save
          </LoadingButton>
          <LoadingButton
            onClick={onUnlink}
            color="primary"
            autoFocus
            variant="contained"
            loading={loadingUnlink}
          >
            Unlink block(s) & save
          </LoadingButton>
        </Fragment>
      }
    />
  );
};

export default BlockUpdateDialog;
