import React, { Fragment, useRef, useState } from 'react';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  styled,
} from '@material-ui/core';
import { PermissionsWrapper } from 'components/Base';
import { setImportTemplatesDialogOpen } from 'state/actions';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ImportDialog from '../Shared/ImportTemplatesDialog';
import { templateStarters } from 'consts/routes';
import { useSubscription } from 'components/Contexts';
import { ArrowDropDown, Copy, Import } from 'assets/icons';

const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  height: theme.custom.buttonHeight,
}));

const CreateTemplateDropdown = ({
  awsConnected,
  setImportTemplatesDialogOpen,
  importDialogOpen,
}) => {
  const history = useHistory();
  const { openTemplateDialog } = useSubscription();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const routeCreateTemplate = () => history.push(templateStarters);
  return (
    <Fragment>
      <PermissionsWrapper permission={'create_templates'}>
        <StyledButtonGroup variant="contained" color="primary" ref={anchorRef}>
          <Button onClick={openTemplateDialog}>Create new template</Button>
          <Button color="primary" size="small" onClick={handleToggle}>
            <ArrowDropDown />
          </Button>
        </StyledButtonGroup>
      </PermissionsWrapper>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        style={{
          marginTop: '8px',
          padding: 0,
          zIndex: 1,
          width: anchorRef.current?.offsetWidth,
        }}
      >
        <Paper>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList disablePadding>
              <div>
                <MenuItem key="template-starters" onClick={routeCreateTemplate}>
                  <ListItemText>Create from starters</ListItemText>
                  <ListItemIcon style={{ justifyContent: 'end' }}>
                    <Copy fontSize="small" />
                  </ListItemIcon>
                </MenuItem>
                {awsConnected && (
                  <Fragment>
                    <Divider />
                    <MenuItem
                      key="template-import"
                      onClick={() => setImportTemplatesDialogOpen(true)}
                    >
                      <ListItemText>Import from AWS SES</ListItemText>
                      <ListItemIcon style={{ justifyContent: 'end' }}>
                        <Import fontSize="small" />
                      </ListItemIcon>
                    </MenuItem>
                  </Fragment>
                )}
              </div>
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
      {importDialogOpen && <ImportDialog />}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    importDialogOpen: state.layout.importTemplatesDialogOpen,
    templateCount: state.templates.count,
    awsConnected: state.aws.all?.length > 0,
  };
};

const mapDispatchToProps = {
  setImportTemplatesDialogOpen,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateTemplateDropdown);
