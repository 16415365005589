import { useState } from 'react';
import { useDispatch } from 'react-redux';

export const useLazyLoading = ({
  action,
  onSuccess = () => {},
  onError = () => {},
}) => {
  const [state, setState] = useState({
    loading: false,
    error: undefined,
    data: undefined,
  });
  const dispatch = useDispatch();
  const call = async (...args) => {
    setState({
      ...state,
      loading: true,
    });

    try {
      const data = await dispatch(action(...args));

      setState({
        ...state,
        loading: false,
        data,
      });

      onSuccess(data);
      return data;
    } catch (error) {
      setState({
        ...state,
        loading: false,
        error: error.message,
      });

      onError(error);
      return undefined;
    }
  };

  return [call, { ...state }];
};
