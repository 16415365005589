import React, { Fragment, useEffect, useState } from 'react';
import { DialogFormFrame, LogoLoader } from 'components/Base';
import { fetchBlockUsages } from 'state/actions/blocks';
import { useLazyLoading } from 'state/hooks';
import { Box, styled, Tooltip, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { StatusChip } from '../Shared';
import { useHistory } from 'react-router-dom';

const TemplateRow = styled('div')(({ theme }) => ({
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
  margin: theme.spacing(1),
  padding: theme.spacing(2),
  paddingRight: theme.spacing(0),
  borderRadius: '8px',
  cursor: 'pointer',
}));

const ClickableTemplateRow = styled(TemplateRow)(({ theme }) => ({
  transition: 'box-shadow 0.3s ease',

  '&:hover': {
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.25)',
  },
}));

const ScrollableContent = styled('div')({
  maxHeight: 'calc(100vh - 10rem)',
  overflowY: 'auto',
});

const BlockTemplatesDialog = ({
  onClose,
  open,
  blockId,
  defaultAwsAccountId,
}) => {
  const history = useHistory();
  const [templates, setTemplates] = useState();
  const [blocksLoaded, setBlocksLoaded] = useState(false);

  const [getBlockUsages, { loading, error }] = useLazyLoading({
    action: fetchBlockUsages,
    onSuccess: (data) => {
      setTemplates(data);
      setBlocksLoaded(true);
    },
  });

  useEffect(() => {
    if (open && !blocksLoaded && !loading) {
      getBlockUsages({ blockId });
      setBlocksLoaded(true);
    }
  }, [open, blockId]); // eslint-disable-line

  const routeToTemplate = (templateId) => {
    let path = `/templates/${templateId}/editor`;
    history.push(path);
  };

  const TemplateRowContent = (template) => {
    return (
      <>
        <Box display="flex">
          <Box width="70%">
            <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
              <span style={{ color: 'grey' }}>Name:</span>{' '}
              {template.templateName}
            </Typography>
            <Typography variant="subtitle2" style={{ color: 'darkslategrey' }}>
              <strong>
                <span style={{ color: 'grey' }}>AWS Account Alias: </span>
              </strong>
              {template.awsAccount?.alias}
            </Typography>
          </Box>
          <div>
            <StatusChip status={template.status} />
          </div>
        </Box>
      </>
    );
  };

  const getDialogContent = () => {
    if (error) return <div>{error}</div>;
    if (loading || !blocksLoaded) return <LogoLoader />;

    return (
      <ScrollableContent>
        {templates?.length > 0 ? (
          templates?.map((template) => {
            return (
              <Fragment key={template.uid}>
                {template.awsAccountId === defaultAwsAccountId ? (
                  <ClickableTemplateRow
                    onClick={() => routeToTemplate(template.uid)}
                  >
                    {TemplateRowContent(template)}
                  </ClickableTemplateRow>
                ) : (
                  <Tooltip title={'Change AWS Account to get there.'}>
                    <TemplateRow>{TemplateRowContent(template)}</TemplateRow>
                  </Tooltip>
                )}
              </Fragment>
            );
          })
        ) : (
          <div>No templates available.</div>
        )}
      </ScrollableContent>
    );
  };

  return (
    <DialogFormFrame
      onClose={onClose}
      title="List of templates"
      open={open}
      formComponent={getDialogContent()}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    defaultAwsAccountId: state.aws?.default?.id,
  };
};

export default connect(mapStateToProps)(BlockTemplatesDialog);
