import React, { useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  CircularProgress,
  MenuItem,
  MenuList,
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { ArrayParam, useQueryParams, withDefault } from 'use-query-params';
import { ColoredTag } from 'components/Base';
import { getTags } from 'state/actions';
import { useLazyLoading } from 'state/hooks';

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  marginRight: 4,
  color: theme.palette.primary.main,
}));

const TagsFilter = () => {
  const [query, setQuery] = useQueryParams({
    filteredTags: withDefault(ArrayParam, []),
  });

  const { filteredTags } = query;

  const [tags, setTags] = useState();

  const [loadTags, { loading }] = useLazyLoading({
    action: getTags,
  });

  useEffect(() => {
    const fetchData = async () => {
      if (!tags && !loading) {
        const { tags } = await loadTags();
        setTags(tags);
      }
    };
    fetchData();
  }, [tags, loadTags, loading]);

  const handleTagFilterChange = (id) => {
    let updatedFilter = filteredTags;
    if (updatedFilter.includes(id)) {
      updatedFilter = updatedFilter.filter((tagId) => tagId !== id);
    } else {
      updatedFilter = [...updatedFilter, id];
    }
    setQuery({ filteredTags: updatedFilter });
  };

  if (!tags || loading)
    return (
      <Box p={2}>
        <CircularProgress size={27} />
      </Box>
    );

  return (
    <MenuList>
      {tags.map((tag) => (
        <MenuItem
          style={{ padding: 0 }}
          key={tag.label}
          value={tag}
          background={tag.color}
          onClick={() => handleTagFilterChange(tag.id)}
        >
          <StyledCheckbox checked={filteredTags?.includes(tag.id)} />
          <Box pr={1}>
            <ColoredTag background={tag.color}>{tag.label}</ColoredTag>
          </Box>
        </MenuItem>
      ))}
    </MenuList>
  );
};

export default TagsFilter;
