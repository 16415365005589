const verifiedDomainColumns = [
  {
    id: 'domainName',
    label: 'Domain',
    type: 'text',
    sortable: true,
  },
  {
    align: 'right',
    id: 'actions',
    label: 'Actions',
    type: 'actions',
    width: '10%',
    sortable: false,
  },
];

export default verifiedDomainColumns;
