const INITIAL_STATE = {
  entities: [],
  count: 0,
};

const setUserGroups = (state, { payload }) => {
  return {
    count: payload.count,
    entities: payload.entities.map((item) => ({
      ...item,
      awsAccountsCount: item.awsAccounts.length,
      policiesCount: item.policies.length,
    })),
  };
};

const deleteUserGroup = (state, { payload }) => {
  return {
    entities: state.entities.filter((item) => item.id !== payload),
    count: state.count - 1,
  };
};

const addUserGroup = (state, { payload }) => {
  const entities = [
    ...state.entities,
    {
      ...payload,
      awsAccountsCount: payload.awsAccounts.length,
      policiesCount: payload.policies.length,
    },
  ];
  return {
    entities,
    count: entities.length,
  };
};

const applyUpdateUserGroup = (state, { payload }) => {
  const index = state.entities.findIndex((item) => item.uid === payload.uid);

  if (index !== -1) {
    const updatedEntities = [...state.entities];

    updatedEntities[index] = {
      ...payload,
      awsAccountsCount: payload.awsAccounts.length,
      policiesCount: payload.policies.length,
    };

    return {
      count: state.count,
      entities: updatedEntities,
    };
  }

  return {
    count: state.count,
    entities: [
      ...state.entities,
      {
        ...payload,
        awsAccountsCount: payload.awsAccounts.length,
        policiesCount: payload.policies.length,
      },
    ],
  };
};

const policyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'USER_GROUPS_SET': {
      return setUserGroups(state, action);
    }
    case 'USER_GROUP_DELETE': {
      return deleteUserGroup(state, action);
    }
    case 'USER_GROUP_ADD': {
      return addUserGroup(state, action);
    }
    case 'USER_GROUP_UPDATE': {
      return applyUpdateUserGroup(state, action);
    }
    default:
      return state;
  }
};

export default policyReducer;
