import React, { useEffect, useState } from 'react';
import { Box, Grid, useTheme } from '@material-ui/core';
import { FastField as Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import {
  LogoLoader,
  LoadingButton,
  FieldWrapper,
  OverviewTable,
} from 'components/Base';
import { DrawerContent, DrawerFooter } from '../Shared';
import { connect } from 'react-redux';
import {
  getAwsAccounts,
  fetchCompanyMembers,
  fetchUserGroups,
  fetchUserGroupDetails,
  fetchPolicies,
  updateUserGroup,
  deleteUserGroup,
} from 'state/actions';
import { useAsyncAction, useLazyLoading } from 'state/hooks';
import { StringParam, useQueryParam } from 'use-query-params';
import * as Yup from 'yup';

const UpdateSchema = Yup.object().shape({
  label: Yup.string().required('Please enter a label for this User Group.'),
  description: Yup.string().required('Please enter a short description'),
});

const UserGroupDetails = ({ users }) => {
  const theme = useTheme();
  const [userGroupId, setUserGroupId] = useQueryParam(
    'userGroupId',
    StringParam
  );
  const [userGroup, setUserGroup] = useState();
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [selectedPolicyIds, setSelectedPolicyIds] = useState([]);
  const [selectedAwsAccountIds, setSelectedAwsAccountIds] = useState([]);

  const [userPage, setUserPage] = useState(0);
  const [userRowsPerPage, setUserRowsPerPage] = useState(10);

  const [awsPage, setAwsPage] = useState(0);
  const [policyPage, setPolicyPage] = useState(0);

  const [
    handleFetchUserGroups,
    { loading: loadingUserGroups },
  ] = useLazyLoading({
    action: fetchUserGroups,
  });

  const [handleFetchUsers, { loading: loadingUsers }] = useLazyLoading({
    action: fetchCompanyMembers,
  });

  const [
    handleFetchAwsAccounts,
    { loading: loadingAwsAccounts },
  ] = useLazyLoading({
    action: getAwsAccounts,
  });

  const [updateUserGroupAction, { loading: updating }] = useLazyLoading({
    action: updateUserGroup,
    onSuccess: () => {
      closeDrawer();
      handleFetchUserGroups();
      handleFetchAwsAccounts();
      handleFetchUsers();
    },
  });

  const [deleteUserGroupAction, { loading: deleting }] = useLazyLoading({
    action: deleteUserGroup,
    onSuccess: () => {
      closeDrawer();
    },
  });

  const [fetchUserGroupDetailsAction, { loading }] = useLazyLoading({
    action: fetchUserGroupDetails,
  });

  const { data: policies, loading: loadingPolicies } = useAsyncAction({
    action: fetchPolicies,
  });

  useEffect(() => {
    if (userGroupId && !userGroup && !loading) {
      fetchUserGroupDetailsAction(userGroupId).then((data) => {
        setUserGroup(data);
        if (data && data.members) {
          setSelectedUserIds(data.members.map((user) => user.uid));
        }
        if (data && data.policies) {
          setSelectedPolicyIds(data.policies.map((policy) => policy.id));
        }
        if (data && data.awsAccounts) {
          setSelectedAwsAccountIds(
            data.awsAccounts
              .filter((account) => account.active === true)
              .map((account) => account.id)
          );
        }
      });
    }
  }, [userGroupId, userGroup, loading, fetchUserGroupDetailsAction]);

  const handleUserChange = (userUid) => {
    setSelectedUserIds((prev) => {
      if (prev.includes(userUid)) {
        return prev.filter((uid) => uid !== userUid);
      } else {
        return [...prev, userUid];
      }
    });
  };

  const handlePolicyChange = (policyId) => {
    setSelectedPolicyIds((prev) => {
      if (prev.includes(policyId)) {
        return prev.filter((id) => id !== policyId);
      } else {
        return [...prev, policyId];
      }
    });
  };

  const handleAwsAccountChange = (accountId) => {
    setSelectedAwsAccountIds((prev) => {
      if (prev.includes(accountId)) {
        return prev.filter((id) => id !== accountId);
      } else {
        return [...prev, accountId];
      }
    });
  };

  const handleUpdateUserGroup = (values) => {
    updateUserGroupAction(userGroupId, {
      label: values.label,
      description: values.description,
      userIds: selectedUserIds,
      policyIds: selectedPolicyIds,
      awsAccountIds: selectedAwsAccountIds,
    });
  };

  const handleDeleteUserGroup = () => {
    deleteUserGroupAction(userGroupId);
  };

  const closeDrawer = () => {
    setUserGroupId(null);
  };

  if (
    loading ||
    loadingPolicies ||
    loadingUserGroups ||
    loadingUsers ||
    !userGroup
  )
    return <LogoLoader />;

  return (
    <Formik
      initialValues={{
        label: userGroup?.label || '',
        description: userGroup?.description || '',
      }}
      enableReinitialize
      validationSchema={UpdateSchema}
      onSubmit={handleUpdateUserGroup}
    >
      {({ values }) => (
        <Form style={{ height: '100%' }}>
          <DrawerContent>
            <Grid
              container
              direction="column"
              spacing={2}
              style={{ padding: '0px' }}
            >
              <Grid item>
                <h3>User Group Information</h3>
                <FieldWrapper
                  label="User Group Label"
                  subtitle="Label of User Group to be shown to your company members."
                >
                  <Field
                    component={TextField}
                    fullWidth
                    name="label"
                    variant="outlined"
                  />
                </FieldWrapper>
                <Box />
                <Box pt={3} pb={2}>
                  <FieldWrapper
                    label="User Group Description"
                    subtitle="A short description explaining the permission set of the User Group."
                  >
                    <Field
                      component={TextField}
                      fullWidth
                      name="description"
                      variant="outlined"
                      multiline
                      rows={4}
                    />
                  </FieldWrapper>
                </Box>
              </Grid>
              <Grid item>
                <h3>Users</h3>
                <Box>
                  <OverviewTable
                    data={users}
                    columns={[
                      {
                        label: 'Assigned',
                        id: 'select',
                        type: 'select',
                        align: 'left',
                        isSelected: (row) => {
                          return selectedUserIds.includes(row.uid);
                        },
                        onSelect: (row) => handleUserChange(row.uid),
                      },
                      {
                        label: 'Email',
                        id: 'email',
                        align: 'left',
                        type: 'text',
                      },
                    ]}
                    total={users.length}
                    entityName="selected_users"
                    page={userPage}
                    setPage={setUserPage}
                    styleOverwrites={{ minWidth: theme.spacing(10) }}
                    rowsPerPage={userRowsPerPage}
                    setRowsPerPage={setUserRowsPerPage}
                    loading={loadingUsers}
                  />
                </Box>
              </Grid>
              {userGroup.awsAccounts.length > 0 && (
                <Grid item>
                  <h3>AWS Account Access</h3>
                  <Box>
                    <OverviewTable
                      data={userGroup.awsAccounts}
                      columns={[
                        {
                          label: 'Assigned',
                          id: 'select',
                          type: 'select',
                          align: 'left',
                          isSelected: (row) => {
                            return selectedAwsAccountIds.includes(row.id);
                          },
                          onSelect: (row) => handleAwsAccountChange(row.id),
                        },
                        {
                          label: 'AWS Account',
                          id: 'alias',
                          align: 'left',
                          type: 'text',
                        },
                      ]}
                      total={userGroup.awsAccounts}
                      entityName="selected_aws_account"
                      page={awsPage}
                      setPage={setAwsPage}
                      styleOverwrites={{ minWidth: theme.spacing(10) }}
                      rowsPerPage={5}
                      loading={loadingAwsAccounts}
                    />
                  </Box>
                </Grid>
              )}
              <Grid item>
                <h3>Policies</h3>
                <Box>
                  <OverviewTable
                    data={policies.entities}
                    columns={[
                      {
                        label: 'Assigned',
                        id: 'select',
                        type: 'select',
                        align: 'left',
                        isSelected: (row) => {
                          return selectedPolicyIds.includes(row.id);
                        },
                        onSelect: (row) => handlePolicyChange(row.id),
                      },
                      {
                        label: 'Policy',
                        id: 'label',
                        align: 'left',
                        type: 'text',
                      },
                    ]}
                    total={policies.count}
                    entityName="selected_policies"
                    page={policyPage}
                    setPage={setPolicyPage}
                    styleOverwrites={{ minWidth: theme.spacing(10) }}
                    rowsPerPage={5}
                    loading={loadingPolicies}
                  />
                </Box>
              </Grid>
            </Grid>
          </DrawerContent>
          <DrawerFooter>
            <LoadingButton
              variant="outlined"
              loading={deleting}
              color="error"
              onClick={handleDeleteUserGroup}
            >
              Remove User Group
            </LoadingButton>
            <LoadingButton
              variant="contained"
              loading={updating}
              color="primary"
              type="submit"
            >
              Update
            </LoadingButton>
          </DrawerFooter>
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state) => {
  return {
    users: state.members?.entities || [],
  };
};

export default connect(mapStateToProps)(UserGroupDetails);
