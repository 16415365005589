import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { privateRoutesAccess } from 'consts';
import { showInfoSnackbar } from 'state/actions/layout';
import { connect } from 'react-redux';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const authUser = useSelector((state) => state.auth.authUser);
  const userPermissions = useSelector((state) => state.user.permissions);
  const dispatch = useDispatch();

  if (!authUser) {
    return <Redirect to="/login" />;
  }

  const hasPermission = (requiredPermissions) => {
    if (!requiredPermissions || requiredPermissions.length === 0) return true;
    return requiredPermissions.some((perm) =>
      userPermissions.some((userPerm) => userPerm.id === perm)
    );
  };

  const requiredPermissions = privateRoutesAccess[rest.computedMatch.path];

  if (!hasPermission(requiredPermissions)) {
    dispatch(
      showInfoSnackbar(
        'You do not have access to this route. Redirecting to Home.'
      )
    );
    return <Redirect to="/" />;
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export const mapDispatchToProps = {
  showInfoSnackbar,
};

export default connect(null, mapDispatchToProps)(PrivateRoute);
