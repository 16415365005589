export const backendErrors = {
  AWS_ACCOUNT_MISSING:
    'No AWS Account conntected to publish to. Please connect your AWS Account.',
  AWS_CREDENTIALS_INSUFFICIENT:
    'The AWS credentials provided do not have sufficient privileges. You might experience issues when using our services.',
  DELETE_SELF_NOT_POSSIBLE:
    'You cannot remove your own account. Please add another admin so he can remove you.',
  ROLE_DOWNGRADE_SELF_NOT_POSSIBLE:
    'You cannot downgrade your own account. Please add another admin so he can change your role.',
  TAG_LABEL_ALREADY_EXISTS:
    'A tag with this label already exists. Please choose another one.',
  AWS_CREDENTIALS_EXIST:
    'The region for this account cannot be added. Either you added it already or it is managed by a different Semplates account.',
  AWS_CREDENTIALS_INVALID:
    'The credentials you entered are invalid. Please check them and try again.',
  CURRENT_USER_DEFAULT_ACCOUNT:
    'You cannot remove the account which is currently set as your default.',
  ERROR_RETRIEVING_AWS_ACCOUNTS:
    'We were not able to find any connected aws accounts. Try again or ask for support.',
  IDENTITY_NOT_FOUND: 'Identity not found. Try again or ask for support.',
  IDENTITY_EXISTS:
    'You are trying to add an identity that is already connected. It is not possible to connect the same identity twice.',
  USER_EXISTS:
    'This user is already using Semplates. Contact us for more information.',
  MEMBER_NOT_FOUND: 'We are sorry, but we were not able to find this user.',
  TEMPLATE_NOT_FOUND:
    'The requested template was not found. It might have been deleted via SES directly. If you need to restore this template please contact our support.',
  TEMPLATE_EXISTS:
    'A template with this name is already existing. Each template can only be chosen once per AWS account.',
  TEMPLATE_UPDATE_FAILED_BLOCK_MODIFIED:
    'This template contains reusable blocks, that have been changed.',
  REMOVING_OWN_IAM_PERMISSION_NOT_POSSIBLE:
    'You cannot remove your own IAM permissions. Please ask another admin to remove your permissions.',
  REMOVING_OWN_IAM_ACCESS:
    'You cannot remove your own user. Please ask another admin to remove you.',
  DEFAULT_POLICY_CANNOT_BE_EDITED:
    'Default policies cannot be edited. Please choose another policy.',
  USER_GROUP_REQUIRED:
    'Users need to be associated with at least one user group.',
  USER_NOT_VERIFIED:
    'Your user is not yet verified. We resent a verification email to your email address.',
  REMOVING_OWN_IAM_ACCESS_BY_USER_REMOVAL:
    'You cannot remove your own user from the user group. Please ask another admin to remove you.',
  REMOVING_OWN_IAM_ACCESS_BY_POLICY_REMOVAL:
    'You cannot change the policies as you did, as it would remove your access to this page.',
};
