import { Button, styled } from '@material-ui/core';

export const StyledButton = styled(Button)(({ theme, width, size }) => ({
  width: width ? `${width}px` : 'unset',
  fontFamily: theme.typography.fontFamilyTitle,
  textTransform: 'none',
  borderWidth: '1.5px',
  padding: size === 'large' ? theme.spacing(0.5, 2) : theme.spacing(0.5, 1),
  height: theme.custom.buttonHeight,
}));

export const StyledGroupButton = styled(StyledButton)(
  ({ theme, isactive }) => ({
    fontWeight: isactive ? 600 : 400,
    padding: theme.spacing(0.5, 2),
    backgroundColor: isactive
      ? theme.palette.grey.main
      : theme.palette.background.paper,
    border: `0.5px solid ${theme.palette.grey.borderColor}`,

    color: theme.palette.text.primary,

    '&:disabled': {
      backgroundColor: theme.palette.grey[300],
    },
  })
);

export const StyledInvertedButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.main,
  fontFamily: theme.typography.fontFamilyTitle,
  textTransform: 'none',
  height: theme.custom.buttonHeight,
  borderColor:
    theme.palette.type === 'dark'
      ? theme.palette.text.main
      : theme.palette.primary.main,
  borderWidth: '1.5px',
  '&:hover': {
    borderColor: theme.palette.text.main,
    backgroundColor: theme.palette.text.main,
    color: theme.palette.background.main,
    borderWidth: '1.5px',
  },
  padding: theme.spacing(0.5, 1),
}));
