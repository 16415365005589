import React, { useState } from 'react';
import { Layout } from 'components/Structure';
import { SettingsHeader } from 'components/Base';
import { Grid } from '@material-ui/core';
import { TagManager } from 'components/Menu/Tags';
import CreateTagDialog from 'components/Menu/Tags/CreateTagDialog';
import { connect } from 'react-redux';
import { TagProvider } from 'components/Contexts';
import { infoBoxTexts } from 'consts';

const Tags = ({ count }) => {
  const [createTagDialogOpen, setCreateTagDialogOpen] = useState(false);
  const [selectedTag, setSelectedTag] = useState('');

  const title = 'Tag Manager';

  const handleClose = () => {
    setCreateTagDialogOpen(false);
    setSelectedTag(null);
  };

  const handleEditTag = (tag) => {
    setSelectedTag(tag);
    setCreateTagDialogOpen(true);
  };

  return (
    <Layout title={title}>
      <TagProvider>
        <Grid container direction={'column'}>
          <SettingsHeader
            buttonText={'Add new tag'}
            title={'Existing tags'}
            itemsCount={count}
            infoText={infoBoxTexts.tags}
            onClick={() => setCreateTagDialogOpen(true)}
            primaryActionPermission={'write_tags'}
          />
          <TagManager onEditDialogClick={handleEditTag} />
          <CreateTagDialog
            open={createTagDialogOpen}
            tag={selectedTag}
            onClose={handleClose}
          />
        </Grid>
      </TagProvider>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    count: state.tags.count,
  };
};

export default connect(mapStateToProps)(Tags);
