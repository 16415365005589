import React, { useState } from 'react';
import { FastField as Field, Form, Formik } from 'formik';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { TextField } from 'formik-material-ui';
import { Grid, Link } from '@material-ui/core';
import { useConversions } from 'components/Contexts';
import * as Yup from 'yup';
import { CheckboxField, FieldWrapper, LoadingButton } from 'components/Base';
import PasswordInputField from './PasswordInputField';
import { TermsAndConditionsDialog } from './Dialogs/index';
import { register } from 'state/actions';
import { useLazyLoading } from 'state/hooks';
import { isCompanyEmail } from 'components/utils';
import { StyledLink, SpacedContainer } from './styles';
import SSOButtons from './SSOButtons';
import AuthHeadline from './AuthHeadline';
import { styled } from '@material-ui/core/styles';
import { googleAdsConversionTypes } from 'consts';
import { StringParam, useQueryParam } from 'use-query-params';

export const LoginButton = styled(LoadingButton)(({ theme }) => ({
  marginTop: theme.spacing(1.5),
  width: '100%',
  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.text.main,
  },
}));

export const RegistrationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email')
    .required('This field is required'),
  password: Yup.string()
    .required('This field is required.')
    .min(6, 'Please enter at least 6 characters'),
  termsAccepted: Yup.bool()
    .oneOf([true], 'Please accept terms and conditions')
    .required('Please accept terms and conditions'),
});

const RegistrationForm = () => {
  const { sendConversion } = useConversions();
  const history = useHistory();
  const [isSignedUp, setSignedUp] = useState(false);
  const [email] = useQueryParam('email', StringParam);

  const [termsOpen, setTermsOpen] = useState(false);

  const [handleSubmit, { loading }] = useLazyLoading({
    action: register,
    onSuccess: (user) => {
      if (user?.gclid) {
        sendConversion(
          user.gclid,
          isCompanyEmail(user.email)
            ? googleAdsConversionTypes.COMPANY_SUBSCRIBER
            : googleAdsConversionTypes.SUBSCRIBER
        );
      }

      if (user?.emailVerified) {
        history.push('/');
      } else {
        setSignedUp(true);
      }
    },
  });

  if (isSignedUp) {
    return (
      <AuthHeadline
        title="Thank you for signing up!"
        subTitle="Please check your email for a confirmation link."
      />
    );
  }

  return (
    <Formik
      initialValues={{
        email: email || '',
        password: '',
        termsAccepted: false,
      }}
      validateOnBlur={false}
      validationSchema={RegistrationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, setFieldValue }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AuthHeadline
                title="Welcome"
                subTitle="Create your free account."
              />
            </Grid>
            <Grid item xs={12}>
              <FieldWrapper label="Email" inverted variant="small">
                <Field
                  component={TextField}
                  fullWidth
                  name="email"
                  variant="outlined"
                />
              </FieldWrapper>
            </Grid>
            <Grid item xs={12}>
              <FieldWrapper label="Password" inverted variant="small">
                <Field
                  fullWidth
                  component={PasswordInputField}
                  name="password"
                  variant="outlined"
                />
              </FieldWrapper>
            </Grid>
            <Grid item xs={12}>
              <SpacedContainer>
                <Field
                  component={CheckboxField}
                  name="termsAccepted"
                  className="form-control rounded-0"
                  color="secondary"
                  hasdarkbackground="true"
                  childrenComponent={
                    <span style={{ color: 'white' }}>
                      I agree to the &nbsp;
                      <StyledLink onClick={() => setTermsOpen(true)}>
                        <b>terms and conditions</b>
                      </StyledLink>
                    </span>
                  }
                />
                <Link component={RouterLink} to="/login" color="secondary">
                  <StyledLink>
                    Already registered?&nbsp;
                    <b>Login here.</b>
                  </StyledLink>
                </Link>
              </SpacedContainer>
            </Grid>
            <Grid item xs={12}>
              <LoginButton
                loading={isSubmitting || loading}
                variant="contained"
                color="secondary"
                type="submit"
                className="button-wide"
              >
                Signup
              </LoginButton>
            </Grid>
            <Grid item xs={12}>
              <SSOButtons />
            </Grid>
          </Grid>

          <TermsAndConditionsDialog
            open={termsOpen}
            onAccept={() => {
              setFieldValue('termsAccepted', true);
              setTermsOpen(false);
            }}
            onClose={() => setTermsOpen(false)}
          />
        </Form>
      )}
    </Formik>
  );
};

export default RegistrationForm;
