import {
  CREATE_POLICY_SUCCESS,
  CREATE_POLICY_ERROR,
  DELETE_POLICY_SUCCESS,
  DELETE_POLICY_ERROR,
  DUPLICATE_POLICY_SUCCESS,
  DUPLICATE_POLICY_ERROR,
  UPDATE_POLICY_SUCCESS,
  UPDATE_POLICY_ERROR,
} from 'state/consts/snackbarMessages';
import { showSuccessSnackbar, showErrorSnackbar } from 'state/actions/layout';
import { handleBackendErrorMessage } from 'state/utils/error_handling';

export const setPolicies = (data) => ({
  type: 'POLICIES_SET',
  payload: data,
});

export const setDeletePolicy = (data) => ({
  type: 'POLICY_DELETE',
  payload: data,
});

export const setUpdatePolicy = (data) => ({
  type: 'POLICY_UPDATE',
  payload: data,
});

export const fetchPolicies = ({
  limit, // Default to undefined
  skip = 0,
  search = '',
  order = 'asc',
  orderBy = 'label',
} = {}) => async (dispatch, getState, { axios }) => {
  try {
    const { policies: state } = getState().policies;

    if (!state?.length || state.length < skip) {
      if (limit !== undefined) {
        limit = skip + limit;
      }
      skip = 0;
    }

    let query = `/iam/policies?skip=${skip}&order=${order}&order_by=${orderBy}`;
    if (limit !== undefined) {
      query += `&limit=${limit}`;
    }
    if (search) {
      query += `&search=${search}`;
    }

    const { data } = await axios.get(query);

    if (skip > 0 && state?.length) {
      const allPolicies = state.concat(data.entities);
      dispatch(
        setPolicies({
          entities: allPolicies,
          count: data.count,
        })
      );
    } else {
      dispatch(setPolicies({ entities: data.entities, count: data.count }));
    }

    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchPolicyDetails = (id) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const { data } = await axios.get(`/iam/policies/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const updatePolicyMetadata = (id, values) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const { data } = await axios.put(`/iam/policies/${id}`, values);
    dispatch(setUpdatePolicy(data));
    dispatch(showSuccessSnackbar(UPDATE_POLICY_SUCCESS));
    return data;
  } catch (error) {
    handleBackendErrorMessage(error, dispatch, UPDATE_POLICY_ERROR);
    throw error;
  }
};

export const createPolicy = (values) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const { data } = await axios.post(`/iam/policies`, values);
    dispatch(showSuccessSnackbar(CREATE_POLICY_SUCCESS));
    return data;
  } catch (error) {
    handleBackendErrorMessage(error, dispatch, CREATE_POLICY_ERROR);
    throw error;
  }
};

export const deletePolicy = (id) => async (dispatch, getState, { axios }) => {
  try {
    await axios.delete(`/iam/policies/${id}`);
    dispatch(setDeletePolicy(id));
    dispatch(showSuccessSnackbar(DELETE_POLICY_SUCCESS));
  } catch (error) {
    if (error.response?.status === 409) {
      dispatch(showErrorSnackbar(error.response.data.detail));
    } else {
      handleBackendErrorMessage(error, dispatch, DELETE_POLICY_ERROR);
    }
    throw error;
  }
};

export const duplicatePolicy = (id) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const { data } = await axios.post(`/iam/policies/duplicate`, {
      source_policy_id: id,
    });
    dispatch(showSuccessSnackbar(DUPLICATE_POLICY_SUCCESS));
    return data;
  } catch (error) {
    handleBackendErrorMessage(error, dispatch, DUPLICATE_POLICY_ERROR);
    throw error;
  }
};
