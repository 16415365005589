import {
  DELETE_MEMBER_ERROR,
  DELETE_MEMBER_SUCCESS,
  INVITE_MEMBER_ERROR,
  INVITE_MEMBER_COSTS_ERROR,
  INVITE_MEMBER_SUCCESS,
  UPDATE_MEMBER_ERROR,
  UPDATE_MEMBER_SUCCESS,
  RESEND_MEMBER_INVITATION_SUCCESS,
  RESEND_MEMBER_INVITATION_ERROR,
} from 'state/consts/snackbarMessages';
import { showErrorSnackbar, showSuccessSnackbar } from 'state/actions/layout';
import {
  handleBackendErrorMessage,
  handleUpgradeError,
} from 'state/utils/error_handling';
import { fetchSignInMethodsForEmail } from 'firebase/auth';

export const setCompanyMembers = (data) => ({
  type: 'COMPANY_MEMBERS_SET',
  payload: data,
});

export const setCompanyMember = (data) => ({
  type: 'COMPANY_MEMBER_SET',
  payload: data,
});

export const removeCompanyMember = (data) => ({
  type: 'COMPANY_MEMBER_REMOVE',
  payload: data,
});

const enrichMembersWithSignInMethods = async (members, getFirebase) => {
  const auth = getFirebase().auth();
  try {
    return await Promise.all(
      members.map(async (member) => {
        try {
          let signInMethods = await fetchSignInMethodsForEmail(
            auth,
            member.email
          );
          // Initialize a Set to store unique signInMethods
          const uniqueMethods = new Set();

          // Filter, clean, and add unique signInMethods
          signInMethods.forEach((method) => {
            if (method.includes('microsoft')) uniqueMethods.add('microsoft');
            else if (method.includes('google')) uniqueMethods.add('google');
            else if (method.includes('okta')) uniqueMethods.add('okta');
            else if (method === 'password') uniqueMethods.add('password');
          });

          // Convert the Set back to an array
          signInMethods = Array.from(uniqueMethods);
          return { ...member, signInMethods };
        } catch (error) {
          return member;
        }
      })
    );
  } catch (error) {
    return members;
  }
};

export const fetchCompanyMembers = ({
  limit, // Default to undefined
  skip = 0,
  search = '',
  order = 'asc',
  orderBy = 'display_name',
} = {}) => async (dispatch, getState, { axios, getFirebase }) => {
  try {
    const { entities: state } = getState().members;

    if (!state?.length || state.length < skip) {
      if (limit !== undefined) {
        limit = skip + limit;
      }
      skip = 0;
    }

    let query = `/iam/members?skip=${skip}&order=${order}&order_by=${orderBy}`;
    if (limit !== undefined) {
      query += `&limit=${limit}`;
    }
    if (search) {
      query += `&search=${search}`;
    }

    const { data } = await axios.get(query);

    if (data.entities.length >= 3) {
      const enrichedData = await enrichMembersWithSignInMethods(
        data.entities,
        getFirebase
      );
      data.entities = enrichedData;
    }

    if (skip > 0 && state?.length) {
      const allMembers = state.concat(data.entities);
      dispatch(
        setCompanyMembers({
          entities: allMembers,
          count: data.count,
        })
      );
    } else {
      dispatch(
        setCompanyMembers({ entities: data.entities, count: data.count })
      );
    }

    return data;
  } catch (error) {
    throw error;
  }
};

export const inviteCompanyMember = ({
  email,
  userGroupIds,
  confirmAdditionalCosts,
}) => async (dispatch, getState, { axios }) => {
  try {
    await axios.post(`/iam/members`, {
      email,
      user_group_ids: userGroupIds,
      confirm_additional_costs: confirmAdditionalCosts,
    });
    dispatch(showSuccessSnackbar(INVITE_MEMBER_SUCCESS));
    return true;
  } catch (error) {
    console.log(error);
    if (error.response?.status === 426) {
      handleUpgradeError(error, dispatch);
    } else if (error.response?.status === 402) {
      dispatch(showErrorSnackbar(INVITE_MEMBER_COSTS_ERROR));
    } else {
      handleBackendErrorMessage(error, dispatch, INVITE_MEMBER_ERROR);
    }
    throw error;
  }
};

export const deleteCompanyMember = (uid) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    await axios.delete(`/iam/members/${uid}`);
    dispatch(removeCompanyMember({ uid }));
    dispatch(showSuccessSnackbar(DELETE_MEMBER_SUCCESS));
    return true;
  } catch (error) {
    console.log(error);
    handleBackendErrorMessage(error, dispatch, DELETE_MEMBER_ERROR);
    throw error;
  }
};

export const updateMemberUserGroups = (uid, userGroupIds) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const { data } = await axios.put(`/iam/members/${uid}/group`, {
      ids: userGroupIds,
    });
    dispatch(setCompanyMember(data));
    dispatch(showSuccessSnackbar(UPDATE_MEMBER_SUCCESS));
    return data;
  } catch (error) {
    handleBackendErrorMessage(error, dispatch, UPDATE_MEMBER_ERROR);
    throw error;
  }
};

export const resendMemberInvitation = (uid) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const { data } = await axios.put(`/iam/members/${uid}/reinvite`);
    dispatch(showSuccessSnackbar(RESEND_MEMBER_INVITATION_SUCCESS));
    return data;
  } catch (error) {
    console.log(error);
    handleBackendErrorMessage(error, dispatch, RESEND_MEMBER_INVITATION_ERROR);
    throw error;
  }
};
