import React, { useRef } from 'react';
import { PermissionsWrapper } from 'components/Base';
import { ButtonGroup, styled, Tooltip } from '@material-ui/core';
import { Save, Publish } from 'assets/icons';
import LoadingButton from 'components/Base/LoadingButton';
import { useSelector } from 'react-redux';

const StyledButtonGroup = styled(ButtonGroup)(() => ({
  '&:hover': {
    boxShadow: 'none',
  },
  boxShadow: 'none',
}));

const commonButtonStyles = ({ theme, published }) => ({
  transition: 'background-color 0.25s',
  backgroundColor: published
    ? theme.palette.secondary.main
    : theme.palette.primary.main,
  color: published ? theme.palette.text.main : theme.palette.text.reverted,
  '&:hover': {
    backgroundColor: published
      ? theme.palette.secondary.dark
      : theme.palette.primary.dark,
    boxShadow: 'none',
  },
  '&:disabled': {
    color: 'rgba(3,39,51,0.4)',
  },
});

const StyledLoadingButton = styled(LoadingButton)(commonButtonStyles);

/* good for future dropdown secondary action
const StyledButtonWithCommon = styled(Button)(commonButtonStyles);

const StyledButton = styled(StyledButtonWithCommon)(({ theme }) => ({
  width: theme.spacing(4),
  height: theme.custom.buttonHeight,
}));*/

const BlockPublishButton = ({
  unsavedChanges,
  onSave,
  onSaveToTemplates,
  onPublishToTemplates,
  loading,
  block,
}) => {
  const anchorRef = useRef(null);

  const { all: awsAccounts } = useSelector((state) => state.aws);

  const showUpdate = !unsavedChanges && block?.state === 'UPDATED';

  const showPublish = !showUpdate && block.state === 'PROPAGATED';
  const awsAccountIds = awsAccounts.map((awsAccount) => awsAccount.id);

  if (unsavedChanges) {
    return (
      <PermissionsWrapper permission={'edit_templates'}>
        <StyledLoadingButton
          loading={loading}
          startIcon={<Save size={26} />}
          onClick={() => onSave()}
          disabled={!unsavedChanges}
        >
          Update Block
        </StyledLoadingButton>
      </PermissionsWrapper>
    );
  }

  // publish state action
  if (showPublish) {
    return (
      <PermissionsWrapper permission={'publish_templates'}>
        <StyledButtonGroup
          variant="contained"
          ref={anchorRef}
          disabled={loading}
        >
          <Tooltip title="This action will publish all templates that use this block across all accounts with the latest saved version of this block.">
            <StyledLoadingButton
              loading={loading}
              startIcon={<Publish size={26} />}
              onClick={() => onPublishToTemplates(awsAccountIds)}
            >
              Publish all Templates
            </StyledLoadingButton>
          </Tooltip>
        </StyledButtonGroup>
      </PermissionsWrapper>
    );
  }

  // Update state action
  if (showUpdate) {
    return (
      <PermissionsWrapper permission={'edit_templates'}>
        <StyledButtonGroup
          variant="contained"
          ref={anchorRef}
          disabled={loading}
        >
          <Tooltip title="This action will save the latest version of this block to all templates that use this block across all accounts.">
            <StyledLoadingButton
              loading={loading}
              startIcon={<Save size={26} />}
              onClick={() => onSaveToTemplates(awsAccountIds)}
            >
              Save to all Templates
            </StyledLoadingButton>
          </Tooltip>
        </StyledButtonGroup>
      </PermissionsWrapper>
    );
  }

  return (
    <PermissionsWrapper permission={'edit_templates'}>
      <StyledLoadingButton
        loading={loading}
        startIcon={<Save size={26} />}
        onClick={() => onSave()}
        disabled={!unsavedChanges}
      >
        Update Block
      </StyledLoadingButton>
    </PermissionsWrapper>
  );
};

export default BlockPublishButton;
