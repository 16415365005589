import React, { useEffect, useState } from 'react';
import { DialogFormFrame, LogoLoader } from 'components/Base';
import { fetchTemplateBlocks } from 'state/actions/blocks';
import { useLazyLoading } from 'state/hooks';
import { Box, styled, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const ClickableBlock = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  border: '1px solid lightgrey',
  transition: 'box-shadow 0.3s ease',

  '&:hover': {
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.25)',
  },
}));

const ScrollableContent = styled('div')({
  maxHeight: 'calc(100vh - 10rem)',
  overflowY: 'auto',
});

const TemplateBlocksDialog = ({ onClose, open, templateId }) => {
  const history = useHistory();
  const [blocks, setBlocks] = useState();

  const [getBlockUsages, { loading, error }] = useLazyLoading({
    action: fetchTemplateBlocks,
    onSuccess: (data) => setBlocks(data),
  });

  const handleOnClose = () => {
    setBlocks(null);
    onClose();
  };

  useEffect(() => {
    if (open && !blocks && !loading) {
      getBlockUsages({ templateId });
    }
  }, [open, getBlockUsages, templateId, loading, blocks]);

  const routeToBlock = (blockId) => {
    let path = `/templates/reusable-blocks?blockId=${blockId}`;
    history.push(path);
  };

  const BlockContent = (block) => {
    return (
      <>
        <ClickableBlock dangerouslySetInnerHTML={{ __html: block.html }} />
      </>
    );
  };

  const getDialogContent = () => {
    if (error) return <div>{error}</div>;
    if (loading || !blocks) return <LogoLoader />;
    return (
      <ScrollableContent>
        {blocks.length > 0 ? (
          blocks?.map(
            (block) =>
              block.html && (
                <Box my={2}>
                  <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
                    <span style={{ color: 'grey' }}>Name:</span> {block.label}
                  </Typography>
                  <div onClick={() => routeToBlock(block.id)}>
                    {BlockContent(block)}
                  </div>
                </Box>
              )
          )
        ) : (
          <div>No blocks available.</div>
        )}
      </ScrollableContent>
    );
  };

  return (
    <DialogFormFrame
      onClose={handleOnClose}
      title="List of blocks used in this template"
      open={open}
      formComponent={getDialogContent()}
    />
  );
};

export default TemplateBlocksDialog;
