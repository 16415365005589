import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Layout, SettingsContainer } from 'components/Structure';
import { companySettings, companySubscription } from 'consts/routes';
import { TeamAndAccess, Subscriptions } from 'components/Menu/CompanySettings';

const CompanySettings = () => {
  const matchMembers = useRouteMatch(companySettings);
  const matchSubscription = useRouteMatch(companySubscription);

  const getTitle = () => {
    if (matchMembers?.isExact) {
      return 'Team & Access';
    } else if (matchSubscription?.isExact) {
      return 'Subscription';
    } else {
      return 'Company Settings';
    }
  };

  return (
    <Layout title={getTitle()}>
      <SettingsContainer>
        <Switch>
          <Route exact path={companySettings} component={TeamAndAccess} />
          <Route exact path={companySubscription} component={Subscriptions} />
        </Switch>
      </SettingsContainer>
    </Layout>
  );
};

export default CompanySettings;
