import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@material-ui/core/styles';
import {
  ClickAwayListener,
  Grow,
  IconButton,
  Paper,
  Popper,
} from '@material-ui/core';
import { ArrayParam, useQueryParams, withDefault } from 'use-query-params';
import { FilterList } from 'assets/icons';
import { TagsFilter } from 'components/Menu/Tags';

export const FilterContainer = styled('div')({
  marginLeft: '4px',
  position: 'relative',
});

export const FilterCount = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '-2px',
  right: '-6px',
  width: '16px',
  height: '16px',
  borderRadius: '100%',
  backgroundColor: theme.palette.warning.main,
  color: theme.palette.text.main,
  zIndex: 1,
  justifyContent: 'center',
  opacity: 0.9,
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
}));

const TagFilterIconButton = () => {
  const [filterOpen, setFilterOpen] = useState(false);
  const filterAnchorRef = useRef(null);

  const [query] = useQueryParams({
    filteredTags: withDefault(ArrayParam, []),
  });

  const { filteredTags } = query;

  const [tagFilterCount, setTagFilterCount] = useState(filteredTags.length);

  useEffect(() => {
    setTagFilterCount(filteredTags.length);
  }, [filteredTags]);

  return (
    <FilterContainer>
      {tagFilterCount > 0 && <FilterCount>{tagFilterCount}</FilterCount>}
      <IconButton
        ref={filterAnchorRef}
        style={{ padding: 0 }}
        onClick={() => setFilterOpen(!filterOpen)}
      >
        <FilterList size={20} />
      </IconButton>
      <Popper
        key={`template-table-popper-filter`}
        open={filterOpen}
        anchorEl={filterAnchorRef.current}
        transition
        disablePortal
        placement={'bottom-start'}
        popperOptions={{
          modifiers: {
            preventOverflow: {
              padding: 0,
            },
          },
        }}
        style={{ zIndex: 1 }}
      >
        {({ TransitionProps, placement }) => (
          <ClickAwayListener onClickAway={() => setFilterOpen(false)}>
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: 'center top' }}
            >
              <Paper>
                <TagsFilter />
              </Paper>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    </FilterContainer>
  );
};

export default TagFilterIconButton;
