import { showErrorSnackbar, showUpgradeDialog } from '../actions';
import { backendErrors } from '../consts/backendErrorDefinitions';

export const handleUpgradeError = (error, dispatch) => {
  if (error.response?.status === 426) {
    const errorCode = error.data?.detail;
    dispatch(showUpgradeDialog(errorCode));
    throw error;
  }
};

export const handleBackendErrorMessage = (error, dispatch, fallback) => {
  const errorCode = error.response?.data?.detail;
  if (errorCode && errorCode !== 'undefined' && backendErrors[errorCode]) {
    dispatch(showErrorSnackbar(`${backendErrors[errorCode]}`));
  } else if (errorCode) {
    dispatch(showErrorSnackbar(`${errorCode}`));
  } else {
    dispatch(showErrorSnackbar(`${fallback}: ${errorCode}`));
  }
  throw error;
};
