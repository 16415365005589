import React, { Fragment, useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import {
  LogoLoader,
  OverviewTable,
  SettingsHeader,
  AlertDialog,
} from 'components/Base';
import { UserDrawer } from '../Shared';
import { policyColumns, infoBoxTexts } from 'consts';
import {
  fetchPolicies,
  deletePolicy,
  duplicatePolicy,
  getUserDetails,
} from 'state/actions';
import { useLazyLoading } from 'state/hooks';
import { connect } from 'react-redux';
import CreatePolicyDialog from './CreatePolicyDialog';
import { StringParam, useQueryParam } from 'use-query-params';
import PolicyDetails from './PolicyDetails';
import { DeleteOutline, EditIcon, ContentCopy } from 'assets/icons';

const PolicyTable = ({ policies, policyCount, dispatch }) => {
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [policyId, setPolicyId] = useQueryParam('policyId', StringParam);
  const [scopedPolicy, setScopedPolicy] = useState(null);

  const [handleFetchUser] = useLazyLoading({
    action: getUserDetails,
  });

  const [handleFetchPolicies] = useLazyLoading({
    action: fetchPolicies,
  });

  const onChangeHandleFetchPolicy = () => {
    handleFetchPolicies({ limit: rowsPerPage, skip: page * rowsPerPage });
  };

  useEffect(() => {
    onChangeHandleFetchPolicy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const [handleDeletePolicy, { loading: deleteLoading }] = useLazyLoading({
    action: deletePolicy,
    onSuccess: () => {
      handleCloseDeleteDialog();
    },
  });

  const [handleDuplicatePolicy] = useLazyLoading({
    action: duplicatePolicy,
    onSuccess: (policy) => {
      onChangeHandleFetchPolicy(); // as we add, we re-fetch in order to preserve ordering
      setPolicyId(policy.id);
    },
  });

  const handleOpenEditDrawer = (policy) => {
    setPolicyId(policy.id);
  };

  const handleOpenDeleteDialog = (policy) => {
    setDeleteDialogOpen(true);
    setScopedPolicy(policy);
  };

  const handleDuplicatePolicyClick = (policyId) => {
    handleDuplicatePolicy(policyId);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setScopedPolicy(null);
  };

  if (policies === undefined) {
    return <LogoLoader />;
  }

  return (
    <Fragment>
      <Grid item>
        <SettingsHeader
          buttonText={'Add new Policy'}
          title={'Policies'}
          onClick={() => setCreateDialogOpen(true)}
          itemsCount={policyCount}
          infoText={infoBoxTexts.policies}
        />
        <OverviewTable
          data={policies}
          columns={policyColumns}
          total={policyCount}
          entityName="policies"
          actions={[
            {
              icon: <EditIcon size="26" />,
              label: 'Edit Policy',
              onClick: handleOpenEditDrawer,
              enabledCondition: (policy) => {
                return policy.isDefault === 'False';
              },
              disabledTooltipText: 'Default policy cannot be edited',
            },
            {
              icon: <ContentCopy size="26" />,
              label: 'Duplicate Policy',
              onClick: (policy) => handleDuplicatePolicyClick(policy.id),
            },
            {
              icon: <DeleteOutline size="26" />,
              label: 'Remove Policy',
              onClick: handleOpenDeleteDialog,
              enabledCondition: (policy) => {
                return policy.isDefault === 'False';
              },
              disabledTooltipText: 'Default policy cannot be edited',
            },
          ]}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Grid>
      <CreatePolicyDialog
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
      />
      <UserDrawer open={!!policyId} onClose={() => setPolicyId(undefined)}>
        <PolicyDetails
          onUpdate={() => {
            handleFetchPolicies();
            handleFetchUser();
          }}
        />
      </UserDrawer>
      <AlertDialog
        onClose={handleCloseDeleteDialog}
        open={deleteDialogOpen}
        title={'Remove Policy'}
        onSubmit={() => handleDeletePolicy(scopedPolicy.id)}
        text={'Are you sure you want to delete this policy?'}
        submitText={'Remove'}
        loading={deleteLoading}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    policies: state.policies.entities || [],
    policyCount: state.policies.count ?? 0,
  };
};

export default connect(mapStateToProps)(PolicyTable);
