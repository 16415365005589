import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Layout, SettingsContainer } from 'components/Structure';
import { awsRoute, domainManagement, emailManagement } from 'consts/routes';
import { DomainsManager, EmailsManager } from 'components/Menu/Identities';
import AwsAccounts from './AwsAccounts';

const Aws = () => {
  const matchIdentities = useRouteMatch(emailManagement);
  const matchDomainManagement = useRouteMatch(domainManagement);
  const matchCompanySettings = useRouteMatch(awsRoute);

  const getTitle = () => {
    if (matchIdentities?.isExact) {
      return 'Email Identities';
    } else if (matchDomainManagement?.isExact) {
      return 'Domain Identities';
    } else if (matchCompanySettings?.isExact) {
      return 'AWS Accounts';
    } else {
      return 'Identity Management';
    }
  };

  return (
    <Layout title={getTitle()}>
      <SettingsContainer>
        <Switch>
          <Route exact path={awsRoute} component={AwsAccounts} />
          <Route exact path={emailManagement} component={EmailsManager} />
          <Route exact path={domainManagement} component={DomainsManager} />
        </Switch>
      </SettingsContainer>
    </Layout>
  );
};

export default Aws;
