import { showErrorSnackbar, showSuccessSnackbar } from './layout';
import {
  ACCEPT_TERMS_ERROR,
  ACCEPT_TERMS_SUCCESS,
  GET_USER_DETAILS_ERROR,
  UPDATE_USER_DETAILS_ERROR,
  UPDATE_USER_DETAILS_SUCCESS,
} from '../consts/snackbarMessages';
import { handleBackendErrorMessage } from '../utils/error_handling';

export const setUserDetails = (data) => ({
  type: 'USER_SET',
  payload: data,
});

export const getUserDetails = () => async (
  dispatch,
  getState,
  { getFirebase, axios }
) => {
  try {
    const token = await getFirebase()
      .auth()
      .currentUser?.getIdToken();

    const { data } = await axios.get('/user', {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });

    dispatch(setUserDetails(data));
    return true;
  } catch (error) {
    handleBackendErrorMessage(error, dispatch, GET_USER_DETAILS_ERROR);
    throw error;
  }
};

export const finalizeRegistration = (values) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const { data } = await axios.put(`/user/register-details`, values);
    dispatch(setUserDetails(data));
    dispatch(showSuccessSnackbar(UPDATE_USER_DETAILS_SUCCESS));
    return true;
  } catch (error) {
    dispatch(showErrorSnackbar(UPDATE_USER_DETAILS_ERROR));
    throw error;
  }
};

export const updateUserDetails = (values) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const { data } = await axios.put(`/user`, values);
    dispatch(setUserDetails(data));
    dispatch(showSuccessSnackbar(UPDATE_USER_DETAILS_SUCCESS));
    return true;
  } catch (error) {
    dispatch(showErrorSnackbar(UPDATE_USER_DETAILS_ERROR));
    throw error;
  }
};

export const acceptUpdatedTerms = () => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const { data } = await axios.patch('/user/terms-accepted');
    dispatch(setUserDetails(data));
    dispatch(showSuccessSnackbar(ACCEPT_TERMS_SUCCESS));
    return true;
  } catch (error) {
    dispatch(showErrorSnackbar(ACCEPT_TERMS_ERROR));
    throw error;
  }
};

export const setTutorialCompleted = (tutorial) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const { data } = await axios.patch(`/user/tutorial/${tutorial}`, null);
    dispatch(setUserDetails(data));
    return true;
  } catch (error) {
    throw error;
  }
};
