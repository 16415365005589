const INITIAL_STATE = {
  tags: undefined,
  count: 0,
};

const applySetTags = (state, { payload }) => {
  return {
    ...state,
    ...payload,
  };
};

const applyRemoveTag = (state, { payload }) => {
  const tags = state.tags.filter((item) => item.id !== payload);
  return {
    ...state,
    tags,
    count: state.count - 1,
  };
};

const applyUpdateTag = (state, { payload }) => {
  const updateIndex = state.tags.findIndex((tag) => tag.id === payload.id);
  state.tags[updateIndex] = payload;
  return state;
};

const templateReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'TAG_LIST_SET': {
      return applySetTags(state, action);
    }
    case 'TAG_REMOVE': {
      return applyRemoveTag(state, action);
    }
    case 'TAG_UPDATE': {
      return applyUpdateTag(state, action);
    }
    default:
      return state;
  }
};

export default templateReducer;
