import { showErrorSnackbar, showSuccessSnackbar } from './layout';
import {
  CREATE_BLOCK_ERROR,
  CREATE_BLOCK_SUCCESS,
  DELETE_BLOCK_ERROR,
  DELETE_BLOCK_SUCCESS,
  UPDATE_BLOCK_ERROR,
  UPDATE_BLOCK_SUCCESS,
  UPDATE_BLOCK_TAGS_SUCCESS,
  UPDATE_BLOCK_TAGS_ERROR,
  SAVE_BLOCK_SUCCESS,
  SAVE_BLOCK_ERROR,
  PUBLISH_BLOCK_SUCCESS,
  PUBLISH_BLOCK_ERROR,
  DUPLICATE_BLOCK_ERROR,
  DUPLICATE_BLOCK_SUCCESS,
  FETCH_BLOCK_DETAILS_ERROR,
  FETCH_TEMPLATE_BLOCKS_ERROR,
} from '../consts/snackbarMessages';
import { handleBackendErrorMessage } from '../utils/error_handling';

export const setBlocks = (blocks) => ({
  type: 'BLOCK_LIST_SET',
  payload: blocks,
});

export const updateBlockInState = (block) => ({
  type: 'BLOCK_UPDATE',
  payload: block,
});

export const deleteBlock = (id) => ({
  type: 'BLOCK_REMOVE',
  payload: id,
});

export const addBlock = (block) => ({
  type: 'BLOCK_ADD',
  payload: block,
});

export const setBlockDetails = (block) => ({
  type: 'BLOCK_DETAILS_SET',
  payload: block,
});

export const createBlock = (body) => async (dispatch, getState, { axios }) => {
  try {
    const { data } = await axios.post(`/blocks`, body);
    dispatch(addBlock(data));
    dispatch(showSuccessSnackbar(CREATE_BLOCK_SUCCESS));
    return data;
  } catch (error) {
    handleBackendErrorMessage(error, dispatch, CREATE_BLOCK_ERROR);
    throw error;
  }
};

export const removeBlock = ({ id }) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    await axios.delete(`/blocks/${id}`);
    dispatch(deleteBlock(id));
    dispatch(showSuccessSnackbar(DELETE_BLOCK_SUCCESS));

    return true;
  } catch (error) {
    dispatch(showErrorSnackbar(DELETE_BLOCK_ERROR));
    throw error;
  }
};

export const fetchBlocks = ({ tagIds }) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    let query = `/blocks`;
    if (tagIds) {
      query += `?tag_filter=${tagIds}`;
    }
    const { data } = await axios.get(query);
    dispatch(setBlocks(data));
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updateBlock = ({ id, values }) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const { data } = await axios.put(`/blocks/${id}`, values);
    dispatch(updateBlockInState(data));
    dispatch(showSuccessSnackbar(UPDATE_BLOCK_SUCCESS));
    return true;
  } catch (error) {
    handleBackendErrorMessage(error, dispatch, UPDATE_BLOCK_ERROR);
    throw error;
  }
};

export const updateBlockTags = ({ id, tagIds }) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const { data } = await axios.patch(`/blocks/${id}/tags`, {
      tag_ids: tagIds,
    });
    dispatch(updateBlockInState(data));
    dispatch(showSuccessSnackbar(UPDATE_BLOCK_TAGS_SUCCESS));
    return true;
  } catch (error) {
    handleBackendErrorMessage(error, dispatch, UPDATE_BLOCK_TAGS_ERROR);
    throw error;
  }
};

export const saveBlockToTemplates = ({ id, awsAccountIds }) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const { data } = await axios.patch(`/blocks/${id}/propagate`, {
      aws_account_ids: awsAccountIds,
    });
    dispatch(updateBlockInState(data));
    dispatch(showSuccessSnackbar(SAVE_BLOCK_SUCCESS));
    return data;
  } catch (error) {
    handleBackendErrorMessage(error, dispatch, SAVE_BLOCK_ERROR);
    throw error;
  }
};

export const publishBlockToTemplates = ({ id, awsAccountIds }) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const { data } = await axios.patch(`/blocks/${id}/publish`, {
      aws_account_ids: awsAccountIds,
    });
    dispatch(updateBlockInState(data));
    dispatch(showSuccessSnackbar(PUBLISH_BLOCK_SUCCESS));
    return data;
  } catch (error) {
    handleBackendErrorMessage(error, dispatch, PUBLISH_BLOCK_ERROR);
    throw error;
  }
};

export const duplicateBlockAction = (id) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const { data } = await axios.post(`/blocks/${id}`);
    dispatch(addBlock(data));
    dispatch(showSuccessSnackbar(DUPLICATE_BLOCK_SUCCESS));

    return data;
  } catch (error) {
    handleBackendErrorMessage(error, dispatch, DUPLICATE_BLOCK_ERROR);
    throw error;
  }
};

export const fetchBlockDetails = ({ blockId }) => async (
  dispatch,
  getState,
  { axios }
) => {
  if (!blockId) {
    return;
  }
  try {
    const { data } = await axios.get(`/blocks/${blockId}`);
    dispatch(setBlockDetails(data));
    return data;
  } catch (error) {
    dispatch(showErrorSnackbar(FETCH_BLOCK_DETAILS_ERROR));
    throw error;
  }
};

export const fetchBlockUsages = ({ blockId }) => async (
  dispatch,
  getState,
  { axios }
) => {
  if (!blockId) {
    return;
  }
  try {
    const { data } = await axios.get(`/blocks/${blockId}/usages`);
    return data;
  } catch (error) {
    dispatch(showErrorSnackbar(FETCH_BLOCK_DETAILS_ERROR));
    throw error;
  }
};

export const fetchTemplateBlocks = ({ templateId }) => async (
  dispatch,
  getState,
  { axios }
) => {
  if (!templateId) {
    return;
  }
  try {
    const { data } = await axios.get(`/template/${templateId}/blocks`);
    return data;
  } catch (error) {
    dispatch(showErrorSnackbar(FETCH_TEMPLATE_BLOCKS_ERROR));
    throw error;
  }
};
