const tagColumns = [
  {
    id: 'label',
    label: 'Tag Label',
    type: 'text',
    sortable: true,
  },
  {
    id: 'color',
    label: 'Color',
    type: 'color',
    sortable: false,
  },
  {
    id: 'createdTimestamp',
    label: 'Date of Creation',
    type: 'date',
    sortable: true,
  },
  {
    id: 'Actions',
    label: 'Actions',
    type: 'actions',
    align: 'right',
  },
];

export default tagColumns;
