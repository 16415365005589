import React, { Fragment, useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import {
  AlertDialog,
  LogoLoader,
  OverviewTable,
  SettingsHeader,
} from 'components/Base';
import { memberColumns, infoBoxTexts } from 'consts';
import {
  deleteCompanyMember,
  resendMemberInvitation,
  fetchCompanyMembers,
} from 'state/actions';
import { useLazyLoading } from 'state/hooks';
import { DeleteOutline, EditIcon, Refresh } from 'assets/icons';
import { connect } from 'react-redux';
import { useSubscription } from '../../../Contexts';
import { StringParam, useQueryParam } from 'use-query-params';

const UserTable = ({ members, count }) => {
  const [scopedMember, setScopedMember] = useState(null);
  const [deleteMemberDialogOpen, setDeleteMemberDialogOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [query, setQuery] = useQueryParam('search_users', StringParam);
  const [isFetching, setIsFetching] = useState(false);

  const { openUserDialog } = useSubscription();

  const [handleFetchMembers] = useLazyLoading({
    action: fetchCompanyMembers,
  });

  const [handleDeleteMember, { loading: deletingMember }] = useLazyLoading({
    action: deleteCompanyMember,
    onSuccess: () => {
      handleCloseDialog();
    },
  });

  const [handleResendInvitation] = useLazyLoading({
    action: resendMemberInvitation,
  });

  const fetchMembers = async () => {
    try {
      setIsFetching(true);
      await handleFetchMembers({
        limit: rowsPerPage,
        skip: page * rowsPerPage,
        search: query,
      });
      setIsFetching(false);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  useEffect(() => {
    fetchMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, page, rowsPerPage]);

  const handleOpenDeleteDialog = (member) => {
    setDeleteMemberDialogOpen(true);
    setScopedMember(member);
  };

  const handleOpenMemberEditDialog = (member) => {
    openUserDialog(member);
  };

  const handleCloseDialog = () => {
    setDeleteMemberDialogOpen(false);
    setScopedMember(null);
  };

  const handleQueryUpdates = (value) => {
    setPage(0);
    if (value !== undefined && value !== '') {
      setQuery(value);
    } else {
      setQuery('');
    }
  };

  if (members === undefined) {
    return <LogoLoader />;
  }

  const shouldNotDisplayMemberColumn = !members.every((member) =>
    member.hasOwnProperty('signInMethods')
  );

  return (
    <Fragment>
      <Grid item>
        <SettingsHeader
          buttonText={'Add new User'}
          title={'Users'}
          infoText={infoBoxTexts.users}
          onClick={() => openUserDialog(null)}
          itemsCount={count}
          searchProps={{
            onSubmit: handleQueryUpdates,
            onCancelSearch: () => setQuery(''),
            submitAfterType: true,
            defaultQueryParam: 'search_users',
          }}
        />
        <OverviewTable
          data={members}
          columns={
            shouldNotDisplayMemberColumn
              ? memberColumns.filter((column) => column.id !== 'signInMethods')
              : memberColumns
          }
          entityName="user"
          total={count}
          actions={[
            {
              icon: <EditIcon size="26" />,
              label: 'Edit User',
              onClick: (row) => handleOpenMemberEditDialog(row),
            },
            {
              icon: <DeleteOutline size="26" />,
              label: 'Remove User',
              onClick: handleOpenDeleteDialog,
            },
            {
              icon: <Refresh size="26" />,
              label: 'Resend Invitation',
              onClick: (row) => handleResendInvitation(row.uid),
              hideCondition: 'showResendInvitation',
            },
          ]}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          loading={isFetching}
        />
      </Grid>
      <AlertDialog
        onClose={handleCloseDialog}
        open={deleteMemberDialogOpen}
        title={'Remove User'}
        onSubmit={() => handleDeleteMember(scopedMember.uid)}
        text={
          'Are you sure you want to delete the user from your organization?'
        }
        submitText={'Remove'}
        loading={deletingMember}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    members: state.members?.entities || [],
    count: state.members?.count || 0,
  };
};

export default connect(mapStateToProps)(UserTable);
