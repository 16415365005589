import React from 'react';
import { NavLink } from 'react-router-dom';
import { emailManagement } from 'consts/routes';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  Chip,
  Grid,
  ListItemIcon,
  MenuItem,
  Typography,
  styled,
} from '@material-ui/core';
import { FastField as Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { Autocomplete } from 'formik-material-ui-lab';
import { AsyncDialogActions, FieldWrapper } from 'components/Base';
import AddIcon from '@material-ui/icons/Add';
import Alert from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';

const StyledNavLink = styled(NavLink)(({ theme }) => ({
  color: theme.palette.text.main,
  textDecoration: 'none',
  width: '100%',

  '&:hover': {
    backgroundColor: theme.palette.grey[100],
  },
}));

const Schema = Yup.object().shape({
  senderEmailAddress: Yup.string().required('This field is required'),
});

const SendForm = ({
  loading,
  onSubmit,
  verifiedMailAddresses = [],
  isDraft,
}) => {
  const [recipients, setRecipients] = React.useState([]);
  const [inputValue, setInputValue] = React.useState('');

  const defaultMail = verifiedMailAddresses.find((el) => el.isDefault) || {
    identity: '',
  };

  const verifiedMailsExist = verifiedMailAddresses.length > 0;

  const isNotEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return !emailRegex.test(email);
  };

  const validateRecipients = (value) => {
    let emailArray;
    if (
      typeof value === 'string' &&
      (value.includes(',') || value.includes(';'))
    ) {
      emailArray = value.split(/[,;]/);
    } else if (Array.isArray(value)) {
      emailArray = value;
    } else {
      emailArray = [value];
    }
    let combinedRecipients = [
      ...new Set([...recipients, ...emailArray]),
    ].map((email) => email.trim());
    if (combinedRecipients.length > 50) {
      return `The total number of email addresses cannot exceed 50. Currently selected: ${emailArray.length}.`;
    }
    if (combinedRecipients.length === 0) {
      return 'This field is required';
    }
    const hasInvalidEmails = combinedRecipients.some((email) =>
      isNotEmail(email)
    );
    if (hasInvalidEmails) {
      return 'Enter valid email addresses';
    }

    setRecipients(combinedRecipients);
    return undefined;
  };

  const handleSubmit = async (values) => {
    values.recipients = recipients;
    onSubmit(values);
  };

  const handleChipChange = (e, newValues, form) => {
    setRecipients(newValues);
    form.setFieldValue('recipients', newValues);
    setInputValue('');
  };

  const handleTextChange = (e, newInputValue, form) => {
    if (
      newInputValue.includes(',') ||
      newInputValue.includes(';') ||
      newInputValue.includes(' ') ||
      newInputValue.endsWith('{enter}')
    ) {
      let emails = newInputValue
        .replace(/{enter}/, '')
        .split(/[,;]/)
        .map((x) => x.trim())
        .filter((x) => x);
      const newRecipients = [...new Set([...recipients, ...emails])];
      form.setFieldValue('recipients', newRecipients);
      setRecipients(newRecipients);
      setInputValue('');
    } else {
      setInputValue(newInputValue.trim());
    }
  };

  return (
    <Formik
      initialValues={{
        recipients: [],
        senderEmailAddress: defaultMail.identity || '',
      }}
      validateOnBlur={true}
      validateOnChange={false}
      validationSchema={Schema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid container spacing={3}>
            {isDraft && (
              <Grid item xs={12}>
                <Alert severity="info">
                  <AlertTitle>Sending Unpublished Template!</AlertTitle>
                  You are sending out a template that has unpublished changes.
                  Therefore the published version on AWS SES might differ. To
                  make sure that the template sent out and the one you are
                  currently viewing are the same, publish the template first.
                </Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <FieldWrapper
                label="Recipient(s)"
                subtitle="Separate adresses by comma, semicolon, space or enter."
              >
                <Field name="recipients">
                  {({ field, form, meta }) => (
                    <Field
                      component={Autocomplete}
                      variant="outlined"
                      options={[]}
                      fullWidth
                      multiple
                      freeSolo
                      validate={validateRecipients}
                      key={'recipients'}
                      name={'recipients'}
                      onChange={(e, newValues) =>
                        handleChipChange(e, newValues, form)
                      }
                      value={recipients}
                      onInputChange={(e, newInputValue) =>
                        handleTextChange(e, newInputValue, form)
                      }
                      inputValue={inputValue}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          field={field}
                          form={form}
                          meta={meta}
                          {...params}
                          name="recipientsString"
                          variant="outlined"
                        />
                      )}
                    />
                  )}
                </Field>
              </FieldWrapper>
            </Grid>
            <Grid item xs={12}>
              <FieldWrapper label="Sender Email Address">
                <Field
                  component={TextField}
                  type="text"
                  name="senderEmailAddress"
                  select
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  {verifiedMailsExist &&
                    verifiedMailAddresses?.map(({ senderName, identity }) => (
                      <MenuItem key={identity} value={identity}>
                        {senderName} ({identity})
                      </MenuItem>
                    ))}
                  {!verifiedMailsExist && (
                    <MenuItem>
                      <StyledNavLink to={emailManagement}>
                        <ListItemIcon>
                          <AddIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography variant="inherit">Add a new one</Typography>
                      </StyledNavLink>
                    </MenuItem>
                  )}
                </Field>
              </FieldWrapper>
            </Grid>
          </Grid>
          <AsyncDialogActions
            loading={isSubmitting || loading}
            submitText="Send Mail"
          />
        </Form>
      )}
    </Formik>
  );
};

SendForm.propTypes = {
  verifiedMailAddresses: PropTypes.array.isRequired,
};

export default SendForm;
