const INITIAL_STATE = {
  entities: [],
  count: 0,
};

const setPolicies = (state, { payload }) => {
  return {
    ...state,
    ...payload,
  };
};
const deletePolicy = (state, { payload }) => {
  return {
    entities: state.entities.filter((item) => item.id !== payload),
    count: state.count - 1,
  };
};

const updatePolicy = (state, { payload }) => {
  const index = state.entities.findIndex((item) => item.id === payload.id);
  state.entities[index] = payload;
  return {
    entities: state.entities,
    count: state.count,
  };
};

const policyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'POLICIES_SET': {
      return setPolicies(state, action);
    }
    case 'POLICY_DELETE': {
      return deletePolicy(state, action);
    }
    case 'POLICY_UPDATE': {
      return updatePolicy(state, action);
    }
    default:
      return state;
  }
};

export default policyReducer;
