export const dashboard = '/';
export const analytics = '/analytics';
export const templates = '/templates';
export const starterRoute = '/starters';
export const blocksRoute = '/reusable-blocks';
export const templateVersions = templates + '/:id/versions';
export const templateStarters = templates + starterRoute;
export const templateBlocks = templates + blocksRoute;
export const templateEditor = templates + '/:id/editor';
export const templatePreview = templates + '/:id/preview';
export const tags = '/tags';
export const awsRoute = '/aws';
export const emailRoute = '/email-identities';
export const emailManagement = awsRoute + emailRoute;
export const domainRoute = '/domain-identities';
export const domainManagement = awsRoute + domainRoute;
export const companySettings = '/settings';
export const companyMembers = companySettings;
export const subscriptionRoute = '/subscription';
export const companySubscription = companySettings + '/subscription';
export const userSettings = '/user';
export const userSecurity = userSettings + '/security';
export const userPrivacy = userSettings + '/privacy';
export const userDataSecurity = userSettings + '/data-security';
export const userTerms = userSettings + '/terms-of-use';

export const companySettingsRoutes = [
  companySettings,
  companyMembers,
  companySubscription,
];

export const awsRoutes = [awsRoute, emailManagement, domainManagement];

export const templateRoutes = [
  templates,
  templateVersions,
  templateEditor,
  templatePreview,
  templateStarters,
  templateBlocks,
];

export const userRoutes = [
  userSettings,
  userSecurity,
  userDataSecurity,
  userPrivacy,
  userTerms,
];

export const privateRoutes = [
  analytics,
  companySettings,
  dashboard,
  tags,
  ...awsRoutes,
  ...companySettingsRoutes,
  ...templateRoutes,
  ...userRoutes,
];
