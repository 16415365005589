import React from 'react';
import { styled } from '@material-ui/core/styles';

const StyledBadge = styled('div')(
  ({ theme, maxwidth, color = 'secondary' }) => ({
    color: theme.palette.text.main,
    backgroundColor:
      color === 'secondary'
        ? theme.palette.background.light
        : theme.palette.primary.main,
    minWidth: '40px',
    maxWidth: maxwidth ? `${maxwidth}px` : 'unset',
    textAlign: 'center',
    height: theme.custom.tagHeight,
    margin: theme.spacing(0),
    padding: '2px 4px 4px 4px',
    borderRadius: '4px',
  })
);

const BadgeText = styled('p')(({ theme, color = 'secondary' }) => ({
  fontFamily: theme.typography.fontFamilyTitle,
  fontSize: '0.9375rem',
  fontWeight: 800,
  margin: theme.spacing(0, 0.5),
  color:
    theme.palette.type === 'dark'
      ? color === 'secondary'
        ? theme.palette.text.default
        : theme.palette.text.main
      : color === 'secondary'
      ? theme.palette.secondary.main
      : theme.palette.text.reverted,
}));

export const ColoredTag = styled('div')(({ theme, background }) => ({
  fontFamily: theme.typography.fontFamilyTitle,
  backgroundColor: background,
  fontSize: '0.875rem',
  color: 'white',
  fontWeight: 600,
  margin: theme.spacing(0.5),
  cursor: 'pointer',
  textAlign: 'center',
  height: theme.custom.tagHeight,
  padding: '4px 8px 8px 8px',
  borderRadius: '4px',
  width: 'fit-content',
}));

const Badge = ({ text, maxWidth, color }) => {
  return (
    <StyledBadge color={color} maxwidth={maxWidth}>
      <BadgeText color={color}>{text}</BadgeText>
    </StyledBadge>
  );
};

export default Badge;
