import { showErrorSnackbar, showSuccessSnackbar } from './layout';
import {
  CREATE_USER_ACCOUNT_ERROR,
  CREATE_USER_ACCOUNT_SUCCESS,
  LOGIN_ERROR,
  LOGIN_ERROR_SSO,
  TOKEN_LOGIN_ERROR,
  SEND_VERIFICATION_ERROR,
  PASSWORD_RESET_ERROR,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_UPDATE_SUCCESS,
} from '../consts/snackbarMessages';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

export const setAuthData = (user) => ({
  type: 'AUTH_DATA_SET',
  payload: {
    user,
  },
});

export const signOutAction = () => ({
  type: 'SIGN_OUT',
});

const firebaseLogin = async (Firebase, email, password) => {
  const { user } = await Firebase()
    .auth()
    .signInWithEmailAndPassword(email, password);

  await Firebase()
    .auth()
    .setPersistence(Firebase().auth.Auth.Persistence.LOCAL);
  return user;
};

export const register = ({ email, password }) => async (
  dispatch,
  getState,
  { axios, getFirebase }
) => {
  try {
    let userData = {
      email,
      password,
    };
    const { data } = await axios.post('/user/register', userData);
    const user = await firebaseLogin(getFirebase, email, password);
    if (user?.emailVerified) {
      dispatch(setAuthData(user));
    }
    dispatch(showSuccessSnackbar(CREATE_USER_ACCOUNT_SUCCESS));
    return {
      emailVerified: user?.emailVerified,
      ...data,
    };
  } catch (error) {
    dispatch(showErrorSnackbar(CREATE_USER_ACCOUNT_ERROR));
    throw error;
  }
};

export const signIn = ({ email, password }) => async (
  dispatch,
  getState,
  { getFirebase, axios }
) => {
  try {
    const user = await firebaseLogin(getFirebase, email, password);
    dispatch(setAuthData(user));
    return user;
  } catch (error) {
    dispatch(showErrorSnackbar(LOGIN_ERROR));
    throw error;
  }
};

export const resendVerification = ({ email }) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    await axios.post('/resend-verification', { email });
  } catch (error) {
    dispatch(showErrorSnackbar(SEND_VERIFICATION_ERROR));
    throw error;
  }
};

export const signOut = () => async (dispatch, getState, { getFirebase }) => {
  try {
    const result = getFirebase()
      .auth()
      .signOut();
    dispatch(signOutAction());
    if (window['autoLoginInterval']) {
      clearTimeout(window['autoLoginInterval']);
    }
    return result;
  } catch (error) {
    throw error;
  }
};

export const sendResetMail = (email) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    await axios.post('/password-reset', { email });
    dispatch(showSuccessSnackbar(PASSWORD_RESET_SUCCESS));
    return true;
  } catch (error) {
    if (error?.code === 'auth/user-not-found') {
      return true;
    }
    dispatch(showErrorSnackbar(PASSWORD_RESET_ERROR));
    throw error;
  }
};

export const updatePassword = ({ oldPassword, newPassword }) => async (
  dispatch,
  getState,
  { getFirebase }
) => {
  const auth = getFirebase().auth();
  const user = auth.currentUser;
  try {
    const credential = getFirebase().auth.EmailAuthProvider.credential(
      user.email,
      oldPassword
    );
    await user.reauthenticateWithCredential(credential);
  } catch {
    throw new Error('Your current password is wrong.');
  }
  try {
    await user.updatePassword(newPassword);
    dispatch(showSuccessSnackbar(PASSWORD_UPDATE_SUCCESS));
    return true;
  } catch (error) {
    dispatch(showErrorSnackbar(error));
    throw new Error();
  }
};

// SSO

const registerSSO = async (user, axios, dispatch, oidcProvider) => {
  try {
    const { email, uid } = user;

    const { data } = await axios.post('/user/register-sso', {
      email,
      uid,
      oidcProvider,
    });
    dispatch(showSuccessSnackbar(CREATE_USER_ACCOUNT_SUCCESS));

    return data;
  } catch (error) {
    dispatch(showErrorSnackbar(CREATE_USER_ACCOUNT_ERROR));
    throw error;
  }
};

const firebaseLoginSSO = async (Firebase, provider, dispatch, axios) => {
  try {
    const { user } = await Firebase()
      .auth()
      .signInWithPopup(provider);
    await Firebase()
      .auth()
      .setPersistence(Firebase().auth.Auth.Persistence.LOCAL);

    dispatch(setAuthData(user));

    const {
      metadata: { creationTime, lastSignInTime },
    } = user;

    if (creationTime === lastSignInTime) {
      await registerSSO(user, axios, dispatch, provider.providerId);
    }

    return user;
  } catch (error) {
    throw error;
  }
};

export const signInWithOkta = (issuer) => async (
  dispatch,
  getState,
  { getFirebase, axios }
) => {
  try {
    const provider = new firebase.auth.OAuthProvider('oidc.' + issuer);
    return await firebaseLoginSSO(getFirebase, provider, dispatch, axios);
  } catch (error) {
    console.error('Error logging in with Okta: ', error);
    dispatch(showErrorSnackbar(LOGIN_ERROR_SSO));
    throw error;
  }
};

export const signInWithGoogle = () => async (
  dispatch,
  getState,
  { getFirebase, axios }
) => {
  try {
    const provider = new firebase.auth.GoogleAuthProvider();
    return await firebaseLoginSSO(getFirebase, provider, dispatch, axios);
  } catch (error) {
    console.error('Error logging in with Google: ', error);
    dispatch(showErrorSnackbar(LOGIN_ERROR_SSO));
    throw error;
  }
};

export const signInWithMicrosoft = () => async (
  dispatch,
  getState,
  { getFirebase, axios }
) => {
  try {
    const provider = new firebase.auth.OAuthProvider('microsoft.com');
    return await firebaseLoginSSO(getFirebase, provider, dispatch, axios);
  } catch (error) {
    console.error('Error logging in with Microsoft: ', error);
    dispatch(showErrorSnackbar(LOGIN_ERROR_SSO));
    throw error;
  }
};

export const signInWithToken = (token) => async (
  dispatch,
  getState,
  { getFirebase, axios }
) => {
  try {
    let { user } = await getFirebase()
      .auth()
      .signInWithCustomToken(token);

    const backendUser = await axios.patch('user/verify', {
      email: user.email,
    });

    user = {
      ...user,
      ...backendUser,
      emailVerified: true,
    };
    dispatch(setAuthData(user));
    return user;
  } catch (error) {
    dispatch(showErrorSnackbar(TOKEN_LOGIN_ERROR));
    throw error;
  }
};
