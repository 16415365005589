const userMenu = [
  {
    label: 'User Details',
    to: '/user',
    checkIsActive: true,
  },
  {
    label: 'Security',
    to: '/user/security',
  },
  {
    label: 'Privacy Notice',
    to: '/user/privacy',
  },
  {
    label: 'Terms of Use',
    to: '/user/terms-of-use',
  },
  {
    label: 'Data Security',
    to: '/user/data-security',
  },
];

export default userMenu;
