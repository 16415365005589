// members.js

const INITIAL_STATE = {
  entities: [],
  count: 0,
};

const applySetCompanyMembers = (state, { payload }) => {
  const { count, entities } = payload;
  return {
    count,
    entities: entities.map((member) => {
      return {
        ...member,
        userGroups: member.userGroups,
        verified: !!member?.firebaseUid ? 'True' : 'False',
        showResendInvitation: !!member.firebaseUid,
      };
    }),
  };
};

const applySetCompanyMember = (state, { payload }) => {
  const index = state.entities.findIndex((item) => item.uid === payload.uid);

  if (index !== -1) {
    const updatedEntities = [...state.entities];
    updatedEntities[index] = payload;
    return {
      count: state.count,
      entities: updatedEntities,
    };
  }

  return {
    count: state.count,
    entities: [...state.entities, payload],
  };
};

const removeCompanyMember = (state, { payload }) => {
  const { uid } = payload;
  return {
    count: state.count - 1,
    entities: state.entities.filter((member) => member.uid !== uid),
  };
};

const memberReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'COMPANY_MEMBERS_SET': {
      return applySetCompanyMembers(state, action);
    }
    case 'COMPANY_MEMBER_SET': {
      return applySetCompanyMember(state, action);
    }
    case 'COMPANY_MEMBER_REMOVE': {
      return removeCompanyMember(state, action);
    }
    default:
      return state;
  }
};

export default memberReducer;
