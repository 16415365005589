import React from 'react';
import PropTypes from 'prop-types';
import { FastField as Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { Grid, Checkbox, FormControlLabel } from '@material-ui/core';
import { AsyncDialogActions, FieldWrapper, LogoLoader } from 'components/Base';
import * as Yup from 'yup';
import { fetchUserGroups } from 'state/actions';
import { useAsyncAction } from 'state/hooks';

const CreateSchema = Yup.object().shape({
  email: Yup.string().required('Please enter a valid email address.'),
  userGroupIds: Yup.array()
    .min(1, 'Please select at least one user group')
    .required('User group is required'),
  confirmAdditionalCosts: Yup.bool(),
});

const CompanyMemberForm = ({
  confirmAdditionalCosts,
  loading,
  member,
  onClose,
  onSubmit,
}) => {
  const { data: userGroupData, loading: loadingUserGroups } = useAsyncAction({
    action: fetchUserGroups,
  });

  if (!userGroupData || loadingUserGroups) {
    return <LogoLoader />;
  }

  const { entities: userGroups } = userGroupData;
  return (
    <Formik
      initialValues={{
        email: member?.email || '',
        userGroupIds: member?.userGroups.map((group) => group.id) || [],
        confirmAdditionalCosts: confirmAdditionalCosts,
      }}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={CreateSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FieldWrapper
                label="Email Address"
                subtitle="Enter the mail of the person you want to invite"
              >
                <Field
                  component={TextField}
                  fullWidth
                  name="email"
                  variant="outlined"
                  disabled={!!member?.email}
                />
              </FieldWrapper>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <FieldWrapper
                label="User Groups"
                subtitle="Select the user groups for this member"
              >
                {userGroups.map((group) => (
                  <FormControlLabel
                    key={group.id}
                    control={
                      <Checkbox
                        checked={values.userGroupIds.includes(group.id)}
                        onChange={() => {
                          const set = new Set(values.userGroupIds);
                          if (set.has(group.id)) {
                            set.delete(group.id);
                          } else {
                            set.add(group.id);
                          }
                          setFieldValue('userGroupIds', Array.from(set));
                        }}
                        name="userGroupIds"
                        color="primary"
                      />
                    }
                    label={group.label}
                  />
                ))}
              </FieldWrapper>
            </Grid>
            <AsyncDialogActions
              onClose={onClose}
              loading={isSubmitting || loading}
              submitText={member?.email ? 'Update' : 'Invite'}
            />
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

CompanyMemberForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  confirmAdditionalCosts: PropTypes.bool,
  loading: PropTypes.bool,
  member: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};

export default CompanyMemberForm;
