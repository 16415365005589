import {
  showErrorSnackbar,
  showInfoSnackbar,
  showSuccessSnackbar,
  setShimmerOverlay,
} from './layout';

import {
  DELETE_AWS_CREDENTIALS_ERROR,
  DELETE_AWS_CREDENTIALS_SUCCESS,
  RENEW_API_KEY_ERROR,
  RENEW_API_KEY_SUCCESS,
  SET_AWS_CREDENTIALS_COSTS_ERROR,
  SET_AWS_CREDENTIALS_ERROR,
  SET_AWS_CREDENTIALS_SUCCESS,
  SET_DEFAULT_ACCOUNT_ERROR,
  ERROR_RETRIEVING_ACCOUNT_STATUS,
  ERROR_RETRIEVING_ACCOUNT_PERMISSIONS,
  ERROR_EVALUATING_ACCOUNT_PERMISSIONS,
  INFO_EVALUATING_ACCOUNT_PERMISSIONS_RESULT,
  ERROR_EVALUATING_PERMISSIONS_MISSING_REQUIRED_PERMISSIONS,
  ERROR_RETRIEVING_SENDING_STATISTICS,
  INFO_EVALUATING_ACCOUNT_PERMISSIONS,
  SUCCESS_EVALUATING_ACCOUNT_PERMISSIONS,
} from '../consts/snackbarMessages';
import { handleBackendErrorMessage } from '../utils/error_handling';

export const setAwsAccounts = (data) => ({
  type: 'AWS_ACCOUNTS_SET',
  payload: data,
});

export const setDefaultAwsAccount = (data) => ({
  type: 'DEFAULT_AWS_ACCOUNT_SET',
  payload: data,
});

export const addAwsAccount = (data) => ({
  type: 'AWS_ACCOUNT_ADD',
  payload: data,
});

export const updateApiKey = (data) => ({
  type: 'API_KEY_UPDATE',
  payload: data,
});

export const removeAwsCredentials = (data) => ({
  type: 'AWS_CREDENTIALS_REMOVE',
  payload: data,
});

export const updateAwsAccount = (data) => ({
  type: 'AWS_ACCOUNT_UPDATE',
  payload: data,
});

export const incrementAwsCredentialsCount = () => ({
  type: 'AWS_CREDENTIALS_COUNT_INCREMENT',
  payload: undefined,
});

export const decrementAwsCredentialsCount = () => ({
  type: 'AWS_CREDENTIALS_COUNT_DECREMENT',
  payload: undefined,
});

export const getAwsAccounts = () => async (dispatch, getState, { axios }) => {
  try {
    const { data } = await axios.get(`/aws/accounts`);
    dispatch(setAwsAccounts(data));
    dispatch(setDefaultAwsAccount(data.find((account) => account.isDefault)));
    return data;
  } catch (error) {
    throw error;
  }
};

export const getAwsAccountStatus = (id) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const { data } = await axios.get(`/aws/account/${id}/status`);
    return data;
  } catch (error) {
    if (error.response?.status === 405) {
      dispatch(showErrorSnackbar(ERROR_RETRIEVING_ACCOUNT_STATUS));
    }
    throw error;
  }
};

export const getAwsAccountPermissions = (id) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    dispatch(setShimmerOverlay(true));
    const { data } = await axios.get(`/aws/account/${id}/permissions`);
    dispatch(setShimmerOverlay(false));
    return data;
  } catch (error) {
    if (error.response?.status === 405) {
      dispatch(showErrorSnackbar(ERROR_RETRIEVING_ACCOUNT_PERMISSIONS));
    }
    dispatch(setShimmerOverlay(false));
    throw error;
  }
};

export const evaluateAwsPermissions = (
  id,
  provideSnackbarFeedback = false
) => async (dispatch, getState, { axios }) => {
  try {
    if (provideSnackbarFeedback) {
      dispatch(showInfoSnackbar(INFO_EVALUATING_ACCOUNT_PERMISSIONS));
    } else {
      dispatch(setShimmerOverlay(true));
    }
    const { data } = await axios.get(`/aws/account/${id}/evaluate-permissions`);

    if (provideSnackbarFeedback) {
      if (data.permissions.every((permission) => permission.enabled)) {
        dispatch(showSuccessSnackbar(SUCCESS_EVALUATING_ACCOUNT_PERMISSIONS));
      } else {
        dispatch(showInfoSnackbar(INFO_EVALUATING_ACCOUNT_PERMISSIONS_RESULT));
      }
    } else {
      dispatch(setShimmerOverlay(false));
    }
    return data;
  } catch (error) {
    if (error.response?.status === 400) {
      dispatch(
        showErrorSnackbar(
          ERROR_EVALUATING_PERMISSIONS_MISSING_REQUIRED_PERMISSIONS
        )
      );
    }
    dispatch(showErrorSnackbar(ERROR_EVALUATING_ACCOUNT_PERMISSIONS));
    dispatch(setShimmerOverlay(false));
    throw error;
  }
};

export const getAccountSendingStatistics = (id) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const { data } = await axios.get(`/aws/account/${id}/sending-statistics`);
    return data;
  } catch (error) {
    if (error.response?.status === 405) {
      dispatch(showErrorSnackbar(ERROR_RETRIEVING_SENDING_STATISTICS));
    }
    throw error;
  }
};

export const addAwsCredentials = (values) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const { data } = await axios.post(`/aws/account`, values);
    dispatch(addAwsAccount(data));
    dispatch(showSuccessSnackbar(SET_AWS_CREDENTIALS_SUCCESS));
    dispatch(incrementAwsCredentialsCount());
    return data;
  } catch (error) {
    if (error.response?.status === 402) {
      dispatch(showErrorSnackbar(SET_AWS_CREDENTIALS_COSTS_ERROR));
    } else {
      handleBackendErrorMessage(error, dispatch, SET_AWS_CREDENTIALS_ERROR);
    }
    throw error;
  }
};

export const updateAwsCredentials = ({ id, values }) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const { data } = await axios.put(`/aws/account/${id}`, values);
    dispatch(updateAwsAccount(data));
    dispatch(showSuccessSnackbar(SET_AWS_CREDENTIALS_SUCCESS));
    return true;
  } catch (error) {
    if (error.response?.status === 402) {
      dispatch(showErrorSnackbar(SET_AWS_CREDENTIALS_COSTS_ERROR));
    } else {
      handleBackendErrorMessage(error, dispatch, SET_AWS_CREDENTIALS_ERROR);
    }
    throw error;
  }
};

export const deleteAwsCredentials = (id) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    await axios.delete(`/aws/account/${id}`);
    // ToDo update subscription value too
    dispatch(removeAwsCredentials(id));
    dispatch(showSuccessSnackbar(DELETE_AWS_CREDENTIALS_SUCCESS));
    dispatch(decrementAwsCredentialsCount());
    return true;
  } catch (error) {
    handleBackendErrorMessage(error, dispatch, DELETE_AWS_CREDENTIALS_ERROR);
    throw error;
  }
};

export const renewApiKey = (id) => async (dispatch, getState, { axios }) => {
  try {
    const { data } = await axios.put(`/aws/account/${id}/api-key`);
    dispatch(setAwsAccounts(data));
    dispatch(showSuccessSnackbar(RENEW_API_KEY_SUCCESS));

    return true;
  } catch (error) {
    dispatch(showErrorSnackbar(RENEW_API_KEY_ERROR));
    throw error;
  }
};

export const activateDefaultAwsAccount = (uid) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    await axios.patch(`aws/account/${uid}/default`);
    return true;
  } catch (error) {
    dispatch(showErrorSnackbar(SET_DEFAULT_ACCOUNT_ERROR));
    throw error;
  }
};
