import {
  blocksRoute,
  dashboard,
  templates,
  templateStarters,
  templateBlocks,
  tags,
  awsRoute,
  emailManagement,
  domainManagement,
  companySettings,
  companyMembers,
  companySubscription,
} from './routes';

const privateRoutesAccess = {
  [dashboard]: undefined,
  [blocksRoute]: [
    'create_templates',
    'edit_templates',
    'delete_templates',
    'publish_templates',
  ],
  [templates]: [
    'read_templates',
    'create_templates',
    'edit_templates',
    'delete_templates',
    'publish_templates',
  ],
  [templateStarters]: [
    'create_templates',
    'edit_templates',
    'delete_templates',
    'publish_templates',
  ],
  [templateBlocks]: [
    'create_templates',
    'edit_templates',
    'delete_templates',
    'publish_templates',
  ],
  [tags]: ['read_tags', 'write_tags'],
  [awsRoute]: [
    'read_aws_accounts',
    'write_aws_accounts',
    'read_email_identities',
    'write_email_identities',
    'read_domain_identities',
    'write_domain_identities',
  ],
  [emailManagement]: ['read_email_identities', 'write_email_identities'],
  [domainManagement]: ['read_domain_identities', 'write_domain_identities'],
  [companySettings]: ['billing', 'iam'],
  [companyMembers]: ['iam'],
  [companySubscription]: ['billing'],
};

export const actionTooltips = {
  create_templates: 'Create permission needed. Consult account admin.',
  edit_templates: 'Edit permission needed. Consult account admin.',
  delete_templates: 'Delete permission needed. Consult account admin.',
  publish_templates: 'Publish permission needed. Consult account admin.',
  write_tags: 'Write permission needed. Consult account admin.',
  write_aws_accounts:
    'AWS Account Write permission needed. Consult account admin.',
  write_email_identities:
    'Email Identity Write permission needed. Consult account admin.',
  write_domain_identities:
    'Domain Identity Write permission needed. Consult account admin.',
  write_blocks: 'Write permission needed. Consult account admin.',
  edit_blocks: 'Edit permission needed. Consult account admin.',
  delete_blocks: 'Delete permission needed. Consult account admin.',
};

export default privateRoutesAccess;
