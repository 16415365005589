import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  EnhancedTableHeader,
  EnhancedTableTitle,
  Loader,
  LogoLoader,
} from 'components/Base';
import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { StatusChip } from '../Shared';
import { useLazyLoading } from 'state/hooks';
import { updateTemplateTags } from 'state/actions';
import {
  getComparator,
  ShimmerContainer,
  stableSort,
  StyledLabel,
  tableCellStyles,
  TableTitle,
} from 'components/Base/TableUtils';
import moment from 'moment';
import { TemplateTags } from 'components/Menu/Tags';
import TemplateTableActions from './TemplateTableActions';
import { useHistory } from 'react-router-dom';
import { useTemplates } from 'components/Contexts';

const formattedTimestamp = (timestamp) => {
  return moment(timestamp).format('MMM D, YYYY');
};

const TimeAgo = ({ timestamp }) => {
  const today = moment.utc();
  const daysAgo = moment.duration(today.diff(timestamp)).asDays();
  if (daysAgo < 25) {
    return (
      <Tooltip title={formattedTimestamp(timestamp)}>
        <Moment fromNow utc local>
          {timestamp}
        </Moment>
      </Tooltip>
    );
  }

  return (
    <Moment format="MMM D, YYYY" utc local>
      {timestamp}
    </Moment>
  );
};

const WOAccount = styled('div')(({ theme }) => ({
  fontSize: '0.6rem',
  fontWeight: 600,
  textTransform: 'uppercase',
  color: theme.palette.warning.main,
  marginLeft: theme.spacing(1),
  alignSelf: 'center',
}));

const IFrame = styled('iframe')(({ theme }) => ({
  width: '100%',
  height: '100%',
  borderColor: theme.palette.primary.main,
}));

const PointerBox = styled(Box)({
  cursor: 'pointer',
});

const TemplatesTable = ({
  actions,
  columns,
  data,
  title,
  total,
  onRowClick,
  shimmer,
  outlined,
}) => {
  const {
    rowsPerPage,
    pageNumber: page,
    order,
    orderBy,
    updateQueryParams,
    setLocalStorageTemplateRows,
  } = useTemplates();
  const history = useHistory();
  const [dense] = useState(false);
  const [isCmdPressed, setIsCmdPressed] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.metaKey || event.ctrlKey) {
        setIsCmdPressed(true);
      }
    };

    const handleKeyUp = (event) => {
      if (event.key === 'Meta' || event.key === 'Control') {
        setIsCmdPressed(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    updateQueryParams(
      { order: isAsc ? 'desc' : 'asc', orderBy: property },
      'pushIn'
    );
  };

  const handlePageChange = (event, newPage) => {
    updateQueryParams({ pageNumber: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setLocalStorageTemplateRows(parseInt(event.target.value, 10));
    updateQueryParams({ pageNumber: 0 });
  };

  const [updateTags, { loading }] = useLazyLoading({
    action: updateTemplateTags,
  });

  const openTemplateEditor = (templateId) => {
    let path = `/templates/${templateId}/editor`;
    history.push(path);
  };

  if (loading) return <LogoLoader />;

  const handleTagsUpdate = async (values) => {
    await updateTags(values);
  };

  const onOpenTemplate = async (event, templateId) => {
    if (event.metaKey || event.ctrlKey) {
      window.open(`/templates/${templateId}/editor`, '_blank');
    } else {
      openTemplateEditor(templateId);
    }
  };

  return (
    <Fragment>
      <ShimmerContainer>
        {shimmer && <Loader overlay overlayShimmer spinner={false} />}
        {title && <EnhancedTableTitle title={title} />}
        <TableContainer>
          <Table
            style={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHeader
              headCells={columns}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />

            <TableBody>
              {stableSort(data, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={`${row.id || row.uid}-${index}`}
                    >
                      {columns.map((col) => {
                        switch (col.type) {
                          case 'text':
                            return (
                              <TableCell
                                styles={tableCellStyles}
                                component="th"
                                key={`${col.id}-${index}`}
                                scope="row"
                                onClick={(event) =>
                                  onRowClick && onRowClick(event, row.uid)
                                }
                              >
                                <StyledLabel>
                                  {row[col.id] ? row[col.id] : row['identity']}
                                </StyledLabel>
                              </TableCell>
                            );
                          case 'number':
                            return (
                              <TableCell
                                styles={tableCellStyles}
                                component="th"
                                key={`${col.id}-${index}`}
                                scope="row"
                                onClick={(event) =>
                                  onRowClick && onRowClick(event, row.uid)
                                }
                              >
                                <StyledLabel>{row[col.id]}</StyledLabel>
                              </TableCell>
                            );
                          case 'status':
                            return (
                              <TableCell
                                component="th"
                                key={`${col.id}-${index}`}
                                scope="row"
                                onClick={() => openTemplateEditor(row.uid)}
                              >
                                <PointerBox>
                                  <StatusChip status={row.status} />
                                </PointerBox>
                              </TableCell>
                            );
                          case 'date':
                            return (
                              <TableCell
                                component="th"
                                key={`${col.id}-${index}`}
                                scope="row"
                                onClick={() => openTemplateEditor(row.uid)}
                              >
                                <PointerBox>
                                  Updated: <TimeAgo timestamp={row[col.id]} />
                                </PointerBox>

                                {row.publishedTimestamp && (
                                  <PointerBox
                                    style={{
                                      marginTop: '4px',
                                      fontWeight: '500',
                                    }}
                                  >
                                    Published:{' '}
                                    <TimeAgo
                                      timestamp={row.publishedTimestamp}
                                    />
                                  </PointerBox>
                                )}
                              </TableCell>
                            );
                          case 'tags':
                            return (
                              <TableCell
                                style={col.width && { width: col.width }}
                                component="th"
                                key={`${col.id}-${index}`}
                                scope="row"
                              >
                                <TemplateTags
                                  template={row}
                                  size="small"
                                  onSave={handleTagsUpdate}
                                />
                              </TableCell>
                            );
                          case 'html':
                            return (
                              <TableCell
                                style={col.width && { width: col.width }}
                                component="th"
                                key={`${col.id}-${index}`}
                                scope="row"
                                onClick={() => openTemplateEditor(row.uid)}
                              >
                                <IFrame srcDoc={row[col.id]} scrolling="none" />
                              </TableCell>
                            );
                          case 'title':
                            return (
                              <TableCell
                                style={col.width && { width: col.width }}
                                component="th"
                                key={`${col.id}-${index}`}
                                scope="row"
                                onClick={(event) =>
                                  onOpenTemplate(event, row.uid)
                                }
                              >
                                <PointerBox>
                                  <Box display="flex" position="relative">
                                    <Tooltip
                                      title={
                                        'Press CMD (Mac) or Ctrl (Windows) to open in new tab'
                                      }
                                    >
                                      <TableTitle
                                        cmdpressed={isCmdPressed.toString()}
                                      >
                                        {row.templateName}
                                      </TableTitle>
                                    </Tooltip>
                                    {!row.awsAccountId && (
                                      <WOAccount>W/O account</WOAccount>
                                    )}
                                  </Box>
                                  <Typography variant="subtitle2">
                                    {row.subject}
                                  </Typography>
                                </PointerBox>
                              </TableCell>
                            );
                          case 'actions':
                            return (
                              <TableCell
                                component="th"
                                align={col.align}
                                key={`template-action-${row.uid}`}
                              >
                                <TemplateTableActions
                                  template={row}
                                  actions={actions}
                                />
                              </TableCell>
                            );

                          default:
                            return null;
                        }
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {total === 0 && (
          <Box py={4} textAlign="center">
            <Typography variant="body2">Nothing to show here</Typography>
          </Box>
        )}
        {total >= 0 && total > 5 && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </ShimmerContainer>
    </Fragment>
  );
};

TemplatesTable.defaultProps = {
  data: [],
};

TemplatesTable.propTypes = {
  actions: PropTypes.array,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  onRowClick: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    shimmer: state.layout.shimmerOverlay,
  };
};

export default connect(mapStateToProps)(TemplatesTable);
