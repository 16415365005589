import React, { Fragment, useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import {
  LogoLoader,
  OverviewTable,
  SettingsHeader,
  AlertDialog,
} from 'components/Base';
import { UserDrawer } from '../Shared';
import { userGroupColumns, infoBoxTexts } from 'consts';
import {
  fetchUserGroups,
  deleteUserGroup,
  duplicateUserGroup,
} from 'state/actions';
import { useLazyLoading } from 'state/hooks';
import { connect } from 'react-redux';
import CreateUserGroupDialog from './CreateUserGroupDialog';
import { StringParam, useQueryParam } from 'use-query-params';
import UserGroupDetails from './UserGroupDetails';
import { DeleteOutline, EditIcon, ContentCopy } from 'assets/icons'; // Assuming you have a FileCopyIcon for duplication

const UserGroupTable = ({ userGroups, count }) => {
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isFetching, setIsFetching] = useState(false);
  const [userGroupId, setUserGroupId] = useQueryParam(
    'userGroupId',
    StringParam
  );
  const [scopedUserGroup, setScopedUserGroup] = useState(null);

  const [handleFetchUserGroups] = useLazyLoading({
    action: fetchUserGroups,
  });

  const handleFetchGroups = async () => {
    try {
      setIsFetching(true);
      await handleFetchUserGroups({
        limit: rowsPerPage,
        skip: page * rowsPerPage,
      });
      setIsFetching(false);
    } catch (error) {
      console.error('Error fetching user groups:', error);
    }
  };

  useEffect(() => {
    handleFetchGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const [handleDeleteUserGroup, { loading: deleteLoading }] = useLazyLoading({
    action: deleteUserGroup,
    onSuccess: () => {
      handleCloseDeleteDialog();
    },
  });

  const [handleDuplicateUserGroup] = useLazyLoading({
    action: duplicateUserGroup,
    onSuccess: (userGroup) => {
      handleFetchGroups(); // as we add, we re-fetch in order to preserve ordering
      setUserGroupId(userGroup.id);
    },
  });

  const handleOpenEditDrawer = (userGroup) => {
    setUserGroupId(userGroup.id);
  };

  const handleOpenDeleteDialog = (userGroup) => {
    setDeleteDialogOpen(true);
    setScopedUserGroup(userGroup);
  };

  const handleDuplicateGroup = (userGroupId) => {
    handleDuplicateUserGroup(userGroupId);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setScopedUserGroup(null);
  };

  if (userGroups === undefined) {
    return <LogoLoader />;
  }

  return (
    <Fragment>
      <Grid item>
        <SettingsHeader
          buttonText={'Add new User Group'}
          title={'User Groups'}
          infoText={infoBoxTexts.userGroups}
          onClick={() => setCreateDialogOpen(true)}
          itemsCount={count}
        />
        <OverviewTable
          data={userGroups}
          columns={userGroupColumns}
          total={count}
          entityName="user_group"
          actions={[
            {
              icon: <EditIcon size="26" />,
              label: 'Edit User Group',
              onClick: handleOpenEditDrawer,
            },
            {
              icon: <ContentCopy size="26" />, // Icon for duplication
              label: 'Duplicate User Group',
              onClick: (userGroup) => handleDuplicateGroup(userGroup.id),
            },
            {
              icon: <DeleteOutline size="26" />,
              label: 'Remove User Group',
              onClick: handleOpenDeleteDialog,
            },
          ]}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          loading={isFetching}
        />
      </Grid>
      <CreateUserGroupDialog
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
      />
      <UserDrawer
        open={!!userGroupId}
        onClose={() => setUserGroupId(undefined)}
      >
        <UserGroupDetails />
      </UserDrawer>
      <AlertDialog
        onClose={handleCloseDeleteDialog}
        open={deleteDialogOpen}
        title={'Remove User Group'}
        onSubmit={() => handleDeleteUserGroup(scopedUserGroup.id)}
        text={'Are you sure you want to delete this user group?'}
        submitText={'Remove'}
        loading={deleteLoading}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userGroups: state.userGroups.entities || [],
    count: state.userGroups.count || 0,
  };
};

export default connect(mapStateToProps)(UserGroupTable);
