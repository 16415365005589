import React, { Fragment, useEffect, useState } from 'react';
import { DeleteOutline as DeleteIcon } from 'assets/icons';
import {
  AlertDialog,
  OverviewTable,
  SettingsHeader,
  hasPermission,
} from 'components/Base';
import { VerifyEmailAddressDialog } from 'components/Menu/Identities';
import { useLazyLoading } from 'state/hooks';
import {
  getEmailIdentities,
  removeIdentity,
  syncEmailIdentities,
  updateSenderName,
  updateIsTestSenderName,
} from 'state/actions';
import { connect } from 'react-redux';
import { actionTooltips, verifiedMailColumns } from 'consts';
import { AwsNotConnected } from '../CompanySettings/Aws';
import { useTracking } from 'components/Contexts/TrackingContext';
import { Visibility } from '@material-ui/icons';
import EmailVisibilityDialog from './EmailVisibilityDialog';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Typography } from '@material-ui/core';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { Refresh } from 'assets/icons';

const EmailsManager = ({
  awsConnected,
  verifiedMails,
  addressCount,
  userPermissions,
}) => {
  const { logEvent } = useTracking();

  const [mailDialogOpen, setMailDialogOpen] = useState(false);
  const [visibilityDialogOpen, setVisibilityDialogOpen] = useState(false);
  const [scopedMail, setScopedMail] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [query, setQuery] = useQueryParams({
    search: withDefault(StringParam, ''),
  });

  const [reloadEmailIdentities, { loading, error }] = useLazyLoading({
    action: getEmailIdentities,
  });

  const onChangeHandleFetchPolicy = () => {
    reloadEmailIdentities({
      search: query.search,
      skip: page * rowsPerPage,
      limit: rowsPerPage,
      order: 'asc',
      orderBy: 'identity',
    });
  };

  useEffect(() => {
    onChangeHandleFetchPolicy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, page, rowsPerPage]);

  const [handleRefresh, { loading: refreshing }] = useLazyLoading({
    action: syncEmailIdentities,
    onSuccess: () => onChangeHandleFetchPolicy(),
  });

  const handleCloseDialog = () => {
    setMailDialogOpen(false);
    setDeleteDialogOpen(false);
    setScopedMail(null);
    onChangeHandleFetchPolicy();
  };

  const [handleRemoveIdentity, { loading: deleting }] = useLazyLoading({
    action: removeIdentity,
    onSuccess: () => {
      logEvent({ category: 'email_identity', action: 'removed' });
      setDeleteDialogOpen(false);
      setScopedMail(null);
      onChangeHandleFetchPolicy();
    },
    onError: () => {
      setDeleteDialogOpen(false);
      setScopedMail(null);
    },
  });

  const [
    updateSenderNameAction,
    { loading: setSenderUpdateLoading },
  ] = useLazyLoading({
    action: updateSenderName,
    onSuccess: () => {
      logEvent({ category: 'email_identity', action: 'sender name updated' });
      onChangeHandleFetchPolicy();
      setScopedMail(null);
    },
  });

  const [
    updateEmailVisibility,
    { loading: setVisibiliyLoading },
  ] = useLazyLoading({
    action: updateIsTestSenderName,
    onSuccess: () => {
      setScopedMail(null);
      onChangeHandleFetchPolicy();
    },
  });

  const handleVisibilityDialog = () => {
    setVisibilityDialogOpen(false);
    onChangeHandleFetchPolicy();
  };

  const confirmRemoveIdentity = (row) => {
    setScopedMail(row);
    setDeleteDialogOpen(true);
    onChangeHandleFetchPolicy();
  };

  const handleSenderNameChange = async ({ displayName }) => {
    await updateSenderNameAction(scopedMail.uid, displayName);
  };

  const handleIsTestSenderChange = async (id, value) => {
    await updateEmailVisibility(id, value);
  };

  const handleOpenEmailVisibilityDialog = async () => {
    setVisibilityDialogOpen(true);
  };

  const handleQueryUpdates = (value) => {
    if (value !== undefined && value !== '') {
      setPage(0);
      setQuery({ search: value });
    }
  };

  if (error && error.includes('403')) {
    return (
      <Alert severity="error">
        <AlertTitle>Access Denied</AlertTitle>
        <Typography variant="body2">
          You don't have the necessary permissions to view this information.
          Please contact your administrator to request access.
        </Typography>
      </Alert>
    );
  }

  if (!awsConnected) return <AwsNotConnected />;

  return (
    <Fragment>
      <SettingsHeader
        title="Verified Email Addresses"
        buttonText={'Add Address'}
        onClick={() => setMailDialogOpen(true)}
        itemsCount={addressCount}
        refreshing={refreshing}
        onRefresh={handleRefresh}
        primaryActionPermission={'write_email_identities'}
        additionalActionItems={[
          {
            label: 'Refresh',
            onClick: handleRefresh,
            icon: <Refresh size={26} />,
          },
          {
            label: 'Manage Visibility',
            onClick: handleOpenEmailVisibilityDialog,
            icon: <Visibility size={26} />,
          },
        ]}
        searchProps={{
          onSubmit: handleQueryUpdates,
          onCancelSearch: () => setQuery({ search: '' }),
          submitAfterType: true,
          defaultQueryParam: 'search',
        }}
      ></SettingsHeader>
      <OverviewTable
        data={verifiedMails}
        columns={verifiedMailColumns}
        anyDialogOpen={deleteDialogOpen || mailDialogOpen}
        loading={loading}
        scopedMail={scopedMail}
        onSetScopedEntity={setScopedMail}
        onChangeSenderName={handleSenderNameChange}
        onChangeIsTestSender={handleIsTestSenderChange}
        onLoadingSenderName={setSenderUpdateLoading}
        onLoadingEmailVisibility={setVisibiliyLoading}
        total={addressCount}
        actions={[
          {
            icon: <DeleteIcon size={22} />,
            label: 'Remove',
            onClick: confirmRemoveIdentity,
            enabledCondition: () =>
              hasPermission(userPermissions, 'write_email_identities'),
            disabledTooltipText: actionTooltips.write_email_identities,
          },
        ]}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
      <VerifyEmailAddressDialog
        mail={scopedMail?.identity}
        open={mailDialogOpen}
        onClose={handleCloseDialog}
      />
      <EmailVisibilityDialog
        open={visibilityDialogOpen}
        onClose={handleVisibilityDialog}
      />
      <AlertDialog
        onClose={handleCloseDialog}
        open={deleteDialogOpen}
        title={'Remove Identity'}
        onSubmit={async () => {
          await handleRemoveIdentity(scopedMail);
        }}
        text={'Are you sure you want to delete this Identity?'}
        submitText={'Remove'}
        loading={deleting}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    awsConnected: state.aws.all?.length > 0,
    verifiedMails: state.identities?.emails?.entities,
    addressCount: state.identities.emails?.count ?? 0,
    userPermissions: state.user?.permissions ?? [],
  };
};

export default connect(mapStateToProps)(EmailsManager);
