import React, { useEffect, useState } from 'react';
import clamp from 'lodash/clamp';
import { slidesContent } from 'components/WelcomeSlides/constants/carouselContent';
import { AwsCredentials } from 'components/WelcomeSlides/Slides';
import { WelcomeSlidesDialog } from 'components/WelcomeSlides';
import { useSelector } from 'react-redux';
import { useLazyLoading } from 'state/hooks';
import { setTutorialCompleted } from 'state/actions';
import UserDetails from '../Slides/UserDetails';

const WelcomeSlidesContext = React.createContext(null);

const WelcomeSlidesProvider = ({ children }) => {
  const { welcomeSeen, firstName, lastName, permissions } = useSelector(
    (state) => state.user
  );

  const { default: defaultAwsAccount } = useSelector((state) => state.aws);
  const isAwsAccountEditor = permissions.find(
    (permission) => permission.id === 'write_aws_accounts'
  );
  const showWelcomeSlides = !welcomeSeen || false;

  const [openWelcomeSlides, setOpenWelcomeSlides] = useState(showWelcomeSlides);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [backdropClickEnabled, setBackdropClickEnabled] = useState(false);
  const [slides, setSlides] = useState([...slidesContent]);

  useEffect(() => {
    setOpenWelcomeSlides(showWelcomeSlides);
  }, [showWelcomeSlides]);

  useEffect(() => {
    const extraSlides = [];
    if (!firstName || !lastName) {
      extraSlides.push(UserDetails);
    }
    if (isAwsAccountEditor) {
      !defaultAwsAccount && extraSlides.push(AwsCredentials);
    }

    setSlides([...slides, ...extraSlides]);
  }, []); // eslint-disable-line

  useEffect(() => {
    if (currentSlide >= 4) {
      setBackdropClickEnabled(true);
    }
  }, [currentSlide]); // eslint-disable-line

  const nextSlide = () => {
    setCurrentSlide(clamp(currentSlide + 1, 0, slides.length - 1));
  };

  const prevSlide = () => {
    setCurrentSlide(clamp(currentSlide - 1, 0, slides.length - 1));
  };

  const [handleWelcomeSlidesSeen] = useLazyLoading({
    action: setTutorialCompleted,
  });

  const closeDialog = async () => {
    handleWelcomeSlidesSeen('welcome');
    setOpenWelcomeSlides(false);
  };

  return (
    <WelcomeSlidesContext.Provider
      value={{
        currentSlide,
        setCurrentSlide,
        slides,
        closeDialog,
        nextSlide: currentSlide === slides.length - 1 ? closeDialog : nextSlide,
        prevSlide,
      }}
    >
      <WelcomeSlidesDialog
        isOpen={openWelcomeSlides}
        closeDialog={closeDialog}
        backdropClickEnabled={backdropClickEnabled}
      />
      {children}
    </WelcomeSlidesContext.Provider>
  );
};

export { WelcomeSlidesProvider };

export default WelcomeSlidesContext;
