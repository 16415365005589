import React, { Fragment } from 'react';
import { Typography } from '@material-ui/core';
import {
  DialogFormFrame,
  LoadingButton,
  StyledInvertedButton,
} from 'components/Base';

const RouteGuardDialog = ({
  onClose,
  onSave,
  onDiscardChanges,
  open,
  loading,
}) => {
  return (
    <DialogFormFrame
      onClose={onClose}
      title={'Changes not saved'}
      open={open}
      formComponent={
        <Typography gutterBottom>
          {'Are you sure, you want to leave with unsaved changes?'}
        </Typography>
      }
      actions={
        <Fragment>
          <StyledInvertedButton onClick={onDiscardChanges}>
            Discard changes
          </StyledInvertedButton>
          <LoadingButton
            onClick={onSave}
            color="primary"
            autoFocus
            variant={'contained'}
            loading={loading}
          >
            Save and navigate
          </LoadingButton>
        </Fragment>
      }
    />
  );
};

export default RouteGuardDialog;
