export const fetchPermissionSet = () => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const { data } = await axios.get(`/iam/permissions`);
    return data;
  } catch (error) {
    throw error;
  }
};
