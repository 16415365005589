import React, { useRef, useState } from 'react';
import {
  IconButton,
  TableCell,
  ListItemIcon,
  ListItemText,
  Tooltip,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  ClickAwayListener,
  Grow,
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import defaultBackground from 'assets/img/image-placeholder.png';
import { MoreVert } from 'assets/icons';

export const TableTitle = styled('div')(({ theme, cmdpressed }) => ({
  fontSize: '0.9rem',
  fontWeight: 600,
  cursor: cmdpressed ? 'alias' : 'pointer',
}));

export const ShimmerContainer = styled('div')({
  position: 'relative',
});

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  '&:last-child': {
    borderBottom: 'none',
  },
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.main,
  width: theme.spacing(4),
  height: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(0.5),
  transition: 'all 0.15s ease',
  border: `1px solid transparent`,

  '&:hover': {
    boxShadow: theme.custom.standardShadow,
    border: `1px solid ${theme.palette.grey[400]}`,
  },
}));

const ActionsIconButton = styled(StyledIconButton)(({ theme }) => ({
  marginRight: theme.spacing(2),
}));

export const StyledImage = styled(({ backgroundImage, children, ...rest }) => (
  <div {...rest}>{children}</div>
))(({ theme, backgroundImage }) => ({
  width: '100px',
  height: '100px',
  backgroundImage: `url(${backgroundImage || defaultBackground})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
}));

export const StyledLabel = styled('div')({
  cursor: 'pointer',
});

export const tableCellStyles = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '20vW',
  cursor: 'pointer',
};

export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;

    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
};

export const TableActions = ({ actions, row, col, labelId }) => {
  const [actionsOpen, setActionsOpen] = useState(false);
  const actionAnchorRef = useRef(null);

  const handleToggle = () => {
    setActionsOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (
      actionAnchorRef.current &&
      actionAnchorRef.current.contains(event.target)
    ) {
      return;
    }
    setActionsOpen(false);
  };

  return (
    <TableCell align={col.align} component="th" id={labelId} scope="row">
      <ActionsIconButton
        ref={actionAnchorRef}
        aria-label="open additional actions"
        onClick={handleToggle}
        edge="end"
      >
        <MoreVert size={24} />
      </ActionsIconButton>
      <Popper
        open={actionsOpen}
        anchorEl={actionAnchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement={'bottom-end'}
        popperOptions={{
          modifiers: {
            preventOverflow: {
              padding: 0,
            },
          },
        }}
        style={{ zIndex: 10 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList disablePadding>
                  {actions.map(
                    (
                      {
                        label,
                        icon,
                        onClick,
                        renderCondition,
                        enabledCondition,
                        disabledTooltipText,
                      },
                      index
                    ) => {
                      if (
                        renderCondition !== undefined &&
                        !renderCondition(row)
                      ) {
                        return null;
                      }

                      if (
                        enabledCondition !== undefined &&
                        !enabledCondition(row)
                      ) {
                        return (
                          <Tooltip title={disabledTooltipText} key={index}>
                            <div>
                              <StyledMenuItem
                                key={index}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  onClick(row);
                                  handleClose(event);
                                }}
                                disabled
                              >
                                <ListItemIcon>{icon}</ListItemIcon>
                                <ListItemText>{label}</ListItemText>
                              </StyledMenuItem>
                            </div>
                          </Tooltip>
                        );
                      }
                      return (
                        <StyledMenuItem
                          key={index}
                          onClick={(event) => {
                            event.stopPropagation();
                            onClick(row);
                            handleClose(event);
                          }}
                        >
                          <ListItemIcon>{icon}</ListItemIcon>
                          <ListItemText>{label}</ListItemText>
                        </StyledMenuItem>
                      );
                    }
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </TableCell>
  );
};

export const TableIcons = ({ icons, col, labelId }) => {
  return (
    <TableCell align={col.align} component="th" id={labelId} scope="row">
      {icons.map(({ tooltip, disabled, Icon, icon }, index) => {
        return (
          <Tooltip key={index} title={tooltip}>
            <StyledIconButton disabled={disabled}>
              {icon && (
                <img style={{ height: '24px' }} src={icon.type} alt={tooltip} />
              )}
              {Icon && <Icon />}
            </StyledIconButton>
          </Tooltip>
        );
      })}
    </TableCell>
  );
};
