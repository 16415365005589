// Hell Beige: FEF8F0
// Dunkleres Beige: F0E1C6
// Dunkel Türkis: 285958
// Orange: F19273
import { updateTheme } from './themeOverrides';
import grey from '@material-ui/core/colors/grey';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const lightThemeDefaults = {
  palette: {
    primary: {
      light: '#09759C',
      main: '#064861',
      dark: '#043647',
    },
    secondary: {
      superlight: '#FFFCE9',
      light: '#F9E48A',
      main: '#FFD301',
      dark: '#E6BF00',
    },
    text: {
      default: '#032733',
      defaultReverted: '#ffffff',
      reverted: '#FFFFFF',
      main: '#032733',
    },
    alert: {
      info: '#E5F5FD',
    },
    warning: {
      light: '#E79FAC',
      main: '#E38190',
    },
    error: {
      main: '#f44336',
    },
    succes: {
      main: '#1DED4A',
    },
    info: {
      light: '#F9E48A',
      main: '#FFD301',
      dark: '#E6BF00',
    },
    grey: {
      main: grey[200],
      inactive: '#A0A8AB',
      borderColor: '#06486133',
    },
    bg: {
      main: '#ffffff',
    },
    background: {
      light: '#FFFCE9', // Light secondary. Not inverted in dark mode.
      main: '#FFFFFF',
      paper: '#f3fafc',
      dark: '#d9f3f8', // Light primary. Inverted in dark mode.
    },
    analytics: {
      first: '#084861',
      second: '#425D62',
      third: '#6F7560',
      fourth: '#9B9057',
      fifth: '#C8AF4B',
      sixth: '#F8D139',
    },
  },
  typography: {
    fontFamily: ['Montserrat', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(
      ','
    ),
    fontFamilyTitle: ['Exo'].join(','),
    tableTitle: {
      color: 'red',
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiButton: {
      disableElevation: true,
    },
    MuiDialogTitle: {
      disableTypography: true,
    },
  },
  custom: {
    appBarHeight: 60,
    drawerClosedWidth: 73,
    drawerWidth: 220,
    dropContainerHeight: 660,
    dropContainerWidth: 375,
    editBorderStyle: '1px dashed transparent',
    editBorderStyleHover: '1px dashed #616161',
    editorSidebarWidth: 500,
    profileAreaWidth: 250,
    sideAreaWidth: 360,
    buttonHeight: 32,
    tagHeight: 26,
    standardShadow: '0px 8px 8px 0px #0000000D',
  },
};
export default updateTheme(lightThemeDefaults);
